import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  alpha,
} from '@mui/material';
import {
  Menu as MenuIcon,
  CalendarMonth,
  LocalGasStation,
  Speed,
  Close,
  Download,
  Share,
  Star,  
  Apple,
  Android,
  AddCircle,
  DirectionsCar,
  AttachMoney,
  Check
} from '@mui/icons-material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './components/Footer';
import Header from './components/Header';
import { useNavigate } from 'react-router-dom';

const apiUrl = "https://sea-lion-app-fhfoo.ondigitalocean.app"; //process.env.URL_ENDPOINTS

const API_CONFIG = {
  ANYCAR_URL: 'https://api-v2.anycar.com.br/integracao/consultar',
  API_KEY: 'vTfSS8jSiCKu414mC8pjWFIqkFI6Fzyu',
  VEHICLE_API: 'https://sea-lion-app-fhfoo.ondigitalocean.app/api/vehicles',
  CONFIGURACAO_URL: 'https://sea-lion-app-fhfoo.ondigitalocean.app'
};

// Definir o tema com tons de laranja e azul escuro
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#F97316',
    },
    secondary: {
      main: '#0A192F',
    },
    background: {
      default: '#0A192F',
      paper: '#112240',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: '#ff6400',
          borderColor: '#ff6400',
        },
      },
    },
  },
});

// Styled components
const StyledGradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.1,
    zIndex: 1,
  },
}));

const FloatingAppCTA = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 1000,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.paper, 0.95),
  backdropFilter: 'blur(10px)',
  boxShadow: theme.shadows[4],
  borderRadius: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    flexDirection: 'column',
  },
}));

// New styled component for the register section
const StyledRegisterSection = styled(Box)(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.background.paper}, ${alpha(theme.palette.primary.main, 0.1)})`,
  borderRadius: theme.spacing(3),
  padding: theme.spacing(6),
  position: 'relative',
  overflow: 'hidden',
  boxShadow: theme.shadows[10],
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    width: '40%',
    height: '100%',
    background: `linear-gradient(135deg, transparent, ${alpha(theme.palette.primary.main, 0.2)})`,
    zIndex: 0,
  },
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(8px)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[12],
  },
}));

const TorkiClassifieds = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showDownloadPrompt, setShowDownloadPrompt] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Buscar os três veículos mais recentes
    const fetchRecentVehicles = async () => {
      try {
        console.log(`${apiUrl}/api/listings`);
        const response = await fetch(`${apiUrl}/api/listings`);
        const data = await response.json();
        const sortedVehicles = data.listings
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 3);
        setVehicles(sortedVehicles);
      } catch (error) {
        console.error('Erro ao carregar veículos recentes:', error);
      }
    };
    fetchRecentVehicles();
  }, []);

  // Hero Section
  const Hero = () => (
    <StyledGradientBox sx={{ minHeight: '80vh', position: 'relative' }}>
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2, py: 8 }}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '2.5rem', md: '4rem' },
                fontWeight: 800,
                color: 'white',
                mb: 3,
              }}
            >
              Seu próximo carro está aqui na{' '}
              <Box component="span" sx={{ color: "#0A192F" }}>
                Torki
              </Box>
            </Typography>
            <Typography variant="h5" sx={{ color: 'white', mb: 4, fontWeight: 300 }}>
              A maior plataforma de compra e venda de veículos do Brasil
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 4 }}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                startIcon={<Download />}
                sx={{ py: 2, px: 4 }}
              >
                Baixe o App
              </Button>
              <Button
                variant="outlined"
                size="large"
                sx={{ py: 2, px: 4, color: 'white', borderColor: 'white' }}
                onClick={() => navigate('/classificados/pesquisa')}
              >
                Ver Ofertas
              </Button>
            </Box>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">50K+</Typography>
                <Typography variant="body2">Veículos</Typography>
              </Box>
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">100K+</Typography>
                <Typography variant="body2">Usuários</Typography>
              </Box>
              <Box sx={{ textAlign: 'center', color: 'white' }}>
                <Typography variant="h4" fontWeight="bold">4.8</Typography>
                <Typography variant="body2">Avaliação</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/fundo.jpeg"
              sx={{
                width: '100%',
                maxWidth: 600,
                borderRadius: 4,
                boxShadow: theme.shadows[20],
                transform: 'perspective(1000px) rotateY(-10deg)',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </StyledGradientBox>
  );

  // New Vehicle Registration Section with 3D elements and interactive features
  const VehicleRegistration = () => {
    const [hoveredStep, setHoveredStep] = useState(null);

    return (
      <Box
        sx={{ 
          py: 12, 
          bgcolor: 'background.default',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Container maxWidth="lg">
          {/* Elementos de fundo estáticos */}
          <Box
            sx={{ 
              position: 'absolute', 
              top: '5%', 
              left: '5%', 
              width: 100, 
              height: 100,
              borderRadius: '50%',
              background: alpha(theme.palette.primary.main, 0.15),
              filter: 'blur(40px)'
            }}
          />
          <Box
            sx={{ 
              position: 'absolute', 
              bottom: '10%', 
              right: '8%', 
              width: 150, 
              height: 150,
              borderRadius: '50%',
              background: alpha(theme.palette.primary.main, 0.2),
              filter: 'blur(50px)'
            }}
          />
          <Typography 
            variant="h2" 
            fontWeight="900" 
            align="center"
            sx={{ 
              mb: 2, 
              color: theme.palette.primary.main,
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              position: 'relative',
              zIndex: 2,
            }}
          >
            Venda seu carro em 3 passos simples
          </Typography>
          <Typography 
            variant="h5" 
            align="center" 
            sx={{ 
              mb: 8, 
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
              position: 'relative',
              zIndex: 2,
            }}
          >
            Anuncie seu veículo em minutos e receba propostas de compradores verificados
          </Typography>
          {/* Steps com cartões 3D */}
          <Grid container spacing={4} sx={{ mb: 8, position: 'relative', zIndex: 2 }}>
            {[
              { 
                step: 1, 
                title: 'Cadastre seu veículo', 
                desc: 'Insira os detalhes e fotos do seu carro em nosso formulário inteligente', 
                icon: <DirectionsCar sx={{ fontSize: 40 }} />,
                color: '#FF7D3B'
              },
              { 
                step: 2, 
                title: 'Obtenha avaliação', 
                desc: 'Nossa IA analisa o mercado e sugere o melhor preço para venda rápida', 
                icon: <AttachMoney sx={{ fontSize: 40 }} />,
                color: '#FF9442'
              },
              { 
                step: 3, 
                title: 'Receba propostas', 
                desc: 'Conecte-se com compradores reais e negocie com segurança', 
                icon: <Check sx={{ fontSize: 40 }} />,
                color: '#FFA94F'
              }
            ].map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  onMouseEnter={() => setHoveredStep(index)}
                  onMouseLeave={() => setHoveredStep(null)}
                  sx={{
                    height: '100%',
                    background: hoveredStep === index 
                      ? `linear-gradient(135deg, ${item.color} 0%, ${theme.palette.background.paper} 100%)`
                      : theme.palette.background.paper,
                    borderRadius: 4,
                    p: 4,
                    position: 'relative',
                    overflow: 'hidden',
                    transform: hoveredStep === index ? 'translateY(-10px) rotateY(5deg)' : 'translateY(0) rotateY(0)',
                    transition: 'all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)',
                    boxShadow: hoveredStep === index 
                      ? `0 20px 30px -10px ${alpha(item.color, 0.3)}`
                      : `0 10px 20px -5px ${alpha(theme.palette.common.black, 0.2)}`,
                    border: `1px solid ${alpha(theme.palette.primary.main, hoveredStep === index ? 0.3 : 0.1)}`,
                    '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: hoveredStep === index ? '-100%' : '-30%',
                      right: hoveredStep === index ? '-100%' : '-30%',
                      width: '200%',
                      height: '200%',
                      background: `radial-gradient(circle, ${alpha(item.color, 0.4)} 0%, transparent 60%)`,
                      opacity: hoveredStep === index ? 0.8 : 0.3,
                      transition: 'all 0.5s',
                      zIndex: 0,
                    }
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      height: '100%',
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: 80,
                        height: 80,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '50%',
                        backgroundColor: alpha(item.color, 0.15),
                        color: item.color,
                        mb: 3,
                        transform: hoveredStep === index ? 'scale(1.1)' : 'scale(1)',
                        transition: 'transform 0.3s',
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: -10,
                        right: -10,
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: item.color,
                        color: 'white',
                        fontWeight: 'bold',
                        boxShadow: `0 4px 10px ${alpha(item.color, 0.4)}`,
                      }}
                    >
                      {item.step}
                    </Box>
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ 
                        color: hoveredStep === index ? theme.palette.common.white : 'primary.main',
                        transition: 'color 0.3s'
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{ 
                        color: hoveredStep === index ? alpha(theme.palette.common.white, 0.9) : 'text.secondary',
                        transition: 'color 0.3s',
                        flex: 1
                      }}
                    >
                      {item.desc}
                    </Typography>
                    {hoveredStep === index && (
                      <Box sx={{ mt: 3, width: '100%', textAlign: 'center' }}>
                        <Box
                          sx={{
                            height: 2,
                            width: '40%',
                            mx: 'auto',
                            background: alpha(theme.palette.common.white, 0.5),
                            mb: 3
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {/* Seção CTA */}
          <Box
            sx={{
              position: 'relative',
              zIndex: 2,
              textAlign: 'center',
              py: 5,
              px: { xs: 2, md: 6 },
              borderRadius: 6,
              background: theme.palette.background.paper,
              boxShadow: `0 10px 30px ${alpha(theme.palette.common.black, 0.15)}`,
              border: `1px solid ${alpha(theme.palette.primary.main, 0.2)}`,
              overflow: 'hidden'
            }}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              sx={{ 
                mb: 3,
                color: theme.palette.primary.main,
                textShadow: `0 2px 10px ${alpha(theme.palette.common.black, 0.2)}`
              }}
            >
              Pronto para vender?
            </Typography>
            <Typography variant="h6" sx={{ mb: 4, maxWidth: '800px', mx: 'auto', color: 'text.secondary' }}>
              Junte-se aos mais de 50.000 vendedores satisfeitos que encontraram compradores em tempo recorde
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddCircle />}
              onClick={() => window.open('https://torki.tech/classificados/incluir', '_blank')}
              sx={{
                py: 2.5,
                px: 8,
                fontSize: '1.2rem',
                borderRadius: 3,
                background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                boxShadow: `0 10px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
                transition: 'all 0.3s',
                position: 'relative',
                overflow: 'hidden',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: '-100%',
                  width: '100%',
                  height: '100%',
                  background: `linear-gradient(90deg, transparent, ${alpha(theme.palette.common.white, 0.2)}, transparent)`,
                  transition: 'all 0.5s',
                },
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: `0 10px 20px ${alpha(theme.palette.primary.main, 0.3)}`
                }
              }}
            >
              Cadastrar meu veículo agora
            </Button>
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 2, 
                mt: 4, 
                flexWrap: 'wrap'
              }}
            >
              <Chip
                label="Anúncio 100% grátis"
                variant="outlined"
                icon={<AddCircle fontSize="small" />}
                sx={{ 
                  borderColor: alpha(theme.palette.primary.main, 0.5),
                  background: alpha(theme.palette.background.paper, 0.3),
                  backdropFilter: 'blur(5px)',
                  fontWeight: 'medium',
                  '& .MuiChip-icon': { color: theme.palette.primary.main }
                }}
              />
              <Chip
                label="Avaliação instantânea"
                variant="outlined"
                icon={<Speed fontSize="small" />}
                sx={{ 
                  borderColor: alpha(theme.palette.primary.main, 0.5),
                  background: alpha(theme.palette.background.paper, 0.3),
                  backdropFilter: 'blur(5px)',
                  fontWeight: 'medium',
                  '& .MuiChip-icon': { color: theme.palette.primary.main }
                }}
              />
              <Chip
                label="Suporte dedicado"
                variant="outlined"
                icon={<Star fontSize="small" />}
                sx={{ 
                  borderColor: alpha(theme.palette.primary.main, 0.5),
                  background: alpha(theme.palette.background.paper, 0.3),
                  backdropFilter: 'blur(5px)',
                  fontWeight: 'medium',
                  '& .MuiChip-icon': { color: theme.palette.primary.main }
                }}
              />
            </Box>
          </Box>
          {/* Estatísticas */}
          <Grid container spacing={3} sx={{ mt: 8, position: 'relative', zIndex: 2 }}>
            {[
              { value: '15k+', label: 'Vendas realizadas este mês' },
              { value: '24h', label: 'Tempo médio para primeira proposta' },
              { value: '98%', label: 'Taxa de satisfação dos usuários' }
            ].map((stat, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  sx={{
                    p: 3,
                    textAlign: 'center',
                    background: alpha(theme.palette.background.paper, 0.4),
                    backdropFilter: 'blur(10px)',
                    borderRadius: 3,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.1)}`,
                  }}
                >
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {stat.value}
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                    {stat.label}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    );
  };

  // App Promo Section
  const AppPromo = () => (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/assets/tela1.jpg"
              sx={{
                width: '100%',
                maxWidth: 300,
                mx: 'auto',
                display: 'block',
                transform: 'rotate(-5deg)',
                boxShadow: theme.shadows[10],
                borderRadius: 4,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h2" gutterBottom fontWeight="bold">
              Baixe o App da Torki
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              Tenha o maior classificado de veículos na palma da sua mão
            </Typography>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              {[
                "Notificações de novos veículos", 
                "Chat direto com vendedores", 
                "Financiamento facilitado", 
                "Vistoria garantida"
              ].map((feature) => (
                <Grid item xs={12} key={feature}>
                  <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 2, bgcolor: 'background.paper' }}>
                    <Star color="primary" />
                    <Typography>{feature}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="large" startIcon={<Apple />} color="primary" sx={{ py: 2, px: 4 }}>
                App Store
              </Button>
              <Button variant="contained" size="large" startIcon={<Android />} color="primary" sx={{ py: 2, px: 4 }}>
                Play Store
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  // Floating Download Prompt
  const DownloadPrompt = () => (
    <FloatingAppCTA>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        Veja mais ofertas no App da Torki!
      </Typography>
      <Button variant="contained" color="primary" startIcon={<Download />} sx={{ whiteSpace: 'nowrap' }}>
        Baixar Agora
      </Button>
      <IconButton size="small" onClick={() => setShowDownloadPrompt(false)} sx={{ position: 'absolute', right: 8, top: 8 }}>
        <Close fontSize="small" />
      </IconButton>
    </FloatingAppCTA>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <Header />
        <Hero />
        {/* Seção de Registro de Veículos */}
        <VehicleRegistration />
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <Typography variant="h3" gutterBottom fontWeight="bold" color="primary.main">
            Veículos em Destaque
          </Typography>
          <Grid container spacing={3}>
            {vehicles.map((vehicle) => (
              <Grid item xs={12} sm={6} md={4} key={vehicle._id}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.2s',
                    '&:hover': { transform: 'translateY(-8px)' },
                  }}
                  onClick={() => navigate(`/classificados/detalhes/${vehicle._id}`)}
                >
                  <CardMedia 
                    component="img" 
                    height="200" 
                    image={vehicle.imagens[0]} 
                    alt={vehicle.vehicleId.name} 
                  />
                  <CardContent>
                    <Typography variant="h6" gutterBottom color="primary.main">
                      {vehicle.vehicleId.name}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                      {vehicle.vehicleId.highlights?.map((highlight) => (
                        <Chip 
                          key={highlight} 
                          label={highlight} 
                          size="small" 
                          color="primary" 
                          variant="outlined" 
                        />
                      ))}
                    </Stack>
                    <Typography variant="h5" color="primary.main" gutterBottom>
                      R$ {vehicle.price.toLocaleString('pt-BR')}
                    </Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <CalendarMonth fontSize="small" color="primary" />
                        <Typography variant="body2">{vehicle.vehicleId.year}</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Speed fontSize="small" color="primary" />
                        <Typography variant="body2">{vehicle.vehicleId.odometer}km</Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LocalGasStation fontSize="small" color="primary" />
                        <Typography variant="body2">{vehicle.vehicleId.combustivel}</Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
        <AppPromo />
        <Footer />
        {showDownloadPrompt && <DownloadPrompt />}
        <Fab color="primary" aria-label="share" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
          <Share />
        </Fab>
      </Box>
    </ThemeProvider>
  );
};

export default TorkiClassifieds;
