import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useScrollTrigger,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  ChevronRight,
  Menu as MenuIcon,
  Close as CloseIcon,
  AccessTime as AccessTimeIcon,
  FlashOn as FlashOnIcon,
  Money as MoneyIcon,
  Business as BusinessIcon,
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import autoParts from './images/autoparts.jpeg';
import CookieConsent from './CookiesOk';

// Componente de Contagem Regressiva (FOMO)
const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const deadline = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const difference = deadline - new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        h: Math.floor((difference / (1000 * 60 * 60)) % 24),
        m: Math.floor((difference / (1000 * 60)) % 60),
        s: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2} p={1} bgcolor="error.main" borderRadius={2} color="white">
      <AccessTimeIcon sx={{ mr: 1 }} />
      <Typography variant="subtitle1" align="center">
        Corra! Oferta termina em:{"50"}
        {timeLeft.h != null ? (
          <>
            <strong>{timeLeft.h}h</strong> : <strong>{timeLeft.m}m</strong> : <strong>{timeLeft.s}s</strong>
          </>
        ) : "Encerrada!"}
      </Typography>
    </Box>
  );
};

// Tema customizado (mantendo identidade visual)
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#F97316' },
    secondary: { main: '#0A192F' },
    background: { default: '#0A192F', paper: '#112240' }
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '4rem',
      fontWeight: 900,
      lineHeight: 1.2,
      '@media (max-width:600px)': { fontSize: '2.8rem' }
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 800,
      lineHeight: 1.2,
      '@media (max-width:600px)': { fontSize: '2.2rem' }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          padding: '18px 36px',
          fontSize: '1.3rem',
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
          '&:hover': { transform: 'scale(1.05)', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)' }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          background: alpha('#112240', 0.85),
          backdropFilter: 'blur(8px)',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': { transform: 'translateY(-10px)' }
        }
      }
    }
  }
});

// Componentes estilizados
const GradientBox = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
  position: 'relative',
  overflow: 'hidden'
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  transition: 'all 0.3s ease-in-out',
  '&.scrolled': {
    background: alpha(theme.palette.background.default, 0.95),
    backdropFilter: 'blur(10px)'
  }
}));

const FloatingEffect = styled(Box)({
  position: 'absolute',
  borderRadius: '50%',
  filter: 'blur(60px)',
  animation: 'float 15s infinite ease-in-out',
  '@keyframes float': {
    '0%, 100%': { transform: 'translateY(0)' },
    '50%': { transform: 'translateY(-30px)' }
  }
});

// Seção Hero – Duas colunas com CTA impactante
const HeroSection = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        background: 'linear-gradient(135deg, #0A192F 0%, #112240 100%)',
        py: { xs: 4, md: 8 }
      }}
    >
      <Container>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <Typography variant="h1" gutterBottom sx={{ fontWeight: 900 }}>
              Última Chance: Livre-se dos Débitos Agora!
            </Typography>
            <Typography variant="h5" color="text.secondary" paragraph>
              Não fique refém dos débitos veiculares! Regularize IPVA, multas, seguro obrigatório e licenciamento com condições IMPERDÍVEIS.
            </Typography>
            <CountdownTimer />
            <Box sx={{ mt: 4 }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                fullWidth={isMobile}
                href="https://debitosveiculares.celcoin.com.br/webview/torki"
                target="_blank"
                sx={{ mb: isMobile ? 2 : 0 }}
              >
                Clique aqui! Consulte sua placa e Mude Sua Vida!
              </Button>
              {!isMobile && (
                <Button variant="outlined" color="primary" size="large" href="#features" sx={{ ml: 2 }}>
                  Descubra Como
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box component="img" src={autoParts} alt="Veículo" sx={{ maxWidth: '100%', borderRadius: 2, boxShadow: 3 }} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

// Seção de Features – Soluções em Cards
const FeaturesSection = () => {
  const features = [
    {
      title: "Consulta Instantânea",
      description:
        "Confira agora mesmo seus débitos de IPVA, multas, seguro obrigatório e licenciamento com apenas um clique.",
      items: ["IPVA", "Multas", "Seguro Obrigatório", "Licenciamento"]
    },
    {
      title: "Pagamento Ultra-Rápido",
      description:
        "Quite seus débitos em segundos com métodos exclusivos, sem burocracia e com total segurança.",
      items: ["Integração Bancária", "Parcelamento Fácil", "Confirmação Instantânea"]
    },
    {
      title: "Controle Total",
      description:
        "Gerencie suas pendências com um painel exclusivo e relatórios que te dão o poder de decisão.",
      items: ["Dashboard Exclusivo", "Histórico Completo", "Relatórios Dinâmicos"]
    }
  ];

  return (
    <Box id="features" sx={{ py: 8, backgroundColor: '#112240' }}>
      <Container>
        <Typography variant="h2" textAlign="center" color="primary" gutterBottom>
          Soluções Que Vão Mudar Seu Jogo
        </Typography>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  <Typography variant="h5" gutterBottom color="primary">
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    {feature.description}
                  </Typography>
                  <Box component="ul" sx={{ pl: 2, m: 0 }}>
                    {feature.items.map((item, idx) => (
                      <Typography key={idx} component="li" variant="body2" color="text.secondary">
                        {item}
                      </Typography>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

// Seção de FAQ – Perguntas Frequentes com Accordion
const FAQSection = () => {
  return (
    <Box id="faq" sx={{ py: 8, backgroundColor: '#0A192F' }}>
      <Container>
        <Typography variant="h3" align="center" color="primary" gutterBottom>
          Perguntas Frequentes
        </Typography>
        <Accordion sx={{ background: alpha('#112240', 0.8), color: 'white' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography>1. Quais estados estão operacionais?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Atualmente, as consultas não estão disponíveis para os estados da Bahia (BA), Pará (PA), Tocantins (TO), Amapá (AP), Amazonas (AM) e Sergipe (SE). Estima-se que esses estados estejam 100% operacionais até o segundo semestre de 2025.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: alpha('#112240', 0.8), color: 'white', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography>2. Como funciona a quitação dos débitos?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              A quitação dos débitos no Detran será efetuada após a análise de risco e aprovação do pagamento. Essa análise pode levar até 72 horas úteis entre a análise e a liquidação do débito. Após o pagamento, o órgão demora até 48 horas úteis para dar baixa. Caso tenha dúvidas, nossos canais de atendimento estão disponíveis.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: alpha('#112240', 0.8), color: 'white', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography>3. Os débitos são parceláveis?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Sim! Todos os débitos constantes no Detran são parceláveis: multa, IPVA, licenciamento, seguro obrigatório, etc.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: alpha('#112240', 0.8), color: 'white', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography>4. Quais meios de pagamento estão disponíveis?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Por enquanto, apenas o meio de cartão de crédito em até 12x está disponível. Em breve, a forma de pagamento via PIX também estará disponível.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ background: alpha('#112240', 0.8), color: 'white', mt: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography>5. Quem oferece este produto?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Este produto é oferecido em parceria com a Celcoin e Pronto Paguei, empresas consolidadas e especialistas em transações online.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>
    </Box>
  );
};

// Seção de CTA Final – Linguagem Direta e Urgente
const CTASection = () => {
  return (
    <Box sx={{ py: 6, background: 'linear-gradient(135deg, #0A192F, #112240)' }}>
      <Container>
        <Typography variant="h3" textAlign="center" color="white" gutterBottom>
          Não Perca Mais Tempo!
        </Typography>
        <Typography variant="h6" textAlign="center" color="text.secondary" paragraph>
          Regularize seus débitos agora e evite complicações. Cada segundo conta – sua liberdade financeira depende dessa decisão!
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Button variant="contained" color="primary" size="large" href="https://debitosveiculares.celcoin.com.br/webview/torki" target="_blank">
            Pague Agora e Liberte-se!
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

// Footer
const FooterSection = () => {
  return (
    <Box component="footer" sx={{ py: 4, backgroundColor: '#112240' }}>
      <Container>
        <Typography variant="body2" color="text.secondary" align="center">
          © {new Date().getFullYear()} Torki. Todos os direitos reservados. CNPJ: 36.097.118/0001-27
        </Typography>
      </Container>
    </Box>
  );
};

// Landing Page – Com App Bar, Hero, Features, FAQ, CTA e Footer
const LandingPage = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 50 });
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CookieConsent />

      {/* App Bar */}
      <StyledAppBar position="fixed" className={trigger ? 'scrolled' : ''}>
        <Container sx={{ px: { xs: 2, sm: 4 } }}>
          <Box display="flex" alignItems="center" py={2}>
            <Typography variant="h6" fontWeight="bold">
              Torki
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} gap={3}>
              <Button color="inherit" href="#features">
                Soluções
              </Button>
              <Button color="inherit" href="#faq">
                FAQ
              </Button>
              <Button color="inherit" href="#about">
                Sobre
              </Button>
              <Button variant="contained" color="primary" href="https://debitosveiculares.celcoin.com.br/webview/torki" target="_blank">
                Pague Agora!
              </Button>
            </Box>
            <IconButton color="inherit" onClick={handleDrawerToggle} sx={{ display: { md: 'none' } }}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Container>
      </StyledAppBar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { width: '80%', backgroundColor: theme.palette.background.default }
        }}
      >
        <Box p={2}>
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem button onClick={handleDrawerToggle} component="a" href="#features">
            <ListItemText primary="Soluções" />
          </ListItem>
          <ListItem button onClick={handleDrawerToggle} component="a" href="#faq">
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem button onClick={handleDrawerToggle} component="a" href="#about">
            <ListItemText primary="Sobre" />
          </ListItem>
          <ListItem button onClick={handleDrawerToggle} component="a" href="https://debitosveiculares.celcoin.com.br/webview/torki" target="_blank">
            <ListItemText primary="Pague Agora!" />
          </ListItem>
        </List>
      </Drawer>

      {/* Conteúdo Principal */}
      <Box mt={10}>
        <HeroSection />
        <FeaturesSection />
        <FAQSection />
        <CTASection />
        <FooterSection />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
