import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Link } from '@mui/material';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Verifica se o usuário já aceitou os cookies
    const accepted = localStorage.getItem('cookieAccepted');
    if (!accepted) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setOpen(false);
  };

  if (!open) return null;

  return (
    <Paper 
      elevation={3}
      sx={{
        position: 'fixed',
        bottom: 16,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: 2,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: 2,
        zIndex: 1300,
      }}
    >
      <Box>
        <Typography variant="body2">
          Utilizamos cookies para melhorar sua experiência e personalizar conteúdos e anúncios, 
          além de analisar o tráfego do site, conforme a LGPD. Ao continuar navegando, você 
          concorda com nossa <Link href="/politica-de-privacidade" underline="hover">Política de Privacidade</Link>.
        </Typography>
      </Box>
      <Button variant="contained" color="primary" onClick={handleAccept}>
        Aceitar
      </Button>
    </Paper>
  );
};

export default CookieConsent;
