import React, { useState, useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Badge
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  ArrowBack,
  CalendarMonth,
  Speed,
  LocalGasStation,
  LocationOn,
  Phone,
  WhatsApp,
  Share,
  Favorite,
  FavoriteBorder,
  NavigateNext,
  LocalOffer,
  NavigateBefore,
  Shield,
  Handshake,  
  LocalOffer as TagIcon,  
  DirectionsCar,
  Build,
  Timeline
} from '@mui/icons-material';

import CertifiedBadge from './components/CertifiedBadge';
import FipeComparisonBadge from './components/FipeComparisonBadge';

import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './components/Footer';
import Header from './components/Header';
import HistoricoAtendimentos from './components/HistoricoAtendimentos';

const EnhancedVehicleDetails = ({ idVeiculo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // States
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [showShareSnackbar, setShowShareSnackbar] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleData, setVehicleData] = useState(null);
  const [similarVehicles, setSimilarVehicles] = useState([]);
  const [showPromoDialog, setShowPromoDialog] = useState(false);

  // API Config
const API_CONFIG = {
  ANYCAR_URL: 'https://api-v2.anycar.com.br/integracao/consultar',
  API_KEY: 'vTfSS8jSiCKu414mC8pjWFIqkFI6Fzyu',
  VEHICLE_API: 'https://sea-lion-app-fhfoo.ondigitalocean.app/api/vehicles',
  CONFIGURACAO_URL: 'https://sea-lion-app-fhfoo.ondigitalocean.app',
  DEBITOS_URL: 'https://debitosveiculares.celcoin.com.br/webview/torki'
};
  

  const isCertified = (data) => {
    if (!data?.vehicleId?.service_history?.length) {
      return false;
    }
  
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
    return data.vehicleId.service_history.some(service => {
      const isCompleted = service.status?.toLowerCase() === 'concluído';
      const serviceDate = new Date(service.scheduled_date);
      const isRecent = serviceDate >= thirtyDaysAgo;
  
      console.log("Verificação do serviço:", {
        data: serviceDate,
        concluido: isCompleted,
        recente: isRecent,
        status: service.status
      });
  
      return isCompleted && isRecent;
    });
  };

  // Função para obter o dia de rodízio com base no último dígito da placa
  const getRodizioInfo = (plate) => {
    const lastDigit = plate.slice(-1);
    switch (lastDigit) {
      case '1':
      case '2':
        return 'Segunda-feira';
      case '3':
      case '4':
        return 'Terça-feira';
      case '5':
      case '6':
        return 'Quarta-feira';
      case '7':
      case '8':
        return 'Quinta-feira';
      case '9':
      case '0':
        return 'Sexta-feira';
      default:
        return 'Não disponível';
    }
  };

  // Fetch vehicle data
  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await fetch(`${API_CONFIG.CONFIGURACAO_URL}/api/listings/${idVeiculo}`);
        const data = await response.json();
        setVehicleData(data.listing); // Ajustado para acessar data.listing
      } catch (error) {
        console.error('Erro ao carregar dados do veículo:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSimilarVehicles = async () => {
      try {
        const response = await fetch(`${API_CONFIG.CONFIGURACAO_URL}/api/listings/${idVeiculo}/similar`);
        const similarData = await response.json();
        setSimilarVehicles(similarData);
      } catch (error) {
        console.error('Erro ao carregar veículos similares:', error);
      }
    };

    

    fetchVehicleData();
    fetchSimilarVehicles();
    
  }, [idVeiculo]);


  

  // Vehicle specs configuration
  const specs = [
    {
      icon: <CalendarMonth />,
      label: 'Ano',
      value: isLoading ? '' : `${vehicleData?.vehicleId?.year}/${vehicleData?.vehicleId?.year_model}`,
      tooltip: 'Ano de fabricação/modelo'
    },
    {
      icon: <Speed />,
      label: 'Quilometragem',
      value: isLoading ? '' : `${vehicleData?.vehicleId?.odometer} km`,
      tooltip: 'Quilometragem atual do veículo'
    },
    {
      icon: <LocalGasStation />,
      label: 'Consumo',
      value: isLoading ? '' : `${vehicleData?.vehicleId?.consumption} km/l`,
      tooltip: 'Consumo médio do veículo'
    },
    {
      icon: <LocationOn />,
      label: 'UF',
      value: isLoading ? '' : vehicleData?.vehicleId?.cidade + " - " + vehicleData?.vehicleId?.uf_jurisdicao,
      tooltip: 'Estado do veículo'
    },
    {
      icon: <DirectionsCar />,
      label: 'Tipo',
      value: isLoading ? '' : vehicleData?.vehicleId?.tipo_veiculo,
      tooltip: 'Tipo do veículo'
    },
    {
      icon: <DirectionsCar />,
      label: 'Cor',
      value: isLoading ? '' : vehicleData?.vehicleId?.color,
      tooltip: 'Cor do veículo'
    },
    {
      icon: <DirectionsCar />,
      label: 'Valor Fipe',
      value: isLoading ? '' : vehicleData?.vehicleId?.tabela_fipe
        ? `R$ ${parseFloat(vehicleData?.vehicleId?.tabela_fipe).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}`
        : 'Sob consulta',
      tooltip: 'Valor FIPE do veículo'
    },
    // {
    //   icon: <DirectionsCar />,
    //   label: 'Descrição',
    //   value: isLoading ? '' : vehicleData?.description,
    //   tooltip: 'Descrição'
    // },
    {
      icon: <LocalOffer />,
      label: 'Placa',
      value: isLoading 
        ? '' 
        : (
          <Stack spacing={0.5}>
            <Typography variant="body1" fontWeight="bold">
              ***{vehicleData?.vehicleId?.plate?.slice(-3)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Rodízio: {getRodizioInfo(vehicleData?.vehicleId?.plate)}
            </Typography>
          </Stack>
        ),
      tooltip: 'Placa do veículo'
    }
  ];


  // Promotional content
  const promoBanners = [
    {
      title: "Proteção Torki Premium",
      icon: <Shield sx={{ fontSize: 40 }} />,
      description: "Garantia estendida e proteção contra fraudes",
      buttonText: "Contratar Proteção",
      color: "primary.main",
      action: () => setShowPromoDialog(true)
    },
    {
      title: "Financiamento Facilitado",
      icon: <Handshake sx={{ fontSize: 40 }} />,
      description: "As melhores taxas do mercado com a Torki",
      buttonText: "Simular agora",
      color: "success.main",
      action: () => setShowPromoDialog(true)
    }
  ];

  // Component: Image Gallery
  const ImageGallery = () => (
    <Box sx={{ position: 'relative' }}>
      {isLoading ? (
        <Skeleton variant="rectangular" height={500} />
      ) : (
        <>
          <Box
            component="img"
            src={vehicleData?.imagens?.[activeImageIndex] || '/api/placeholder/800/500'}
            sx={{
              width: '100%',
              height: { xs: 300, sm: 400, md: 500 },
              objectFit: 'cover',
              borderRadius: 2,
              cursor: 'pointer',
            }}
            onClick={() => setShowFullScreen(true)}
          />
          <Stack
            direction="row"
            spacing={1}
            sx={{
              position: 'absolute',
              top: 16,
              right: 16,
              zIndex: 1,
            }}
          >
            <Tooltip title="Adicionar aos favoritos">
              <IconButton
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'background.paper' },
                }}
                onClick={() => {
                  setIsFavorite(!isFavorite);
                  setShowShareSnackbar(true);
                }}
              >
                {isFavorite ? <Favorite color="error" /> : <FavoriteBorder />}
              </IconButton>
            </Tooltip>
            <Tooltip title="Compartilhar">
              <IconButton
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'background.paper' },
                }}
              >
                <Share />
              </IconButton>
            </Tooltip>
          </Stack>
  
          {/* Navigation arrows */}
          <IconButton
            sx={{
              position: 'absolute',
              left: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'background.paper' },
            }}
            onClick={() => setActiveImageIndex((prev) => (prev > 0 ? prev - 1 : 0))}
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            sx={{
              position: 'absolute',
              right: 16,
              top: '50%',
              transform: 'translateY(-50%)',
              bgcolor: 'background.paper',
              '&:hover': { bgcolor: 'background.paper' },
            }}
            onClick={() => setActiveImageIndex((prev) => (prev < (vehicleData?.imagens?.length - 1) ? prev + 1 : 0))}
          >
            <NavigateNext />
          </IconButton>
        </>
      )}
    </Box>
  );
  
  

  // Component: Promotional Banner
  const PromotionalBanner = ({ title, icon, description, buttonText, color, action }) => (
    <Paper 
      sx={{ 
        p: 3, 
        mb: 2, 
        background: `linear-gradient(45deg, ${color}, ${color}dd)`, 
        color: 'white',
        transition: 'transform 0.2s',
        '&:hover': {
          transform: 'translateY(-2px)',
        }
      }}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Box>{icon}</Box>
        <Box flex={1}>
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="primary">{description}</Typography>
        </Box>
        <Button 
          variant="contained" 
          sx={{ bgcolor: 'white', color: color, '&:hover': { bgcolor: 'white' } }}
          onClick={action}
        >
          {buttonText}
        </Button>
      </Stack>
    </Paper>
  );

  
  
  

// Component: Similar Vehicles
const SimilarVehicles = () => (
  <Container sx={{ mt: 4 }}>
    <Typography variant="h6" color="text.primary">Veículos similares</Typography>
    <Grid container spacing={2}>
      {Array.isArray(similarVehicles) ? similarVehicles.slice(0, 3).map((vehicle) => (
        <Grid item xs={12} sm={4} key={vehicle.id}>
          <Card onClick={() => navigate(`/classificados/detalhes/${vehicle.id}`)}>
            <CardMedia
              component="img"              
              height="140"
              image={vehicle.imagens?.[0] || '/api/placeholder/400/140'}
              alt={vehicle.vehicleId?.name}
            />
            <CardContent>
              <Typography variant="subtitle1" fontWeight="bold">{vehicle.vehicleId?.name}</Typography>
              <Typography variant="h6" color="primary">
                R$ {parseFloat(vehicle.price).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
              </Typography>
              <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                <Typography variant="body2">Ano: {vehicle.vehicleId?.year}</Typography>
                <Typography variant="body2">Km: {vehicle.vehicleId?.odometer}</Typography>
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {vehicle.vehicleId?.cidade} - {vehicle.vehicleId?.uf_jurisdicao}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Placa: ***{vehicle.vehicleId?.plate?.slice(-3)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Rodízio: {getRodizioInfo(vehicle.vehicleId?.plate)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      )) : <Typography>Nenhum veículo similar encontrado</Typography>}
    </Grid>
  </Container>
);


  // Component: Torki Advantages
  const TorkiAdvantages = () => (
    <Card sx={{ mt: 4, p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Por que comprar na Torki?
      </Typography>
      <Grid container spacing={3}>
        {[
          { 
            icon: <Shield sx={{ fontSize: 40 }} />, 
            title: "Garantia Torki", 
            desc: "Proteção completa na sua compra por até 12 meses" 
          },
          { 
            icon: <Build sx={{ fontSize: 40 }} />, 
            title: "Inspeção 150+ itens", 
            desc: "Verificação completa por especialistas" 
          },
          { 
            icon: <Handshake sx={{ fontSize: 40 }} />, 
            title: "Negociação Segura", 
            desc: "Acompanhamento em toda negociação" 
          }
        ].map((advantage, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Stack spacing={2} alignItems="center" textAlign="center">
              {advantage.icon}
              <Typography variant="subtitle1" fontWeight="bold">
                {advantage.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {advantage.desc}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Card>
  );

  // Contact Dialog
  const ContactDialog = () => (
    <Dialog open={showContact} onClose={() => setShowContact(false)} fullScreen={isMobile}>
      <DialogContent>
        <Stack spacing={3}>
          <Typography variant="h6">Entre em contato</Typography>
          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              variant="contained"
              startIcon={<WhatsApp />}
              href={`https://wa.me/${vehicleData?.owner_details?.owner_phone}`}
              target="_blank"
            >
              WhatsApp
            </Button>
            <Button
              fullWidth
              variant="outlined"
              startIcon={<Phone />}
              href={`tel:${vehicleData?.owner_details?.owner_phone}`}
            >
              Ligar
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );

  // Image Fullscreen Dialog
  const FullScreenDialog = () => (
    <Dialog
      open={showFullScreen}
      onClose={() => setShowFullScreen(false)}
      maxWidth="xl"
      fullWidth
    >
      <DialogContent>
        <Box
          component="img"
          src={vehicleData?.imagens?.[activeImageIndex] || '/api/placeholder/800/500'}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'contain'
          }}
        />
      </DialogContent>
    </Dialog>
  );
  

  return (
      <>
      <Header />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Helmet>
          <title>
          {`${vehicleData?.vehicleId?.name || 'Veículo'} ${vehicleData?.vehicleId?.fabricante || ''} - Torki`}
          </title>
        </Helmet>

        {isLoading ? (
          <LinearProgress />
        ) : (
          <>
            <Button
              startIcon={<ArrowBack />}
              onClick={() => navigate(-1)}
              sx={{ mb: 2 }}
            >
              Voltar
            </Button>

            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <ImageGallery />
                
                <Box sx={{ mt: 3 }} color="text.primary">
                <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                    {vehicleData?.classified_vehicle_name} {vehicleData?.model}
                  </Typography>
                  <Typography variant="h5" color="primary" gutterBottom>
                    {vehicleData?.price 
                      ? `R$ ${parseFloat(vehicleData.price).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}`
                      : 'Sob consulta'
                    }                    
                  </Typography>                  

                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    {specs.map((spec, index) => (
                      <Grid item xs={6} sm={4} key={index}>
                        <Tooltip title={spec.tooltip}>
                          <Paper sx={{ p: 2 }}>
                            <Stack direction="row" spacing={1} alignItems="center">
                              {spec.icon}
                              <Box>
                                <Typography variant="caption" color="text.secondary">
                                  {spec.label}
                                </Typography>
                                <Typography variant="body1">
                                  {spec.value}
                                </Typography>
                              </Box>
                            </Stack>
                          </Paper>
                        </Tooltip>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <HistoricoAtendimentos user_id={vehicleData?.vehicleId?.userId} />     
                {/* Add the FIPE comparison badge */}
                <FipeComparisonBadge 
                      price={vehicleData?.price}
                      fipeValue={vehicleData?.vehicleId?.tabela_fipe}
                    />

                <SimilarVehicles />
              </Grid>

              <Grid item xs={12} md={4}>
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    size="large"
                    startIcon={<WhatsApp />}
                    onClick={() => setShowContact(true)}
                    fullWidth
                  >
                    Contatar vendedor
                  </Button>

                  {promoBanners.map((banner, index) => (
                    <PromotionalBanner key={index} {...banner} />
                  ))}
                  
                  <TorkiAdvantages />
                </Stack>
              </Grid>
            </Grid>
          </>
        )}

        <ContactDialog />
        <FullScreenDialog />


        <Snackbar
          open={showShareSnackbar}
          autoHideDuration={3000}
          onClose={() => setShowShareSnackbar(false)}
          message={isFavorite ? "Adicionado aos favoritos" : "Removido dos favoritos"}
        />
      </Container>
      <Footer />  
      </>          
  );
};

export default EnhancedVehicleDetails;


