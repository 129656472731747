import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Container,
  IconButton,
  InputBase,
  Drawer,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useScrollTrigger,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  CssBaseline,
  Paper,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Badge,
  MobileStepper,
  Fab,
  Zoom,
  Tooltip
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  DirectionsCar as CarIcon,
  LocalOffer as OfferIcon,
  CalendarToday as CalendarIcon,
  AccessTime as TimeIcon,
  Share as ShareIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  WhatsApp as WhatsAppIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Person as PersonIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Timer as TimerIcon,
  LocalParking as DiscountIcon,
  Notifications as NotificationsIcon,
  BusinessCenter as BusinessCenterIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  ShoppingCart as ShoppingCartIcon,
  Add as AddIcon,
  Info as InfoIcon,
  Settings as SettingsIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  LocalCarWash as LocalCarWashIcon,
  Chat as ChatIcon,
  Call as CallIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Tema atualizado com cores mais vibrantes e impactantes
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF3D00', // Laranja mais intenso
      light: '#FF7539',
      dark: '#DD2C00',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#071429', // Azul mais escuro
      light: '#132543',
      dark: '#050E1B',
      contrastText: '#FFF',
    },
    background: {
      default: '#071429',
      paper: '#0D1E38',
      card: '#132543',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B7C3',
    },
    action: {
      hover: alpha('#FF3D00', 0.2),
      selected: alpha('#FF3D00', 0.3),
    },
    error: {
      main: '#FF3D00',
    },
    success: {
      main: '#00E676',
    }
  },
  typography: {
    fontFamily: '"Montserrat", "Inter", "Roboto", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontSize: '2.75rem',
      fontWeight: 900,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      '@media (min-width:600px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 900,
      lineHeight: 1.1,
      letterSpacing: '-0.01em',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 800,
      lineHeight: 1.2,
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 800,
      lineHeight: 1.2,
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 700,
      lineHeight: 1.3,
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: 'smooth',
        },
        body: {
          overflowX: 'hidden',
        },
        '::selection': {
          backgroundColor: alpha('#FF3D00', 0.7),
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '12px 24px',
          fontWeight: 700,
          transition: 'all 0.3s ease-in-out',
          textTransform: 'none',
        },
        containedPrimary: {
          boxShadow: '0 4px 18px 0 rgba(255, 61, 0, 0.4)',
          '&:hover': {
            boxShadow: '0 8px 25px rgba(255, 61, 0, 0.65)',
            transform: 'translateY(-3px)',
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
            transform: 'translateY(-2px)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: 16,
          background: alpha('#132543', 0.7),
          backdropFilter: 'blur(10px)',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.25)',
          border: `1px solid ${alpha('#FFFFFF', 0.05)}`,
          transition: 'transform 0.4s ease, box-shadow 0.4s ease',
          '&:hover': {
            transform: 'translateY(-12px)',
            boxShadow: '0 20px 50px rgba(0, 0, 0, 0.35)',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          fontWeight: 700,
          '&.MuiChip-filled': {
            background: alpha('#FF3D00', 0.95),
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transition: 'all 0.3s ease-in-out',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
  },
});

// Componentes estilizados
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  transition: 'all 0.4s ease-in-out',
  '&.scrolled': {
    background: alpha(theme.palette.background.default, 0.85),
    backdropFilter: 'blur(20px)',
    boxShadow: `0 8px 32px 0 ${alpha('#000', 0.2)}`,
  },
}));

const SearchBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  background: alpha(theme.palette.background.card, 0.8),
  backdropFilter: 'blur(15px)',
  borderRadius: 14,
  padding: theme.spacing(0.5, 2),
  margin: theme.spacing(3, 0),
  border: `1px solid ${alpha('#FFFFFF', 0.08)}`,
  transition: 'all 0.3s ease',
  '&:focus-within': {
    boxShadow: `0 0 0 3px ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    transform: 'scale(1.02)',
  },
}));

const FeaturedCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  height: '100%',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '65%',
    background: 'linear-gradient(to top, rgba(7,20,41,0.98) 0%, rgba(7,20,41,0.7) 50%, rgba(7,20,41,0) 100%)',
    zIndex: 1,
  },
}));

const ContentCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    inset: 0,
    borderRadius: 16,
    padding: '2px',
    background: `linear-gradient(120deg, transparent 0%, transparent 40%, ${alpha(theme.palette.primary.main, 0.5)} 50%, transparent 60%, transparent 100%)`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    opacity: 0,
    transition: 'opacity 0.4s ease',
  },
  '&:hover::before': {
    opacity: 1,
  }
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '0.75rem',
  height: 30,
  borderRadius: 6,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&.outline': {
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  }
}));

const ActionChip = styled(Chip)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '0.85rem',
  height: 36,
  borderRadius: 18,
  padding: '0 12px',
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  backgroundColor: theme.palette.primary.main,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
  },
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  backdropFilter: 'blur(8px)',
  color: theme.palette.primary.main,
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'translateY(-4px)',
    boxShadow: `0 6px 20px ${alpha(theme.palette.primary.main, 0.4)}`,
  },
}));

const CTASection = styled(Box)(({ theme }) => ({
  position: 'relative',
  background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.secondary.main} 100%)`,
  borderRadius: 24,
  padding: theme.spacing(6, 4),
  overflow: 'hidden',
  boxShadow: '0 25px 50px rgba(0,0,0,0.3)',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `radial-gradient(circle at 30% 50%, ${alpha(theme.palette.primary.main, 0.25)} 0%, transparent 50%)`,
    zIndex: 0,
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    right: '-50%',
    bottom: '-50%',
    left: '-50%',
    background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, ${alpha('#fff', 0.2)} 50%, rgba(255,255,255,0) 100%)`,
    transform: 'rotateZ(60deg) translate(-5em, 7.5em)',
    animation: 'shine 3s infinite',
    animationDelay: '0.5s',
  },
  '@keyframes shine': {
    '0%': {
      transform: 'rotateZ(60deg) translate(-5em, 7.5em)',
    },
    '100%': {
      transform: 'rotateZ(60deg) translate(5em, -7.5em)',
    },
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  display: 'inline-block',
}));

const PulsatingBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.5s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

// WhatsApp Link Helper Function
const openWhatsApp = (message = '') => {
  const phone = '5511987654321';
  const encodedMessage = encodeURIComponent(message);
  window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank');
};

// Componente de Botão Flutuante de WhatsApp
const WhatsAppFab = () => {
  return (
    <Zoom in={true}>
      <Fab
        color="primary"
        aria-label="whatsapp"
        sx={{
          position: 'fixed',
          bottom: 30,
          right: 30,
          zIndex: 1000,
          bgcolor: '#25D366',
          '&:hover': {
            bgcolor: '#128C7E',
          },
          boxShadow: '0 4px 20px rgba(37, 211, 102, 0.5)',
        }}
        onClick={() => openWhatsApp('Olá! Vim do blog da Torki e gostaria de mais informações sobre os serviços.')}
      >
        <WhatsAppIcon />
      </Fab>
    </Zoom>
  );
};

// Componente principal do Blog
const AutoCenterBlog = () => {
  const [loading, setLoading] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('Todos');
  const [savedPosts, setSavedPosts] = useState([]);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [showPromoPopup, setShowPromoPopup] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [productStep, setProductStep] = useState(0);
  const [showWhatsappBanner, setShowWhatsappBanner] = useState(true);
  
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  // Mostrar popup promocional após 3 segundos
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPromoPopup(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  // Simular carregamento inicial
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 800);
    return () => clearTimeout(timer);
  }, []);
  
  // Mostrar banner do WhatsApp depois de 5 segundos de navegação
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWhatsappBanner(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  // Produtos em destaque para o carrossel
  const featuredProducts = [
    {
      id: 1,
      name: "Óleo Sintético Premium Torki 5W-30",
      description: "Proteção máxima para motores modernos. Aumenta a vida útil e melhora a performance.",
      price: 79.90,
      discountPrice: 59.90,
      image: "https://source.unsplash.com/random/400x300/?oil,synthetic",
      badge: "MAIS VENDIDO",
      features: ["100% sintético", "Aprovado pelas montadoras", "Maior intervalo entre trocas"]
    },
    {
      id: 2,
      name: "Kit Filtros Completo Original",
      description: "Kit com filtros de ar, óleo, combustível e ar condicionado. Compatível com diversos veículos.",
      price: 149.90,
      discountPrice: 119.90,
      image: "https://source.unsplash.com/random/400x300/?car,filter",
      badge: "ECONOMIA",
      features: ["Filtros originais", "Alta durabilidade", "Melhor desempenho"]
    },
    {
      id: 3,
      name: "Pastilhas de Freio Cerâmicas Premium",
      description: "Frenagem superior com menor desgaste dos discos. Baixa emissão de poeira e ruído.",
      price: 189.90,
      discountPrice: 159.90,
      image: "https://source.unsplash.com/random/400x300/?brake,pad",
      badge: "PREMIUM",
      features: ["Tecnologia cerâmica", "Maior vida útil", "Frenagem silenciosa"]
    },
    {
      id: 4,
      name: "Scanner Diagnóstico OBD2 Bluetooth",
      description: "Monitore a saúde do seu veículo diretamente pelo smartphone. Compatível com todos os veículos OBD2.",
      price: 199.90,
      discountPrice: 149.90,
      image: "https://source.unsplash.com/random/400x300/?car,diagnostic",
      badge: "TECNOLOGIA",
      features: ["Conexão Bluetooth", "App gratuito", "Diagnóstico completo"]
    },
    {
      id: 5,
      name: "Pacote de Manutenção Preventiva",
      description: "Kit completo para manutenção preventiva: óleo, filtros, fluido de freio e verificação geral.",
      price: 399.90,
      discountPrice: 299.90,
      image: "https://source.unsplash.com/random/400x300/?car,maintenance",
      badge: "ECONOMIA",
      features: ["Economize até 25%", "Produtos originais", "Mão de obra inclusa"]
    }
  ];

  // Serviços em destaque para o carrossel
  const featuredServices = [
    {
      id: 1,
      title: "Revisão Completa 30 Itens",
      description: "Verificação completa dos principais sistemas do veículo com relatório detalhado.",
      price: "R$ 249,90",
      discountPrice: "R$ 149,90",
      discount: "40% OFF",
      icon: <BuildIcon fontSize="large" />,
      image: "https://source.unsplash.com/random/400x300/?car,checkup",
      whatsappMessage: "Olá! Gostaria de agendar uma Revisão Completa 30 Itens com 40% de desconto!"
    },
    {
      id: 2,
      title: "Troca de Óleo + Filtros",
      description: "Troca de óleo com produtos premium e substituição de todos os filtros necessários.",
      price: "R$ 199,90",
      discountPrice: "R$ 129,90",
      discount: "35% OFF",
      icon: <LocalCarWashIcon fontSize="large" />,
      image: "https://source.unsplash.com/random/400x300/?car,oil,change",
      whatsappMessage: "Olá! Gostaria de agendar uma Troca de Óleo + Filtros com 35% de desconto!"
    },
    {
      id: 3,
      title: "Alinhamento + Balanceamento",
      description: "Alinhamento computadorizado e balanceamento de alta precisão para reduzir desgaste dos pneus.",
      price: "R$ 169,90",
      discountPrice: "R$ 99,90",
      discount: "41% OFF",
      icon: <SettingsIcon fontSize="large" />,
      image: "https://source.unsplash.com/random/400x300/?car,wheel,alignment",
      whatsappMessage: "Olá! Gostaria de agendar um Alinhamento + Balanceamento com 41% de desconto!"
    },
    {
      id: 4,
      title: "Higienização de Ar Condicionado",
      description: "Limpeza completa do sistema de ar condicionado com eliminação de fungos e bactérias.",
      price: "R$ 149,90",
      discountPrice: "R$ 89,90",
      discount: "40% OFF",
      icon: <SpeedIcon fontSize="large" />,
      image: "https://source.unsplash.com/random/400x300/?car,air,conditioning",
      whatsappMessage: "Olá! Gostaria de agendar uma Higienização de Ar Condicionado com 40% de desconto!"
    }
  ];

  // Dados do blog com tags e metadados otimizados para SEO e comercialização
  const categories = [
    "Todos",
    "Manutenção",
    "Tecnologia Auto",
    "Dicas",
    "Novidades",
    "Financiamento",
    "Promoções",
    "Segurança"
  ];

  const featuredPost = {
    id: 1,
    title: "EXCLUSIVO: Nova tecnologia aumenta em até 40% a vida útil dos componentes do seu carro",
    excerpt: "A Torki traz com exclusividade para o Brasil o revolucionário sistema de diagnóstico preditivo que identifica falhas antes que elas ocorram.",
    image: "https://www.motul.com/_next/image?url=https%3A%2F%2Fazupim01.motul.com%2Fmedia%2FmotulData%2FIM%2Fbigweb%2FMotul_105777_Gear_300_75W90_1L_png.png&w=640&q=75/?car,maintenance,technology",
    category: "Tecnologia Auto",
    date: "30 Mar 2025",
    author: "Rodrigo Teixeira - Especialista Certificado",
    authorImage: "https://source.unsplash.com/random/100x100/?person",
    readTime: "5 min",
    views: "3.2K",
    isHot: true,
    savingPoints: "Economize até R$ 3.500 em reparos futuros",
    discount: "40% OFF na primeira avaliação",
    tags: ["inteligência artificial", "diagnóstico", "manutenção preventiva", "sensores automotivos"],
    seoDescription: "Descubra como sensores inteligentes, IA e diagnóstico remoto estão transformando a manutenção preventiva de veículos em 2025.",
    slug: "tecnologia-revolucionando-revisoes-preventivas-2025"
  };

  const blogPosts = [
    {
      id: 2,
      title: "ALERTA: 10 sinais que podem significar uma falha catastrófica na suspensão",
      excerpt: "Ignorar estes sintomas pode custar até 3x mais em reparos emergenciais. Veja como a manutenção preventiva pode salvar seu orçamento.",
      image: "https://source.unsplash.com/random/600x400/?car,suspension",
      category: "Manutenção",
      date: "29 Mar 2025",
      author: "Carla Mendes - Engenheira Automotiva",
      authorImage: "https://source.unsplash.com/random/100x100/?woman",
      readTime: "4 min",
      views: "2.8K",
      isHot: true,
      savingPoints: "Economize até R$ 2.200 em reparos",
      discount: "Check-up gratuito da suspensão",
      tags: ["suspensão", "amortecedores", "manutenção preventiva", "segurança veicular"],
      seoDescription: "Identifique os 10 sinais mais comuns de problemas na suspensão do seu veículo e evite acidentes e gastos desnecessários.",
      slug: "sinais-suspensao-precisa-manutencao-urgente"
    },
    {
      id: 3,
      title: "COMPROVADO: Economize até 30% em combustível com estas técnicas exclusivas",
      excerpt: "Nossos clientes relatam economia real de até R$ 450 por mês após seguir estas recomendações e realizar os ajustes técnicos na Torki.",
      image: "https://source.unsplash.com/random/600x400/?car,fuel",
      category: "Dicas",
      date: "27 Mar 2025",
      author: "Felipe Moraes - Especialista em Eficiência",
      authorImage: "https://source.unsplash.com/random/100x100/?man",
      readTime: "6 min",
      views: "5.1K",
      isHot: true,
      savingPoints: "Economize até R$ 450/mês em combustível",
      discount: "25% OFF na calibração eletrônica",
      tags: ["economia de combustível", "eficiência", "direção econômica", "manutenção"],
      seoDescription: "Técnicas e dicas práticas para economizar até 30% no consumo de combustível do seu veículo mantendo o desempenho ideal.",
      slug: "guia-economia-consumo-combustivel-tecnicas-eficientes"
    },
    {
      id: 4,
      title: "REVELADO: O segredo dos óleos sintéticos premium que as montadoras não contam",
      excerpt: "Descubra por que investir no óleo certo pode adicionar anos à vida do seu motor e poupar mais de R$ 8.000 em reparos futuros.",
      image: "https://source.unsplash.com/random/600x400/?car,engine,oil",
      category: "Manutenção",
      date: "25 Mar 2025",
      author: "Ricardo Oliveira - Mestre em Lubrificação",
      authorImage: "https://source.unsplash.com/random/100x100/?engineer",
      readTime: "5 min",
      views: "3.5K",
      savingPoints: "Proteja seu investimento por mais tempo",
      discount: "35% OFF na troca de óleo premium",
      tags: ["óleo sintético", "lubrificação", "motor", "vida útil"],
      seoDescription: "Análise completa sobre como os modernos óleos sintéticos podem aumentar a vida útil do motor e reduzir a necessidade de reparos.",
      slug: "impacto-oleos-sinteticos-ultima-geracao-vida-util-motor"
    },
    {
      id: 5,
      title: "OPORTUNIDADE: Compre seu carro dos sonhos com aprovação em até 15 minutos",
      excerpt: "A análise de crédito inteligente da Torki aprova financiamentos 5x mais rápido e com taxas até 30% menores que os bancos tradicionais.",
      image: "https://source.unsplash.com/random/600x400/?car,finance",
      category: "Financiamento",
      date: "23 Mar 2025",
      author: "Amanda Silva - Consultora Financeira",
      authorImage: "https://source.unsplash.com/random/100x100/?businesswoman",
      readTime: "7 min",
      views: "4.7K",
      isHot: true,
      savingPoints: "Taxas até 30% menores que os bancos",
      discount: "Primeira parcela grátis",
      tags: ["financiamento auto", "inteligência artificial", "crédito veicular", "Torki"],
      seoDescription: "Como a Torki está usando IA para aprovar financiamentos de veículos até 5x mais rápido e com melhores taxas para os clientes.",
      slug: "financiamento-auto-ia-torki-revolucionando-compra-veiculos"
    },
    {
      id: 6,
      title: "ATENÇÃO: 5 peças que NUNCA deve substituir por alternativas genéricas",
      excerpt: "Um estudo revelou que peças alternativas podem falhar até 300% mais rápido e comprometer a segurança. Saiba quais nunca economizar.",
      image: "https://source.unsplash.com/random/600x400/?car,parts",
      category: "Peças e Acessórios",
      date: "21 Mar 2025",
      author: "Paulo Mendonça - Especialista em Peças",
      authorImage: "https://source.unsplash.com/random/100x100/?mechanic",
      readTime: "5 min",
      views: "6.2K",
      savingPoints: "Segurança garantida para você e sua família",
      discount: "20% OFF em peças originais premium",
      tags: ["peças originais", "segurança", "manutenção", "peças genéricas"],
      seoDescription: "Lista das 5 peças automotivas que exigem componentes originais para garantir a segurança e desempenho do seu veículo.",
      slug: "pecas-originais-nunca-substituir-alternativas-genericas"
    },
    {
      id: 7,
      title: "IMPERDÍVEL: Promoção relâmpago na troca de pastilhas e discos de freio",
      excerpt: "Por tempo limitado: 40% de desconto + alinhamento grátis na troca dos freios. Agende agora e garanta sua segurança com preço imbatível!",
      image: "https://source.unsplash.com/random/600x400/?car,brakes,discount",
      category: "Promoções",
      date: "20 Mar 2025",
      author: "Equipe Torki",
      authorImage: "https://source.unsplash.com/random/100x100/?team",
      readTime: "3 min",
      views: "7.9K",
      isHot: true,
      savingPoints: "Economize R$ 740 na manutenção completa",
      discount: "40% OFF + Alinhamento GRÁTIS",
      tags: ["promoção", "freios", "desconto", "segurança"],
      seoDescription: "Aproveite nossa promoção relâmpago e garanta 40% de desconto na troca de pastilhas e discos de freio, mais alinhamento grátis!",
      slug: "promocao-relampago-freios-alinhamento-gratis"
    },
    {
      id: 8,
      title: "COMPARATIVO: Freios cerâmicos premium vs. convencionais - Qual o custo-benefício real?",
      excerpt: "Análise detalhada revela quando vale a pena investir em freios premium e como isso pode economizar até R$ 3.400 em longo prazo.",
      image: "https://source.unsplash.com/random/600x400/?car,brakes",
      category: "Manutenção",
      date: "17 Mar 2025",
      author: "Lucas Ferreira - Especialista em Freios",
      authorImage: "https://source.unsplash.com/random/100x100/?technician",
      readTime: "6 min",
      views: "3.3K",
      savingPoints: "Maior durabilidade e performance",
      discount: "30% OFF na instalação de freios cerâmicos",
      tags: ["freios cerâmica", "sistema de freios", "manutenção", "performance"],
      seoDescription: "Comparativo detalhado entre freios de cerâmica e convencionais, analisando durabilidade, performance e custo-benefício para diferentes tipos de uso.",
      slug: "freios-ceramica-vs-convencionais-analise-investimento"
    }
  ];

  // Ofertas especiais em destaque
  const specialOffers = [
    {
      id: 1,
      title: "Pacote de Revisão Completa",
      description: "Inclui 30 itens verificados + troca de óleo e filtros",
      discount: "30% OFF",
      originalPrice: "R$ 599,90",
      price: "R$ 419,90",
      validUntil: "05/04/2025",
      image: "https://source.unsplash.com/random/300x200/?car,mechanic"
    },
    {
      id: 2,
      title: "Alinhamento + Balanceamento",
      description: "Rodas alinhadas e balanceadas com equipamento digital",
      discount: "25% OFF",
      originalPrice: "R$ 249,90",
      price: "R$ 187,40",
      validUntil: "03/04/2025",
      image: "https://source.unsplash.com/random/300x200/?car,wheel"
    },
    {
      id: 3,
      title: "Higienização do Ar Condicionado",
      description: "Elimina 99,9% das bactérias e germes com ozônio",
      discount: "35% OFF",
      originalPrice: "R$ 189,90",
      price: "R$ 123,40",
      validUntil: "07/04/2025",
      image: "https://source.unsplash.com/random/300x200/?car,air,conditioner"
    }
  ];

  // Funções de interação
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSavePost = (id) => {
    if (savedPosts.includes(id)) {
      setSavedPosts(savedPosts.filter(postId => postId !== id));
      setNotification({
        open: true,
        message: 'Artigo removido dos favoritos',
        severity: 'info'
      });
    } else {
      setSavedPosts([...savedPosts, id]);
      setNotification({
        open: true,
        message: 'Artigo salvo nos favoritos',
        severity: 'success'
      });
    }
  };

  const handleSharePost = (title, slug) => {
    if (navigator.share) {
      navigator.share({
        title: title,
        text: `Confira este artigo no blog da Torki Auto Center: ${title}`,
        url: `https://torki.com.br/blog/${slug}`,
      })
      .catch(error => console.log('Erro ao compartilhar', error));
    } else {
      // Fallback para navegadores sem API Web Share
      setNotification({
        open: true,
        message: 'Link copiado para a área de transferência',
        severity: 'success'
      });
      navigator.clipboard.writeText(`https://torki.com.br/blog/${slug}`);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };
  
  const handleClosePromoPopup = () => {
    setShowPromoPopup(false);
  };

  const filteredPosts = activeCategory === 'Todos' 
    ? blogPosts 
    : blogPosts.filter(post => post.category === activeCategory);

  // Posts relacionados com base em categorias similares
  const relatedPosts = blogPosts
    .filter(post => post.category === featuredPost.category && post.id !== featuredPost.id)
    .slice(0, 3);

  // Funções para o carrossel de serviços
  const handleNextServiceStep = () => {
    setActiveStep((prevActiveStep) => 
      prevActiveStep === featuredServices.length - 1 ? 0 : prevActiveStep + 1
    );
  };

  const handleBackServiceStep = () => {
    setActiveStep((prevActiveStep) => 
      prevActiveStep === 0 ? featuredServices.length - 1 : prevActiveStep - 1
    );
  };

  // Funções para o carrossel de produtos
  const handleNextProductStep = () => {
    setProductStep((prevStep) => 
      prevStep === Math.ceil(featuredProducts.length / (isTablet ? 2 : 3)) - 1 ? 0 : prevStep + 1
    );
  };

  const handleBackProductStep = () => {
    setProductStep((prevStep) => 
      prevStep === 0 ? Math.ceil(featuredProducts.length / (isTablet ? 2 : 3)) - 1 : prevStep - 1
    );
  };

  // Fechar banner do WhatsApp
  const handleCloseWhatsappBanner = () => {
    setShowWhatsappBanner(false);
  };

  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
      
          <Box
            sx={{
              position: 'fixed',
              right: 30,
              bottom: 30,
              zIndex: 1000,
            }}
          >
            <Zoom in={true}>
              <Fab
                color="primary"
                aria-label="whatsapp"
                sx={{
                  bgcolor: '#25D366',
                  '&:hover': {
                    bgcolor: '#128C7E',
                  },
                  boxShadow: '0 4px 20px rgba(37, 211, 102, 0.5)',
                }}
                onClick={() => openWhatsApp('Olá! Vim do blog da Torki e gostaria de mais informações sobre os serviços.')}
              >
                <WhatsAppIcon />
              </Fab>
            </Zoom>
          </Box>
      
      {/* SEO Optimization */}
      <Helmet>
        <title>TORKI AUTO CENTER | Economize até 40% em manutenção automotiva premium</title>
        <meta name="description" content="Blog exclusivo com dicas de especialistas para economizar em manutenção, aumentar a vida útil do seu veículo e garantir máxima segurança. Promoções imperdíveis!" />
        <meta name="keywords" content="auto center, manutenção automotiva, revisão carro, desconto manutenção, promoção auto center, troca de óleo, freios, suspensão, tecnologia automotiva, financiamento auto" />
        <meta property="og:title" content="TORKI AUTO CENTER | Economize até 40% em manutenção automotiva premium" />
        <meta property="og:description" content="Dicas exclusivas de especialistas certificados para economizar em manutenção, aumentar a vida útil do seu veículo e garantir máxima segurança para sua família." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://torki.com.br/blog" />
        <meta property="og:image" content="https://torki.com.br/images/og-blog-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://torki.com.br/blog" />
        {/* Structured Data para Rich Snippets */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Blog",
              "name": "Torki Auto Center Blog",
              "description": "Blog especializado em manutenção automotiva, dicas de economia, novidades tecnológicas e tudo sobre o mundo dos automóveis.",
              "url": "https://torki.com.br/blog",
              "publisher": {
                "@type": "Organization",
                "name": "Torki Auto Center",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://torki.com.br/images/logo.png"
                }
              },
              "blogPost": [
                {
                  "@type": "BlogPosting",
                  "headline": "${featuredPost.title}",
                  "description": "${featuredPost.seoDescription}",
                  "author": {
                    "@type": "Person",
                    "name": "${featuredPost.author}"
                  },
                  "datePublished": "2025-03-30",
                  "image": "${featuredPost.image}"
                }
              ]
            }
          `}
        </script>
      </Helmet>

      {/* AppBar/Header */}
      <StyledAppBar position="fixed" className={trigger ? 'scrolled' : ''}>
        <Container>
          <Box display="flex" alignItems="center" py={1.5}>
            <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <CarIcon color="primary" sx={{ fontSize: 32 }} />
              <Typography variant="h6" component="div" fontWeight="bold">
                TORKI <Box component="span" sx={{ color: 'primary.main' }}>AUTO</Box>
              </Typography>
            </Box>
            
            <Box sx={{ flexGrow: 1 }} />
            
            {/* Menu Desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} gap={3} alignItems="center">
              <Button color="inherit" href="/servicos">Serviços</Button>
              <Button color="inherit" href="/agendamento">Agendar</Button>
              <Button color="inherit" href="/blog" color="primary">Blog</Button>
              <Button color="inherit" href="/contato">Contato</Button>
              <AnimatedButton 
                variant="contained" 
                color="primary" 
                startIcon={<DiscountIcon />}
                onClick={() => navigate("/promocoes")}
                sx={{ ml: 1 }}
              >
                PROMOÇÕES
              </AnimatedButton>
              
              <PulsatingBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                variant="dot"
              >
                <IconButton color="primary" sx={{ ml: 1 }}>
                  <NotificationsIcon />
                </IconButton>
              </PulsatingBadge>
            </Box>
            
            {/* Menu Mobile Toggle */}
            <IconButton
              color="inherit"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Container>
      </StyledAppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '100%',
            background: theme.palette.background.default,
          },
        }}
      >
        <Box p={2} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem button onClick={() => { navigate('/'); handleDrawerToggle(); }}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/servicos'); handleDrawerToggle(); }}>
            <ListItemText primary="Serviços" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/agendamento'); handleDrawerToggle(); }}>
            <ListItemText primary="Agendar Serviço" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/blog'); handleDrawerToggle(); }}>
            <ListItemText primary="Blog" primaryTypographyProps={{ color: 'primary', fontWeight: 'bold' }} />
          </ListItem>
          <ListItem button onClick={() => { navigate('/contato'); handleDrawerToggle(); }}>
            <ListItemText primary="Contato" />
          </ListItem>
          <Divider sx={{ my: 2 }} />
          <ListItem>
            <AnimatedButton 
              fullWidth 
              variant="contained" 
              color="primary"
              startIcon={<DiscountIcon />}
              onClick={() => { navigate('/promocoes'); handleDrawerToggle(); }}
              size="large"
            >
              VER PROMOÇÕES EXCLUSIVAS
            </AnimatedButton>
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ pt: { xs: 8, sm: 10 } }}>
        {/* WhatsApp Banner */}
        {showWhatsappBanner && (
          <Box 
            sx={{ 
              position: 'relative',
              bgcolor: '#128C7E',
              color: 'white',
              py: 1.5,
              zIndex: 10,
            }}
          >
            <Container maxWidth="lg">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center" gap={1.5}>
                  <WhatsAppIcon />
                  <Typography variant="body1" fontWeight="medium">
                    Atendimento imediato pelo WhatsApp! Tire suas dúvidas agora mesmo.
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ 
                      bgcolor: '#25D366', 
                      color: 'white',
                      '&:hover': { bgcolor: '#1DA951' }
                    }}
                    onClick={() => openWhatsApp('Olá! Estou navegando no blog da Torki e gostaria de falar com um consultor.')}
                  >
                    Falar com consultor
                  </Button>
                  <IconButton size="small" color="inherit" onClick={handleCloseWhatsappBanner}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Container>
          </Box>
        )}
        
        {/* Hero Section */}
        <Box
          sx={{
            background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
            pb: 6,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {/* Decorative Elements */}
          <Box
            sx={{
              position: 'absolute',
              width: '400px',
              height: '400px',
              borderRadius: '50%',
              background: alpha(theme.palette.primary.main, 0.15),
              filter: 'blur(90px)',
              top: '-100px',
              right: '-100px',
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              width: '300px',
              height: '300px',
              borderRadius: '50%',
              background: alpha(theme.palette.primary.main, 0.1),
              filter: 'blur(70px)',
              bottom: '50px',
              left: '-100px',
              zIndex: 0,
            }}
          />
          
          <Container maxWidth="lg">
            {/* Page Title */}
            <Box sx={{ position: 'relative', zIndex: 2, py: { xs: 5, md: 7 }, textAlign: 'center' }}>
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 900,
                  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  backgroundClip: 'text',
                  textFillColor: 'transparent',
                  mb: 2,
                  textTransform: 'uppercase',
                  letterSpacing: '-0.02em'
                }}
              >
                Blog <Box component="span" sx={{ color: theme.palette.primary.main }}>Automotivo</Box> de Especialistas
              </Typography>
              <Typography variant="h5" color="text.secondary" sx={{ maxWidth: '850px', mx: 'auto', mb: 3 }}>
                Dicas <Box component="span" fontWeight="bold" color="primary.main">exclusivas</Box> para economizar até <Box component="span" fontWeight="bold" color="primary.main">40%</Box> em manutenção e garantir máxima performance para seu veículo
              </Typography>
              
              {/* CTA Buttons */}
              <Box display="flex" justifyContent="center" gap={2} flexWrap="wrap" mb={4}>
                <AnimatedButton
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<BusinessCenterIcon />}
                  onClick={() => navigate('/agendamento')}
                >
                  AGENDAR SERVIÇO AGORA
                </AnimatedButton>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<WhatsAppIcon />}
                  onClick={() => window.open('https://wa.me/5511987654321', '_blank')}
                >
                  FALAR COM ESPECIALISTA
                </Button>
              </Box>
              
              {/* Search Box */}
              <SearchBox>
                <InputBase
                  placeholder="O que você procura para seu veículo? Ex: óleo, freios, suspensão..."
                  fullWidth
                  sx={{ ml: 1 }}
                />
                <IconButton color="primary">
                  <SearchIcon />
                </IconButton>
              </SearchBox>
              
              {/* WhatsApp Quick Service Buttons */}
              <Box display="flex" flexWrap="wrap" justifyContent="center" gap={{ xs: 1, sm: 2 }} mt={4} sx={{ maxWidth: '900px', mx: 'auto' }}>
                <Tooltip title="Clique para solicitar orçamento via WhatsApp">
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    sx={{ 
                      bgcolor: '#25D366', 
                      '&:hover': { bgcolor: '#1DA951' },
                      px: 2,
                      borderRadius: '50px'
                    }}
                    onClick={() => openWhatsApp('Olá! Gostaria de um orçamento para revisão do meu veículo.')}
                  >
                    Orçamento Revisão
                  </Button>
                </Tooltip>
                
                <Tooltip title="Clique para agendar pelo WhatsApp">
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    sx={{ 
                      bgcolor: '#25D366', 
                      '&:hover': { bgcolor: '#1DA951' },
                      px: 2,
                      borderRadius: '50px'
                    }}
                    onClick={() => openWhatsApp('Olá! Gostaria de agendar uma troca de óleo.')}
                  >
                    Agendar Troca de Óleo
                  </Button>
                </Tooltip>
                
                <Tooltip title="Clique para tirar dúvidas via WhatsApp">
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    sx={{ 
                      bgcolor: '#25D366', 
                      '&:hover': { bgcolor: '#1DA951' },
                      px: 2,
                      borderRadius: '50px'
                    }}
                    onClick={() => openWhatsApp('Olá! Tenho dúvidas sobre o alinhamento e balanceamento.')}
                  >
                    Dúvidas Alinhamento
                  </Button>
                </Tooltip>
                
                <Tooltip title="Clique para solicitar promoções pelo WhatsApp">
                  <Button
                    variant="contained"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    sx={{ 
                      bgcolor: '#25D366', 
                      '&:hover': { bgcolor: '#1DA951' },
                      px: 2,
                      borderRadius: '50px'
                    }}
                    onClick={() => openWhatsApp('Olá! Gostaria de saber as promoções da semana.')}
                  >
                    Promoções da Semana
                  </Button>
                </Tooltip>
              </Box>
            </Box>

            {/* Oferta Relâmpago Desktop */}
            <Box 
              sx={{ 
                display: { xs: 'none', md: 'block' },
                position: 'relative',
                zIndex: 5,
                mb: 4,
                p: 2,
                border: `2px dashed ${theme.palette.primary.main}`,
                borderRadius: 2,
                background: alpha(theme.palette.background.paper, 0.7),
                backdropFilter: 'blur(8px)',
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Box display="flex" alignItems="center" gap={2}>
                    <TimerIcon color="primary" sx={{ fontSize: 40 }} />
                    <Box>
                      <Typography variant="subtitle1" color="primary.main" fontWeight="bold">
                        OFERTA RELÂMPAGO - VÁLIDA POR 24H
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        Revisão completa 30 itens + troca de óleo com 40% OFF
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        <Box component="span" sx={{ textDecoration: 'line-through', opacity: 0.7 }}>De R$ 599,90</Box>{' '}
                        por apenas <Box component="span" fontWeight="bold" color="primary.main">R$ 359,90</Box>
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} sx={{ textAlign: 'right' }}>
                  <AnimatedButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => navigate('/promocoes/revisao-completa')}
                  >
                    GARANTIR DESCONTO
                  </AnimatedButton>
                </Grid>
              </Grid>
            </Box>

            {/* Carrossel de Serviços */}
            <Box sx={{ mb: 6, position: 'relative', mt: 5 }}>
              <Typography variant="h4" component="h2" align="center" gutterBottom fontWeight="bold">
                Nossos <GradientText variant="h4" component="span">Serviços</GradientText> em Destaque
              </Typography>
              <Typography variant="body1" align="center" color="text.secondary" sx={{ mb: 4, maxWidth: "800px", mx: "auto" }}>
                Agende diretamente pelo WhatsApp e garanta descontos exclusivos para leitores do blog
              </Typography>
              
              <Box sx={{ maxWidth: '100%', flexGrow: 1, position: 'relative' }}>
                <Box 
                  sx={{
                    height: { xs: 500, md: 400 },
                    borderRadius: 4,
                    overflow: 'hidden',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <Box 
                        sx={{ 
                          height: '100%', 
                          display: 'flex', 
                          flexDirection: 'column', 
                          justifyContent: 'center',
                          p: { xs: 3, md: 6 },
                        }}
                      >
                        <Box 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 2, 
                            mb: 3,
                            color: 'primary.main'
                          }}
                        >
                          {featuredServices[activeStep].icon}
                          <Typography variant="h6" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                            SERVIÇO EM DESTAQUE
                          </Typography>
                        </Box>
                        
                        <Typography variant="h3" component="h3" fontWeight="bold" gutterBottom sx={{
                          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.25rem' }
                        }}>
                          {featuredServices[activeStep].title}
                        </Typography>
                        
                        <Typography variant="body1" paragraph color="text.secondary" sx={{ 
                          overflow: 'visible',
                          minHeight: { xs: 'auto', md: '4.5rem' },
                          mb: 3
                        }}>
                          {featuredServices[activeStep].description}
                        </Typography>
                        
                      <Box>
                        <Box 
                          display="flex" 
                          alignItems="center" 
                          flexWrap="wrap"
                          gap={2} 
                          mb={3}
                        >
                          <Typography variant="body1" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            {featuredServices[activeStep].price}
                          </Typography>
                          <Typography variant="h4" color="primary.main" fontWeight="bold">
                            {featuredServices[activeStep].discountPrice}
                          </Typography>
                          <Chip 
                            label={featuredServices[activeStep].discount} 
                            color="primary"
                            sx={{ fontWeight: 'bold' }}
                          />
                        </Box>
                      </Box>
                        
                        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => navigate('/agendamento')}
                            startIcon={<CalendarIcon />}
                            fullWidth={isMobile}
                            sx={{ mb: { xs: 1, sm: 0 } }}
                          >
                            Agendar Online
                          </Button>
                          
                          <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<WhatsAppIcon />}
                            fullWidth={isMobile}
                            sx={{ 
                              borderColor: '#25D366', 
                              color: '#25D366',
                              '&:hover': {
                                borderColor: '#1DA951',
                                bgcolor: alpha('#25D366', 0.1)
                              }
                            }}
                            onClick={() => openWhatsApp(featuredServices[activeStep].whatsappMessage)}
                          >
                            Agendar pelo WhatsApp
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          height: { xs: 250, sm: 300, md: 450 },
                          bgcolor: 'background.paper',
                          borderRadius: 4,
                          overflow: 'hidden',
                          boxShadow: '0 8px 40px rgba(0,0,0,0.12)',
                          position: 'relative',
                          mt: { xs: 2, md: 0 }
                        }}
                      >
                        <Box
                            component="img"
                            src={featuredServices[activeStep].image}
                            alt={featuredServices[activeStep].title}
                            sx={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                            bgcolor: 'primary.main',
                            color: 'white',
                            borderRadius: 2,
                            py: 0.5,
                            px: 1.5,
                            fontWeight: 'bold',
                          }}
                        >
                          {featuredServices[activeStep].discount}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                
                <MobileStepper
                  steps={featuredServices.length}
                  position="static"
                  activeStep={activeStep}
                  sx={{ 
                    bgcolor: 'transparent', 
                    mt: { xs: 0, md: 2 },
                    position: { xs: 'relative', md: 'static' },
                    bottom: { xs: '0', md: 'auto' },
                    width: '100%',
                    '.MuiMobileStepper-dot': {
                      bgcolor: alpha(theme.palette.primary.main, 0.3),
                      margin: '0 8px',
                    },
                    '.MuiMobileStepper-dotActive': {
                      bgcolor: theme.palette.primary.main,
                    }
                  }}
                  nextButton={
                    <IconButton 
                      onClick={handleNextServiceStep} 
                      color="primary" 
                      sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1), width: 40, height: 40 }}
                    >
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                  backButton={
                    <IconButton 
                      onClick={handleBackServiceStep} 
                      color="primary" 
                      sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1), width: 40, height: 40 }}
                    >
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  }
                />
              </Box>
            </Box>
            
            {/* Featured Post */}
            <FeaturedCard sx={{ mb: 6 }}>
              <CardActionArea onClick={() => navigate(`/blog/${featuredPost.slug}`)}>
                <CardMedia
                  component="img"
                  image={featuredPost.image}
                  alt={featuredPost.title}
                  height={isMobile ? 350 : 500}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent sx={{ position: 'absolute', bottom: 0, width: '100%', zIndex: 2, p: { xs: 3, md: 4 } }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box display="flex" gap={1}>
                      <CategoryChip label={featuredPost.category} size="small" />
                      {featuredPost.isHot && (
                        <Chip 
                          icon={<StarIcon fontSize="small" />} 
                          label="POPULAR" 
                          size="small"
                          sx={{ 
                            background: alpha('#fff', 0.15), 
                            backdropFilter: 'blur(5px)',
                            color: '#fff',
                            fontWeight: 'bold'
                          }} 
                        />
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <TimeIcon sx={{ fontSize: 16, color: 'primary.main' }} />
                      <Typography variant="caption" color="white">
                        {featuredPost.readTime}
                      </Typography>
                      <VisibilityIcon sx={{ fontSize: 16, color: 'primary.main', ml: 1 }} />
                      <Typography variant="caption" color="white">
                        {featuredPost.views}+
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="h3" component="h2" color="white" gutterBottom fontWeight="bold">
                    {featuredPost.title}
                  </Typography>
                  <Typography variant="body1" color="white" paragraph>
                    {featuredPost.excerpt}
                  </Typography>
                  
                  {/* Economize e Desconto */}
                  <Box display="flex" flexWrap="wrap" gap={2} mb={3}>
                    {featuredPost.savingPoints && (
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1, 
                          backgroundColor: alpha(theme.palette.success.main, 0.2),
                          backdropFilter: 'blur(4px)',
                          borderRadius: 2,
                          p: 1,
                          px: 2
                        }}
                      >
                        <CheckIcon sx={{ color: theme.palette.success.main }} />
                        <Typography variant="body2" fontWeight="medium" color="white">
                          {featuredPost.savingPoints}
                        </Typography>
                      </Box>
                    )}
                    
                    {featuredPost.discount && (
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center', 
                          gap: 1, 
                          backgroundColor: alpha(theme.palette.primary.main, 0.3),
                          backdropFilter: 'blur(4px)',
                          borderRadius: 2,
                          p: 1,
                          px: 2
                        }}
                      >
                        <DiscountIcon sx={{ color: theme.palette.primary.main }} />
                        <Typography variant="body2" fontWeight="medium" color="white">
                          {featuredPost.discount}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap={2}>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Avatar src={featuredPost.authorImage} alt={featuredPost.author} sx={{ width: 40, height: 40 }} />
                      <Box>
                        <Typography variant="body2" color="white" fontWeight="medium">
                          {featuredPost.author}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {featuredPost.date}
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIcon />}
                      size="large"
                    >
                      LER ARTIGO COMPLETO
                    </Button>
                  </Box>
                </CardContent>
              </CardActionArea>
            </FeaturedCard>
          </Container>
        </Box>

                  {/* Categories Section */}
        <Container maxWidth="lg">
          <Box sx={{ py: 4, overflowX: 'auto', mb: 4 }}>
            <Box display="flex" gap={1} sx={{ pb: 1, minWidth: 'max-content' }}>
              {categories.map((category) => (
                <CategoryChip
                  key={category}
                  label={category}
                  className={activeCategory !== category ? 'outline' : ''}
                  onClick={() => setActiveCategory(category)}
                  clickable
                />
              ))}
            </Box>
          </Box>
          
          {/* Carrossel de Produtos */}
          <Box sx={{ mb: 6 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
              <Typography variant="h4" component="h2">
                Produtos <GradientText variant="h4" component="span">Recomendados</GradientText>
              </Typography>
              <Button 
                variant="outlined" 
                color="primary"
                endIcon={<ArrowForwardIcon />}
                onClick={() => navigate('/loja')}
              >
                Ver Loja Completa
              </Button>
            </Box>
            
            <Box sx={{ position: 'relative' }}>
              <Box 
                sx={{ 
                  display: 'flex',
                  flexWrap: { xs: 'wrap', md: 'nowrap' },
                  gap: { xs: 2, md: 0 },
                  transition: { md: 'transform 0.4s ease' },
                  transform: { md: `translateX(-${productStep * 100}%)` },
                  mx: -1,
                }}
              >
                {featuredProducts.map((product) => (
                  <Box 
                    key={product.id} 
                    sx={{ 
                      width: { xs: '100%', md: isTablet ? '50%' : '33.333%' },
                      flexShrink: 0,
                      px: 1,
                      mb: { xs: 2, md: 0 },
                    }}
                  >
                    <Card sx={{ height: 530, position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column' }}>
                      {product.badge && (
                        <Box 
                          sx={{ 
                            position: 'absolute', 
                            top: -12, 
                            right: 12, 
                            zIndex: 2, 
                            background: theme.palette.primary.main,
                            color: '#fff',
                            fontWeight: 'bold',
                            fontSize: '0.75rem',
                            px: 1.5,
                            py: 0.5,
                            borderRadius: 4,
                            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                          }}
                        >
                          {product.badge}
                        </Box>
                      )}
                      
                      <CardMedia
                        component="img"
                        height="180"
                        image={product.image}
                        alt={product.name}
                        sx={{ objectFit: 'cover' }}
                      />
                      
                      <CardContent sx={{ pb: 1, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6" component="h3" gutterBottom fontWeight="bold" sx={{ 
                          height: '2.8rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical'
                        }}>
                          {product.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom sx={{ 
                          height: '3em', 
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical'
                        }}>
                          {product.description}
                        </Typography>
                        
                        <Box mt={2} sx={{ minHeight: '4.5rem', flexGrow: 1 }}>
                          {product.features.map((feature, index) => (
                            <Box key={index} display="flex" alignItems="flex-start" gap={1} mb={0.5}>
                              <CheckIcon color="primary" sx={{ fontSize: 16, mt: 0.3 }} />
                              <Typography variant="body2" sx={{ 
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical'
                              }}>{feature}</Typography>
                            </Box>
                          ))}
                        </Box>
                        
                        <Box display="flex" alignItems="baseline" gap={1} mt="auto">
                          <Typography variant="caption" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            R$ {product.price.toFixed(2)}
                          </Typography>
                          <Typography variant="h6" color="primary.main" fontWeight="bold">
                            R$ {product.discountPrice.toFixed(2)}
                          </Typography>
                        </Box>
                      </CardContent>
                      
                      <Box p={2} pt={0} display="flex" gap={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate(`/loja/produto/${product.id}`)}
                          sx={{ flexGrow: 1 }}
                        >
                          Comprar
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => openWhatsApp(`Olá! Gostaria de mais informações sobre o produto: ${product.name}`)}
                          sx={{ 
                            minWidth: 'auto',
                            borderColor: '#25D366', 
                            color: '#25D366',
                            '&:hover': {
                              borderColor: '#1DA951',
                              bgcolor: alpha('#25D366', 0.1)
                            }
                          }}
                        >
                          <WhatsAppIcon />
                        </Button>
                      </Box>
                    </Card>
                  </Box>
                ))}
              </Box>
              
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: -20,
                    zIndex: 10,
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  <IconButton
                    onClick={handleBackProductStep}
                    sx={{
                      bgcolor: alpha(theme.palette.background.paper, 0.9),
                      color: theme.palette.primary.main,
                      '&:hover': { bgcolor: alpha(theme.palette.background.paper, 0.95) },
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Box>
                
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: -20,
                    zIndex: 10,
                    display: { xs: 'none', md: 'flex' },
                  }}
                >
                  <IconButton
                    onClick={handleNextProductStep}
                    sx={{
                      bgcolor: alpha(theme.palette.background.paper, 0.9),
                      color: theme.palette.primary.main,
                      '&:hover': { bgcolor: alpha(theme.palette.background.paper, 0.95) },
                      boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              
              <Box position="relative" sx={{ overflow: 'hidden' }}>
                <MobileStepper
                  steps={Math.ceil(featuredProducts.length / (isTablet ? 2 : 3))}
                  position="static"
                  activeStep={productStep}
                  sx={{ 
                    bgcolor: 'transparent', 
                    mt: 2,
                    display: { xs: 'flex' },
                    justifyContent: 'center',
                    '.MuiMobileStepper-dot': {
                      bgcolor: alpha(theme.palette.primary.main, 0.3),
                    },
                    '.MuiMobileStepper-dotActive': {
                      bgcolor: theme.palette.primary.main,
                    }
                  }}
                  nextButton={
                    <IconButton onClick={handleNextProductStep} color="primary" size="small">
                      <KeyboardArrowRightIcon />
                    </IconButton>
                  }
                  backButton={
                    <IconButton onClick={handleBackProductStep} color="primary" size="small">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  }
                />
              </Box>
            </Box>
            
            {/* WhatsApp CTA for Products */}
            <Box 
              sx={{ 
                mt: 3, 
                p: 2, 
                borderRadius: 2, 
                bgcolor: alpha('#25D366', 0.1),
                border: '1px dashed #25D366',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 2
              }}
            >
              <Box display="flex" alignItems="center" gap={2}>
                <WhatsAppIcon sx={{ color: '#25D366', fontSize: 40 }} />
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    Não encontrou o que procura?
                  </Typography>
                  <Typography variant="body2">
                    Temos mais de 5.000 produtos e peças para seu veículo!
                  </Typography>
                </Box>
              </Box>
              <Button
                variant="contained"
                size="large"
                startIcon={<WhatsAppIcon />}
                sx={{ 
                  bgcolor: '#25D366', 
                  '&:hover': { bgcolor: '#1DA951' },
                  whiteSpace: 'nowrap'
                }}
                onClick={() => openWhatsApp('Olá! Estou procurando uma peça específica para meu veículo. Podem me ajudar?')}
              >
                Consultar disponibilidade
              </Button>
            </Box>
          </Box>

          {/* Promoções em Destaque */}
          {activeCategory === 'Todos' && (
            <Box sx={{ mb: 6 }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4" component="h2">
                  Ofertas <GradientText variant="h4" component="span">Imperdíveis</GradientText>
                </Typography>
                <Button 
                  variant="outlined" 
                  color="primary"
                  endIcon={<ArrowForwardIcon />}
                  onClick={() => navigate('/promocoes')}
                >
                  Ver todas
                </Button>
              </Box>
              
              <Grid container spacing={3}>
                {specialOffers.map((offer) => (
                  <Grid item xs={12} md={4} key={offer.id}>
                    <Card sx={{ height: '100%', position: 'relative', overflow: 'visible' }}>
                      <Box 
                        sx={{ 
                          position: 'absolute', 
                          top: -15, 
                          right: 20, 
                          zIndex: 2, 
                          background: theme.palette.primary.main,
                          color: '#fff',
                          fontWeight: 'bold',
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                        }}
                      >
                        {offer.discount}
                      </Box>
                      
                      <CardMedia
                        component="img"
                        height="160"
                        image={offer.image}
                        alt={offer.title}
                      />
                      
                      <CardContent sx={{ pb: 1 }}>
                        <Typography variant="h5" component="h3" gutterBottom>
                          {offer.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" gutterBottom>
                          {offer.description}
                        </Typography>
                        
                        <Box display="flex" alignItems="baseline" gap={1} mt={2}>
                          <Typography variant="caption" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            {offer.originalPrice}
                          </Typography>
                          <Typography variant="h6" color="primary.main" fontWeight="bold">
                            {offer.price}
                          </Typography>
                        </Box>
                        
                        <Typography variant="caption" display="block" mt={1}>
                          Válido até {offer.validUntil}
                        </Typography>
                      </CardContent>
                      
                      <Box p={2} pt={0}>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => navigate(`/promocoes/oferta-${offer.id}`)}
                        >
                          APROVEITAR OFERTA
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}

          {/* Blog Posts Grid */}
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" py={8}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4" component="h2">
                  Artigos <GradientText variant="h4" component="span">Recentes</GradientText>
                </Typography>
              </Box>
              
              <Grid container spacing={3}>
                {filteredPosts.map((post) => (
                  <Grid item xs={12} sm={6} md={4} key={post.id}>
                    <ContentCard>
                      <CardActionArea onClick={() => navigate(`/blog/${post.slug}`)}>
                        <Box sx={{ position: 'relative' }}>
                          <CardMedia
                            component="img"
                            height="200"
                            image={post.image}
                            alt={post.title}
                          />
                          {post.isHot && (
                            <Box 
                              sx={{ 
                                position: 'absolute', 
                                top: 12, 
                                right: 12, 
                                background: alpha(theme.palette.error.main, 0.9),
                                backdropFilter: 'blur(4px)',
                                color: '#fff',
                                fontWeight: 'bold',
                                fontSize: '0.7rem',
                                p: 0.5,
                                px: 1,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                              }}
                            >
                              <StarIcon fontSize="small" sx={{ fontSize: 14 }} />
                              POPULAR
                            </Box>
                          )}
                        </Box>
                        <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1.5}>
                            <CategoryChip label={post.category} size="small" />
                            <Typography variant="caption" color="text.secondary">
                              {post.date}
                            </Typography>
                          </Box>
                          <Typography variant="h5" component="h3" gutterBottom sx={{ 
                            minHeight: { sm: '4rem' },
                            fontWeight: 'bold',
                            lineHeight: 1.2,
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}>
                            {post.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" paragraph sx={{ 
                            overflow: 'hidden', 
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            minHeight: '4.5rem'
                          }}>
                            {post.excerpt}
                          </Typography>

                          {/* Economize e Desconto */}
                          <Box display="flex" flexDirection="column" gap={1} mt="auto" mb={2} sx={{ minHeight: '4.5rem' }}>
                            {post.savingPoints && (
                              <Box 
                                sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  gap: 1, 
                                  backgroundColor: alpha(theme.palette.success.main, 0.1),
                                  borderRadius: 1,
                                  p: 0.5,
                                  px: 1
                                }}
                              >
                                <CheckIcon sx={{ color: theme.palette.success.main, fontSize: '0.9rem' }} />
                                <Typography variant="caption" fontWeight="medium" color="text.primary" noWrap>
                                  {post.savingPoints}
                                </Typography>
                              </Box>
                            )}
                            
                            {post.discount && (
                              <Box 
                                sx={{ 
                                  display: 'flex', 
                                  alignItems: 'center', 
                                  gap: 1, 
                                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                                  borderRadius: 1,
                                  p: 0.5,
                                  px: 1
                                }}
                              >
                                <DiscountIcon sx={{ color: theme.palette.primary.main, fontSize: '0.9rem' }} />
                                <Typography variant="caption" fontWeight="medium" color="text.primary" noWrap>
                                  {post.discount}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </CardContent>
                      </CardActionArea>
                      <Box display="flex" justifyContent="space-between" alignItems="center" p={2} pt={0}>
                        <Box display="flex" alignItems="center" gap={1}>
                          <Avatar 
                            src={post.authorImage} 
                            alt={post.author}
                            sx={{ width: 28, height: 28 }}
                          />
                          <Box sx={{ minWidth: 0 }}>
                            <Typography variant="caption" noWrap>
                              {post.readTime}
                            </Typography>
                            <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <VisibilityIcon sx={{ fontSize: 12 }} /> {post.views}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <IconButton 
                            size="small" 
                            color="primary"
                            onClick={(e) => { e.stopPropagation(); handleSavePost(post.id); }}
                          >
                            {savedPosts.includes(post.id) ? <BookmarkIcon fontSize="small" /> : <BookmarkBorderIcon fontSize="small" />}
                          </IconButton>
                          <IconButton 
                            size="small" 
                            color="primary"
                            onClick={(e) => { e.stopPropagation(); handleSharePost(post.title, post.slug); }}
                          >
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    </ContentCard>
                  </Grid>
                ))}
              </Grid>

              {/* Empty State */}
              {filteredPosts.length === 0 && (
                <Box textAlign="center" py={8}>
                  <Typography variant="h5" gutterBottom>
                    Nenhum artigo encontrado nesta categoria
                  </Typography>
                  <Typography variant="body1" color="text.secondary" paragraph>
                    Tente selecionar outra categoria ou volte mais tarde para novos conteúdos.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setActiveCategory('Todos')}
                  >
                    Ver todos os artigos
                  </Button>
                </Box>
              )}

              {/* Load More Button */}
              {filteredPosts.length > 6 && (
                <Box display="flex" justifyContent="center" mt={6} mb={8}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    endIcon={<ArrowForwardIcon />}
                  >
                    Carregar mais artigos
                  </Button>
                </Box>
              )}
            </>
          )}
        </Container>

        {/* Banner Promocional */}
        <Box sx={{ py: 6, backgroundColor: alpha(theme.palette.background.paper, 0.5) }}>
          <Container maxWidth="lg">
            <Box 
              sx={{ 
                background: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0.95)} 0%, ${alpha(theme.palette.primary.main, 0.85)} 100%)`,
                borderRadius: 4,
                p: { xs: 3, md: 5 },
                position: 'relative',
                overflow: 'hidden',
                boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
              }}
            >
              <Box 
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: { xs: '100%', md: '40%' },
                  backgroundImage: 'url(https://source.unsplash.com/random/600x400/?car,mechanic,service)',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  opacity: { xs: 0.15, md: 0.85 },
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    background: { xs: 'transparent', md: 'linear-gradient(to right, rgba(223, 62, 0, 0.95) 0%, rgba(223, 62, 0, 0) 100%)' },
                  },
                }}
              />
              
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant="h3" color="white" gutterBottom fontWeight="bold">
                    Revisão de 30 itens + troca de óleo
                  </Typography>
                  <Typography variant="h5" color="white" sx={{ mb: 3 }}>
                    Com <Box component="span" sx={{ fontWeight: 900, fontSize: '140%' }}>40% OFF</Box> por tempo limitado!
                  </Typography>
                  
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      gap: 2,
                      bgcolor: alpha('#fff', 0.15),
                      backdropFilter: 'blur(8px)',
                      borderRadius: 2,
                      p: 2,
                      mb: 3,
                      maxWidth: { xs: '100%', md: '80%' }
                    }}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <CheckIcon sx={{ color: '#fff' }} />
                      <Typography variant="body1" color="white">
                        Diagnóstico computadorizado completo
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <CheckIcon sx={{ color: '#fff' }} />
                      <Typography variant="body1" color="white">
                        Troca de óleo e filtros premium
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <CheckIcon sx={{ color: '#fff' }} />
                      <Typography variant="body1" color="white">
                        Verificação de 30 itens essenciais
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={2}>
                      <CheckIcon sx={{ color: '#fff' }} />
                      <Typography variant="body1" color="white">
                        <Box component="span" fontWeight="bold">GRÁTIS:</Box> Scanner para detecção de falhas
                      </Typography>
                    </Box>
                  </Box>
                  
                  <Box display="flex" alignItems="center" gap={3} mb={2}>
                    <Box>
                      <Typography variant="body2" color="white" sx={{ opacity: 0.7, textDecoration: 'line-through' }}>
                        De R$ 599,90
                      </Typography>
                      <Typography variant="h4" color="white" fontWeight="bold">
                        Por R$ 359,90
                      </Typography>
                    </Box>
                    
                    <AnimatedButton
                      variant="contained"
                      color="secondary"
                      size="large"
                      sx={{ 
                        bgcolor: '#fff', 
                        color: 'primary.main',
                        fontWeight: 'bold',
                        '&:hover': {
                          bgcolor: alpha('#fff', 0.9),
                        }
                      }}
                      onClick={() => navigate('/promocao/revisao-oleo')}
                    >
                      AGENDAR AGORA
                    </AnimatedButton>
                  </Box>
                  
                  <Typography variant="caption" color="white" sx={{ opacity: 0.8 }}>
                    * Promoção válida até 10/04/2025 ou enquanto durarem os estoques
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>

        {/* Related Content Section */}
        {relatedPosts.length > 0 && (
          <Box sx={{ py: 6, backgroundColor: alpha(theme.palette.background.paper, 0.5) }}>
            <Container maxWidth="lg">
              <Typography variant="h3" gutterBottom sx={{ mb: 4 }}>
                Conteúdo <GradientText variant="h3" component="span">Relacionado</GradientText>
              </Typography>
              
              <Grid container spacing={3}>
                {relatedPosts.map((post) => (
                  <Grid item xs={12} sm={6} md={4} key={post.id}>
                    <Box 
                      display="flex" 
                      alignItems="flex-start" 
                      gap={2} 
                      component={Card} 
                      sx={{ p: 2, height: '100%' }}
                    >
                      <CardMedia
                        component="img"
                        sx={{ width: 100, height: 100, borderRadius: 2, objectFit: 'cover' }}
                        image={post.image}
                        alt={post.title}
                      />
                      <Box flexGrow={1}>
                        <Typography variant="caption" color="primary" fontWeight="bold">
                          {post.category}
                        </Typography>
                        <Typography 
                          variant="h6" 
                          component={CardActionArea}
                          onClick={() => navigate(`/blog/${post.slug}`)}
                          sx={{ p: 0 }}
                        >
                          {post.title}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1} mt={1}>
                          <TimeIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                          <Typography variant="caption" color="text.secondary">
                            {post.readTime}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              
              {/* Expert WhatsApp Consultation */}
              <Box
                sx={{
                  mt: 6,
                  p: 3,
                  borderRadius: 4,
                  bgcolor: alpha(theme.palette.background.paper, 0.7),
                  backdropFilter: 'blur(10px)',
                  boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
                  border: `1px solid ${alpha('#fff', 0.1)}`,
                }}
              >
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12} md={2} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                    <Avatar
                      src="https://source.unsplash.com/random/200x200/?mechanic,expert"
                      alt="Especialista Torki"
                      sx={{
                        width: 100,
                        height: 100,
                        border: `3px solid ${theme.palette.primary.main}`,
                        mx: { xs: 'auto', md: 0 }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <Typography variant="h5" fontWeight="bold" gutterBottom>
                      Tire suas dúvidas com um especialista Torki
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      Nossos técnicos certificados estão disponíveis pelo WhatsApp para responder suas dúvidas sobre manutenção,
                      peças ou qualquer outro assunto relacionado ao seu veículo.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<WhatsAppIcon />}
                      fullWidth
                      onClick={() => openWhatsApp('Olá! Gostaria de conversar com um especialista sobre meu veículo.')}
                      sx={{ 
                        bgcolor: '#25D366', 
                        '&:hover': { bgcolor: '#1DA951' },
                        py: 1.5
                      }}
                    >
                      Falar com Especialista
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        )}

        {/* Newsletter/CTA Section */}
        <Container maxWidth="lg" sx={{ py: 8 }}>
          <CTASection>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={7}>
                <Typography variant="h3" gutterBottom fontWeight="bold">
                  Receba ofertas <GradientText variant="h3" component="span">exclusivas</GradientText>
                </Typography>
                <Typography variant="h5" paragraph color="text.secondary">
                  Cadastre-se para economizar até 40% em serviços e receber alertas de manutenção personalizados
                </Typography>
                <Box 
                  component="ul" 
                  sx={{ 
                    pl: 2, 
                    '& li': { 
                      mb: 1.5,
                      color: 'text.secondary',
                      fontWeight: 'medium'
                    } 
                  }}
                >
                  <li>Alertas de manutenção preventiva personalizados para seu veículo</li>
                  <li>Promoções exclusivas só para assinantes da nossa newsletter</li>
                  <li>Dicas de especialistas para economizar em combustível e peças</li>
                  <li>Desconto de 15% no seu primeiro agendamento após o cadastro</li>
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Paper 
                  elevation={0} 
                  sx={{ 
                    p: 3, 
                    borderRadius: 3, 
                    background: alpha(theme.palette.background.paper, 0.7),
                    backdropFilter: 'blur(10px)', 
                    border: `1px solid ${alpha('#FFFFFF', 0.08)}`,
                    boxShadow: '0 15px 35px rgba(0,0,0,0.2)'
                  }}
                >
                  <Typography variant="h5" gutterBottom textAlign="center" fontWeight="bold">
                    Cadastre-se gratuitamente
                  </Typography>
                  <Box component="form" sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Nome completo"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="E-mail"
                          type="email"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Modelo do veículo (opcional)"
                          type="text"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox defaultChecked color="primary" />}
                          label={<Typography variant="body2">Aceito receber ofertas exclusivas e descontos da Torki</Typography>}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AnimatedButton
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          GARANTIR MEUS DESCONTOS
                        </AnimatedButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </CTASection>
        </Container>

        {/* Social Media Section */}
        <Box bgcolor="background.paper" py={6}>
          <Container maxWidth="lg">
            <Box textAlign="center" mb={4}>
              <Typography variant="h3" gutterBottom fontWeight="bold">
                Siga nas <GradientText variant="h3" component="span">Redes Sociais</GradientText>
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Acompanhe nossas redes para mais dicas, novidades e promoções exclusivas
              </Typography>
            </Box>
            
            <Grid container spacing={4}>
              <Grid item xs={12} md={8}>
                <Box display="flex" justifyContent="center" gap={3} flexWrap="wrap">
                  <SocialButton aria-label="Instagram" size="large">
                    <InstagramIcon fontSize="large" />
                  </SocialButton>
                  <SocialButton aria-label="Facebook" size="large">
                    <FacebookIcon fontSize="large" />
                  </SocialButton>
                  <SocialButton aria-label="YouTube" size="large">
                    <YouTubeIcon fontSize="large" />
                  </SocialButton>
                  <SocialButton aria-label="WhatsApp" size="large" onClick={() => openWhatsApp('Olá! Vim das redes sociais da Torki.')}>
                    <WhatsAppIcon fontSize="large" />
                  </SocialButton>
                </Box>
                
                <Box
                  sx={{
                    mt: 4,
                    p: 3,
                    borderRadius: 3,
                    bgcolor: alpha(theme.palette.background.default, 0.6),
                    backdropFilter: 'blur(8px)',
                  }}
                >
                  <Typography variant="h5" gutterBottom fontWeight="bold">
                    #TorkiAutoCenter
                  </Typography>
                  <Typography variant="body1" paragraph>
                    Compartilhe suas fotos usando nossa hashtag e apareça em nossas redes sociais.
                    Mostre seu carro após nossos serviços e inspire outros motoristas!
                  </Typography>
                  <Box display="flex" flexWrap="wrap" gap={1}>
                    <Chip label="#TorkiAutoCenter" color="primary" />
                    <Chip label="#CarroNovoDenovo" />
                    <Chip label="#ManutençãoDeQualidade" />
                    <Chip label="#ServicoPremium" />
                  </Box>
                </Box>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    p: 3,
                    borderRadius: 3,
                    bgcolor: alpha(theme.palette.primary.main, 0.1),
                    border: `2px dashed ${theme.palette.primary.main}`,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h5" gutterBottom fontWeight="bold" align="center">
                    Grupo exclusivo no WhatsApp
                  </Typography>
                  <Typography variant="body1" paragraph align="center">
                    Entre para nosso grupo e receba:
                  </Typography>
                  <Box sx={{ mb: 3 }}>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <CheckIcon color="primary" />
                      <Typography variant="body2">Alertas de promoções relâmpago</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <CheckIcon color="primary" />
                      <Typography variant="body2">Cupons exclusivos de desconto</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <CheckIcon color="primary" />
                      <Typography variant="body2">Dicas semanais de manutenção</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <CheckIcon color="primary" />
                      <Typography variant="body2">Suporte técnico rápido</Typography>
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<WhatsAppIcon />}
                    size="large"
                    onClick={() => window.open('https://chat.whatsapp.com/example', '_blank')}
                    sx={{ 
                      bgcolor: '#25D366', 
                      '&:hover': { bgcolor: '#1DA951' },
                    }}
                  >
                    Entrar no Grupo VIP
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        {/* Footer */}
        <Box component="footer" bgcolor={theme.palette.background.default} py={6}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                  <CarIcon color="primary" />
                  <Typography variant="h6" fontWeight="bold">
                    TORKI AUTO CENTER
                  </Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  Soluções completas de manutenção automotiva com tecnologia de ponta, especialistas certificados e transparência total.
                </Typography>
                <Box display="flex" gap={1} mt={2}>
                  <IconButton size="small" color="primary">
                    <InstagramIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" color="primary">
                    <FacebookIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" color="primary">
                    <YouTubeIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Serviços
                </Typography>
                <List dense disablePadding>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Revisão Completa</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Troca de Óleo</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Suspensão</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Diagnóstico</Button>
                  </ListItem>
                </List>
              </Grid>
              
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Informações
                </Typography>
                <List dense disablePadding>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Sobre Nós</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Contato</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Perguntas</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Termos</Button>
                  </ListItem>
                </List>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Contato
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Av. das Nações Unidas, 14401 - Chácara Santo Antônio<br />
                  São Paulo - SP, 04794-000
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  contato@torkiauto.com.br<br />
                  (11) 3500-9000
                </Typography>
                <AnimatedButton 
                  variant="contained" 
                  color="primary" 
                  startIcon={<WhatsAppIcon />}
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={() => window.open('https://wa.me/5511987654321', '_blank')}
                >
                  AGENDAR PELO WHATSAPP
                </AnimatedButton>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 4 }} />
            
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              <Typography color="text.secondary" variant="body2">
                © {new Date().getFullYear()} Torki Auto Center. Todos os direitos reservados.
              </Typography>
              
              <Box display="flex" gap={2}>
                <Button color="inherit" size="small">
                  Privacidade
                </Button>
                <Button color="inherit" size="small">
                  Cookies
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

      {/* Popup Promocional */}
      <Drawer
        anchor="bottom"
        open={showPromoPopup}
        onClose={handleClosePromoPopup}
        PaperProps={{
          sx: {
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            background: theme.palette.background.paper,
            maxHeight: '80vh',
          }
        }}
      >
        <Box p={3} textAlign="center" position="relative">
          <IconButton 
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleClosePromoPopup}
          >
            <CloseIcon />
          </IconButton>
          
          <Typography variant="h5" color="primary" fontWeight="bold" gutterBottom>
            OFERTA EXCLUSIVA PARA VOCÊ!
          </Typography>
          
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            1ª Revisão com 40% OFF
          </Typography>
          
          <Typography variant="body1" paragraph>
            Seja bem-vindo ao blog da Torki! Para celebrar sua visita, preparamos um desconto especial na sua primeira revisão.
          </Typography>
          
          <Typography variant="h4" color="primary" fontWeight="bold" my={2}>
            CÓDIGO: BLOG40
          </Typography>
          
          <Box 
            p={2} 
            mb={3} 
            border={`2px dashed ${theme.palette.primary.main}`}
            borderRadius={2}
          >
            <Typography variant="body1" fontWeight="medium">
              Válido para agendamentos até 15/04/2025.<br />
              Apresente este código no momento do agendamento.
            </Typography>
          </Box>
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={handleClosePromoPopup}
              >
                Lembrar depois
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <AnimatedButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate('/agendamento?promo=BLOG40');
                  handleClosePromoPopup();
                }}
              >
                APROVEITAR AGORA
              </AnimatedButton>
            </Grid>
          </Grid>
        </Box>
      </Drawer>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default AutoCenterBlog;
