import React, { useState, useEffect } from 'react';
import {
  ThemeProvider,
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Paper,
  CircularProgress,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Card,
  CardContent,
  Divider,
  Chip,
  Grid,
  IconButton,
  Avatar,
  InputAdornment,
  Snackbar,
  Badge,
  LinearProgress,
  useMediaQuery
} from '@mui/material';
import { createTheme, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LockIcon from '@mui/icons-material/Lock';
import SecurityIcon from '@mui/icons-material/Security';
import SearchIcon from '@mui/icons-material/Search';
import SpeedIcon from '@mui/icons-material/Speed';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VerifiedIcon from '@mui/icons-material/Verified';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DescriptionIcon from '@mui/icons-material/Description';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import GppGoodIcon from '@mui/icons-material/GppGood';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StarIcon from '@mui/icons-material/Star';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SendIcon from '@mui/icons-material/Send';
import TimerIcon from '@mui/icons-material/Timer';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

// API Config
const API_CONFIG = {
  ANYCAR_URL: 'https://api-v2.anycar.com.br/integracao/consultar',
  API_KEY: 'vTfSS8jSiCKu414mC8pjWFIqkFI6Fzyu',
  VEHICLE_API: 'https://sea-lion-app-fhfoo.ondigitalocean.app/api/vehicles',
  CONFIGURACAO_URL: 'https://sea-lion-app-fhfoo.ondigitalocean.app',
  DEBITOS_URL: 'https://debitosveiculares.celcoin.com.br/webview/torki'
};

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#FF6B00', light: '#FF8A33', dark: '#E65C00' },
    secondary: { main: '#00D0FF', light: '#33DBFF', dark: '#00B8E0' },
    background: { 
      default: '#0D1117', 
      paper: '#161B22',
      gradient: 'linear-gradient(135deg, #161B22 0%, #1A2233 100%)'
    },
    success: { main: '#10B981', light: '#34D399' },
    warning: { main: '#F59E0B', light: '#FBBF24' },
    info: { main: '#3B82F6', light: '#60A5FA' },
    error: { main: '#EF4444', light: '#F87171' },
    text: {
      primary: '#F3F4F6',
      secondary: '#9CA3AF'
    },
    divider: 'rgba(255,255,255,0.1)'
  },
  typography: {
    fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 700,
      letterSpacing: '-0.01em'
    },
    h6: {
      fontWeight: 600,
      letterSpacing: '-0.01em'
    },
    subtitle1: {
      fontWeight: 600
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.9rem'
    },
    button: {
      fontWeight: 600,
      textTransform: 'none',
      letterSpacing: '0.01em'
    }
  },
  shape: {
    borderRadius: 12
  },
  components: {
    MuiButton: { 
      styleOverrides: { 
        root: { 
          borderRadius: 10, 
          textTransform: 'none', 
          fontWeight: 600,
          padding: '10px 20px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
          transition: 'all 0.2s ease',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: '0 6px 16px rgba(0,0,0,0.2)'
          }
        },
        containedPrimary: {
          background: 'linear-gradient(135deg, #FF7D1A 0%, #FF6000 100%)',
          '&:hover': {
            background: 'linear-gradient(135deg, #FF8F40 0%, #FF7D1A 100%)'
          }
        },
        containedSecondary: {
          background: 'linear-gradient(135deg, #00D8FF 0%, #00C0E4 100%)',
          '&:hover': {
            background: 'linear-gradient(135deg, #33E0FF 0%, #00D8FF 100%)'
          }
        },
        outlinedPrimary: {
          borderWidth: '2px',
          '&:hover': {
            borderWidth: '2px'
          }
        }
      } 
    },
    MuiCard: { 
      styleOverrides: { 
        root: { 
          borderRadius: 16,
          backdropFilter: 'blur(10px)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
          overflow: 'hidden',
          background: 'linear-gradient(135deg, rgba(26, 32, 44, 0.95) 0%, rgba(17, 21, 28, 0.95) 100%)',
          border: '1px solid rgba(255, 255, 255, 0.08)'
        } 
      } 
    },
    MuiPaper: {
      styleOverrides: { 
        root: { 
          borderRadius: 16,
          backgroundImage: 'linear-gradient(135deg, #1A2233 0%, #161B22 100%)',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.25)'
        } 
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 10,
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(5px)',
            transition: 'all 0.2s',
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.08)'
            },
            '&.Mui-focused': {
              background: 'rgba(255, 255, 255, 0.1)'
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: { 
        root: { 
          fontWeight: 600,
          backdropFilter: 'blur(5px)',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
        },
        colorPrimary: {
          background: 'linear-gradient(135deg, rgba(255, 107, 0, 0.8) 0%, rgba(255, 123, 26, 0.8) 100%)'
        },
        colorSecondary: {
          background: 'linear-gradient(135deg, rgba(0, 208, 255, 0.8) 0%, rgba(0, 180, 230, 0.8) 100%)'
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          background: 'rgba(255, 255, 255, 0.03)',
          backdropFilter: 'blur(10px)',
          borderRadius: 16,
          padding: '16px 8px',
          boxShadow: '0 4px 24px rgba(0, 0, 0, 0.2)',
          border: '1px solid rgba(255, 255, 255, 0.05)'
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          backdropFilter: 'blur(5px)'
        },
        standardSuccess: {
          background: 'rgba(16, 185, 129, 0.15)',
          border: '1px solid rgba(16, 185, 129, 0.3)'
        },
        standardError: {
          background: 'rgba(239, 68, 68, 0.15)',
          border: '1px solid rgba(239, 68, 68, 0.3)'
        },
        standardInfo: {
          background: 'rgba(59, 130, 246, 0.15)',
          border: '1px solid rgba(59, 130, 246, 0.3)'
        },
        standardWarning: {
          background: 'rgba(245, 158, 11, 0.15)',
          border: '1px solid rgba(245, 158, 11, 0.3)'
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontWeight: 500
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: 'rgba(255, 255, 255, 0.08)'
        }
      }
    }
  }
});

// Mask plate (e.g., "ABC-1234" → "ABC-****")
const maskPlate = (plate) => {
  if (!plate) return '';
  const parts = plate.split('-');
  if (parts.length === 2) {
    return `${parts[0]}-****`;
  }
  return plate.substring(0, 3) + '-****';
};

// Generate a random 12-character password
const generateRandomPassword = (length = 12) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

// Format currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

// Step Badge component
const StepBadge = ({ number, active, completed }) => {
  let bgColor = 'rgba(255, 255, 255, 0.1)';
  let textColor = 'text.secondary';
  let borderColor = 'transparent';
  
  if (active) {
    bgColor = 'rgba(255, 107, 0, 0.2)';
    textColor = 'primary.main';
    borderColor = 'primary.main';
  } else if (completed) {
    bgColor = 'rgba(16, 185, 129, 0.2)';
    textColor = 'success.main';
    borderColor = 'success.main';
  }
  
  return (
    <Avatar
      sx={{
        width: 36,
        height: 36,
        fontSize: '1rem',
        fontWeight: 'bold',
        bgcolor: bgColor,
        color: textColor,
        border: '2px solid',
        borderColor: borderColor
      }}
    >
      {completed ? <CheckCircleOutlineIcon fontSize="small" /> : number}
    </Avatar>
  );
};

// Benefit Card component
const BenefitCard = ({ icon, title, description, color = 'primary' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        p: 2,
        borderRadius: 2,
        mb: 2,
        background: `linear-gradient(135deg, ${alpha(theme.palette[color].main, 0.1)} 0%, ${alpha(theme.palette[color].main, 0.05)} 100%)`,
        border: '1px solid',
        borderColor: alpha(theme.palette[color].main, 0.3),
        boxShadow: `0 4px 12px ${alpha(theme.palette[color].main, 0.1)}`,
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: `0 6px 16px ${alpha(theme.palette[color].main, 0.15)}`
        }
      }}
    >
      <Avatar
        sx={{
          bgcolor: alpha(theme.palette[color].main, 0.15),
          color: theme.palette[color].main,
          mr: 2,
          width: 40,
          height: 40
        }}
      >
        {icon}
      </Avatar>
      <Box>
        <Typography variant="subtitle1" color={`${color}.main`} gutterBottom fontWeight="bold">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

// Hot Deal component with countdown timer
const HotDealBanner = ({ onClose }) => {
  const [timeLeft, setTimeLeft] = useState(900); // 15 minutes in seconds
  
  useEffect(() => {
    if (timeLeft <= 0) return;
    
    const timer = setInterval(() => {
      setTimeLeft(prevTime => prevTime - 1);
    }, 1000);
    
    return () => clearInterval(timer);
  }, [timeLeft]);
  
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: { xs: 0, sm: 16 },
        left: { xs: 0, sm: 16 },
        right: { xs: 0, sm: 'auto' },
        zIndex: 1000,
        borderRadius: { xs: '16px 16px 0 0', sm: '16px' },
        overflow: 'hidden',
        maxWidth: { xs: '100%', sm: '340px' },
        boxShadow: '0 -4px 24px rgba(0, 0, 0, 0.3)',
      }}
    >
      <Card
        sx={{
          background: 'linear-gradient(135deg, #FF4D00 0%, #FF7E1A 100%)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
            borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WhatshotIcon sx={{ color: '#FFF', mr: 1 }} />
            <Typography variant="subtitle2" color="#FFF" fontWeight="bold">
              OFERTA EXCLUSIVA
            </Typography>
          </Box>
          <IconButton size="small" onClick={onClose} sx={{ color: '#FFF' }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <CardContent sx={{ p: 2 }}>
          <Typography variant="body1" color="#FFF" fontWeight="bold" sx={{ mb: 1 }}>
            Destaque Premium: <span style={{ textDecoration: 'line-through', opacity: 0.7 }}>R$ 29,90</span> GRÁTIS!
          </Typography>
          <Typography variant="body2" color="rgba(255, 255, 255, 0.8)" sx={{ mb: 2 }}>
            Seu anúncio no topo dos resultados por 7 dias. Finalize agora!
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TimerIcon sx={{ color: '#FFF', mr: 1 }} />
            <Typography variant="subtitle2" color="#FFF">
              Expira em: {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{
              bgcolor: 'rgba(255, 255, 255, 0.9)',
              color: '#FF4D00',
              fontWeight: 'bold',
              '&:hover': {
                bgcolor: '#FFF',
              },
            }}
          >
            ATIVAR DESTAQUE GRÁTIS
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

// Live activity notifications
const LiveActivityNotification = ({ message, onClose }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 16,
        left: { xs: 16, sm: 'auto' },
        right: { xs: 16, sm: 16 },
        zIndex: 1000,
        maxWidth: { xs: '100%', sm: '350px' },
        borderRadius: 2,
        overflow: 'hidden',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
        animation: 'slideIn 0.3s ease-out',
        '@keyframes slideIn': {
          '0%': {
            transform: 'translateY(-100%)',
            opacity: 0,
          },
          '100%': {
            transform: 'translateY(0)',
            opacity: 1,
          },
        },
      }}
    >
      <Card>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            bgcolor: 'rgba(16, 185, 129, 0.1)',
            borderLeft: '4px solid',
            borderColor: 'success.main',
          }}
        >
          <Avatar
            sx={{
              bgcolor: 'success.main',
              width: 40,
              height: 40,
              mr: 2,
            }}
          >
            <NotificationsActiveIcon />
          </Avatar>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle2" color="text.primary" gutterBottom>
              Atividade Recente
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {message}
            </Typography>
          </Box>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Card>
    </Box>
  );
};

// Main Component
const VehicleRegistration = () => {
  // Responsive check
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Phases: debitCheck, registration, adCreation
  const [phase, setPhase] = useState('debitCheck');
  const [formData, setFormData] = useState({ email: '', plate: '' });
  const [vehicleData, setVehicleData] = useState(null);
  const [registeredVehicle, setRegisteredVehicle] = useState(null);
  const [uploadedVehicleImage, setUploadedVehicleImage] = useState(null);
  
  const [listingPrice, setListingPrice] = useState('');
  const [listingCondition, setListingCondition] = useState('new');
  const [listingDescription, setListingDescription] = useState('');
  const [listingImages, setListingImages] = useState([]);
  
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [debitCheckCompleted, setDebitCheckCompleted] = useState(false);
  
  // FOMO elements
  const [showHotDeal, setShowHotDeal] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [activeUsers, setActiveUsers] = useState(42);
  const [viewsToday, setViewsToday] = useState(256);
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const navigate = useNavigate();

  // Get active step based on current phase
  const getActiveStep = () => {
    switch (phase) {
      case 'debitCheck': return 0;
      case 'registration': return 1;
      case 'adCreation': return 2;
      default: return 0;
    }
  };

  // Update form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  // Convert vehicle image to base64 (optional single image)
  const handleVehicleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setUploadedVehicleImage(reader.result);
      reader.readAsDataURL(file);
    }
    
    // Show notification after upload
    setTimeout(() => {
      showTemporaryNotification("Ótima escolha! Anúncios com fotos vendem 70% mais rápido.");
    }, 1000);
  };

  // Convert multiple images to base64 for the listing (max 3)
  const handleListingImageUpload = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      const filesArray = Array.from(files).slice(0, 3);
      Promise.all(filesArray.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      }))
      .then(images => {
        setListingImages(images);
        
        // Show notification after upload
        const count = images.length;
        showTemporaryNotification(`${count} ${count > 1 ? 'fotos adicionadas' : 'foto adicionada'}! Isso aumentará suas chances em 3x.`);
      })
      .catch(error => console.error("Error reading listing images", error));
    }
  };

  // Redirect to debt verification site
  const redirectToDebitCheck = () => {
    if (!formData.plate.trim()) {
      setMessage({ text: 'Digite a placa do veículo para verificar débitos!', type: 'error' });
      return;
    }
    
    // Open URL in new tab
    window.open(API_CONFIG.DEBITOS_URL, '_blank');
    
    // Mark verification as completed
    setDebitCheckCompleted(true);
    setMessage({ 
      text: 'Verificação de débitos iniciada! Após concluir, retorne para continuar.', 
      type: 'success' 
    });
    
    // Show hot deal after 5 seconds
    setTimeout(() => {
      setShowHotDeal(true);
    }, 5000);
  };

  // Proceed to registration after debt check
  const proceedToRegistration = () => {
    setPhase('registration');
    setMessage({ text: '', type: '' });
    
    // Simulate user activity notification
    setTimeout(() => {
      showTemporaryNotification("12 pessoas cadastraram seus veículos nos últimos 30 minutos!");
      
      // Update active users count periodically
      const interval = setInterval(() => {
        setActiveUsers(prev => Math.min(prev + Math.floor(Math.random() * 3), 65));
        setViewsToday(prev => prev + Math.floor(Math.random() * 5));
      }, 20000);
      
      return () => clearInterval(interval);
    }, 8000);
  };

  // Phase 1: Fetch vehicle data via Anycar API
  const fetchVehicleData = async () => {
    if (!formData.plate.trim()) {
      setMessage({ text: 'Digite a placa do veículo!', type: 'error' });
      return;
    }
    setLoading(true);
    setMessage({ text: '', type: '' });
    try {
      const response = await fetch(API_CONFIG.ANYCAR_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          apiKey: API_CONFIG.API_KEY,
          tipo: 'agregados-propria',
          placa: formData.plate
        }),
      });
      const result = await response.json();
      if (result.status) {
        setVehicleData(result.dados);
        setMessage({ text: 'Veículo encontrado! Confira e continue para garantir seu anúncio.', type: 'success' });
        
        // Show notification after successful fetch
        setTimeout(() => {
          showTemporaryNotification(`${result.dados.fabricante} ${result.dados.modelo} é um dos mais buscados! 🔥`);
        }, 2000);
      } else {
        setMessage({ text: result.msg || 'Veículo não encontrado.', type: 'error' });
      }
    } catch (error) {
      setMessage({ text: 'Erro ao buscar veículo.', type: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Phase 1: Register user and vehicle
  const handleVehicleRegistration = async () => {
    if (!formData.email.includes('@')) {
      setMessage({ text: 'Digite um email válido!', type: 'error' });
      return;
    }
    if (!vehicleData) {
      setMessage({ text: 'Busque os dados do veículo primeiro!', type: 'error' });
      return;
    }
    setLoading(true);
    try {
      // 1. Create user with random password
      const randomPassword = generateRandomPassword(12);
      const userPayload = {
        name: "Usuário Sem Nome",
        email: formData.email,
        password: randomPassword,
        telefone: "Não informado",
        tipo: 'cliente'
      };

      const userResponse = await fetch(`${API_CONFIG.CONFIGURACAO_URL}/api/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userPayload)
      });
      
      if (!userResponse.ok) {
        const errorData = await userResponse.json();
        setMessage({ text: errorData.message || 'Erro ao criar usuário.', type: 'error' });
        setLoading(false);
        return;
      }
      
      const userData = await userResponse.json();
      
      // Ajustado para pegar corretamente o ID do usuário da resposta da API
      const userId = userData?.data?.user?._id || formData.email;
      
      console.log('Usuário criado com ID:', userId);


      // 2. Register vehicle in database
      const vehiclePayload = {
        userId: userId,
        ...vehicleData,
        plate: vehicleData.placa,
        image: uploadedVehicleImage || vehicleData.image || ''
      };

      const vehicleRegResponse = await fetch(API_CONFIG.VEHICLE_API, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(vehiclePayload),
      });

      if (!vehicleRegResponse.ok) {
        const errorData = await vehicleRegResponse.json();
        setMessage({ text: errorData.message || 'Erro ao cadastrar veículo.', type: 'error' });
        setLoading(false);
        return;
      }

      // 3. Search for newly registered vehicle
      const searchResponse = await fetch(`${API_CONFIG.CONFIGURACAO_URL}/api/vehicles/search?plate=${formData.plate}`);
      const searchResult = await searchResponse.json();
      if (!Array.isArray(searchResult) || searchResult.length === 0) {
        setMessage({ text: 'Veículo não encontrado para criação de anúncio.', type: 'error' });
        setLoading(false);
        return;
      }
      const sortedVehicles = searchResult.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      const latestVehicle = sortedVehicles[0];
      setRegisteredVehicle(latestVehicle);
      setMessage({ text: 'Veículo cadastrado com sucesso! Vamos criar o anúncio antes que a oferta expire.', type: 'success' });
      
      // Show hot deal notification again
      setShowHotDeal(true);
      
      // Advance to next phase
      setPhase('adCreation');
      
      // Show notification
      showTemporaryNotification("Boa! Estamos a um passo de vender seu carro!");
    } catch (error) {
      setMessage({ text: 'Erro ao enviar os dados.', type: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Phase 2: Create listing using registered vehicle
  const handleAdCreation = async () => {
    if (!registeredVehicle) {
      setMessage({ text: 'Veículo não disponível para anúncio.', type: 'error' });
      return;
    }
    if (!listingPrice) {
      setMessage({ text: 'Informe o preço para o anúncio.', type: 'error' });
      return;
    }
    setLoading(true);
    try {
      const listingPayload = {
        vehicleId: registeredVehicle._id,
        sellerId: registeredVehicle.userId,
        price: parseFloat(listingPrice),
        condition: listingCondition,
        description: listingDescription,
        views: 0,
        isActive: true,
        imagens: listingImages,
        analytics: {
          dailyViews: {},
          contactAttempts: 0,
          shareCount: 0,
          lastViewedAt: new Date().toISOString()
        },
        marketInsights: {
          averageMarketPrice: 0,
          priceComparison: 0,
          updatedAt: new Date().toISOString()
        }
      };

      const listingResponse = await fetch(
        `${API_CONFIG.CONFIGURACAO_URL}/api/listings`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(listingPayload),
        }
      );

      if (listingResponse.ok) {
        setMessage({ text: 'Anúncio criado com sucesso! 🎉', type: 'success' });
        // Show confetti or celebration effect
        showTemporaryNotification("Parabéns! Seu anúncio está pronto para receber compradores!");
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000);
      } else {
        setMessage({ text: 'Erro ao cadastrar o anúncio.', type: 'error' });
      }
    } catch (error) {
      setMessage({ text: 'Erro ao enviar os dados.', type: 'error' });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  // Show temporary notification
  const showTemporaryNotification = (message) => {
    setNotificationMessage(message);
    setShowNotification(true);
    
    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };
  
  // Quick Action Drawer content
  const DrawerContent = () => (
    <Box sx={{ width: 280, p: 3 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>Ferramentas Rápidas</Typography>
      
      <Button 
        variant="outlined" 
        color="primary" 
        fullWidth 
        startIcon={<SearchIcon />}
        sx={{ mb: 2 }}
      >
        Avaliar Meu Veículo
      </Button>
      
      <Button 
        variant="outlined" 
        color="secondary" 
        fullWidth 
        startIcon={<PhoneIcon />}
        sx={{ mb: 2 }}
      >
        Falar com Consultor
      </Button>
      
      <Button 
        variant="outlined" 
        color="success" 
        fullWidth 
        startIcon={<AttachMoneyIcon />}
        sx={{ mb: 3 }}
      >
        Simular Financiamento
      </Button>
      
      <Divider sx={{ mb: 3 }} />
      
      <Typography variant="subtitle2" sx={{ mb: 2 }}>Atividade em Tempo Real</Typography>
      
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            <VisibilityIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
            Visualizações hoje
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {viewsToday}
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={(viewsToday / 500) * 100} 
          color="secondary"
          sx={{ height: 6, borderRadius: 3 }}
        />
      </Box>
      
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2" color="text.secondary">            
            Usuários ativos
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            {activeUsers}
          </Typography>
        </Box>
        <LinearProgress 
          variant="determinate" 
          value={(activeUsers / 100) * 100} 
          color="primary" 
          sx={{ height: 6, borderRadius: 3 }}
        />
      </Box>
    </Box>
  );

  // UseEffect for activity simulations and FOMO triggers
  useEffect(() => {
    // Simulate other users activity
    const activityInterval = setInterval(() => {
      const activities = [
        "Um Honda Civic vendido há 2 minutos em São Paulo",
        "Um Jeep Renegade acaba de ser anunciado",
        "Alguém acabou de encontrar um comprador em menos de 24h!",
        "3 pessoas interessadas no mesmo modelo que o seu",
        "5 compradores ativos buscando veículos como o seu agora",
        "Um veículo similar ao seu recebeu 8 contatos hoje"
      ];
      
      const randomActivity = activities[Math.floor(Math.random() * activities.length)];
      
      // 30% chance to show notification
      if (Math.random() < 0.3) {
        showTemporaryNotification(randomActivity);
      }
    }, 45000); // Every 45 seconds
    
    // Show hot deal after 30 seconds
    const hotDealTimeout = setTimeout(() => {
      setShowHotDeal(true);
    }, 30000);
    
    return () => {
      clearInterval(activityInterval);
      clearTimeout(hotDealTimeout);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          minHeight: '100vh', 
          background: 'radial-gradient(circle at 50% 0%, #1E293B 0%, #0F172A 60%, #0D1117 100%)',
          backgroundAttachment: 'fixed',
          py: { xs: 2, md: 6 }, 
          px: { xs: 1, md: 2 }
        }}
      >
        <Header />
        
        <Container maxWidth="lg" sx={{ mb: 6 }}>
          <Typography 
            variant="h4" 
            color="primary" 
            align="center" 
            sx={{ 
              mb: 2, 
              background: 'linear-gradient(90deg, #FF6B00 0%, #FF9D33 100%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              fontWeight: 800,
              letterSpacing: '-0.02em',
              fontSize: { xs: '1.75rem', sm: '2.125rem' }
            }}
          >
            Venda Seu Carro em 24h
          </Typography>
          
          <Typography 
            variant="subtitle1" 
            color="text.secondary" 
            align="center" 
            sx={{ mb: 3, maxWidth: '700px', mx: 'auto' }}
          >
            {isMobile ? (
              "Anuncie agora e conecte-se com mais de 5.000 compradores diários."
            ) : (
              "Cadastre e venda seu veículo com facilidade. Nossa plataforma conecta você com mais de 5.000 compradores ativos todos os dias."
            )}
          </Typography>
          
          {/* Live Stats Bar */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(0,0,0,0.2)',
              borderRadius: 3,
              p: 1.5,
              mb: 4,
              flexWrap: 'wrap',
              gap: { xs: 2, md: 4 },
              border: '1px solid rgba(255,255,255,0.05)',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: 'rgba(255, 107, 0, 0.2)',
                  color: 'primary.main',
                  mr: 1
                }}
              >
                
              </Avatar>
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Usuários Ativos
                </Typography>
                <Typography variant="subtitle2">
                  {activeUsers} agora
                </Typography>
              </Box>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: 'rgba(16, 185, 129, 0.2)',
                  color: 'success.main',
                  mr: 1
                }}
              >
                <TrendingUpIcon fontSize="small" />
              </Avatar>
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Vendas Hoje
                </Typography>
                <Typography variant="subtitle2">
                  37 concluídas
                </Typography>
              </Box>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  width: 32,
                  height: 32,
                  bgcolor: 'rgba(0, 208, 255, 0.2)',
                  color: 'secondary.main',
                  mr: 1
                }}
              >
                <AccessTimeIcon fontSize="small" />
              </Avatar>
              <Box>
                <Typography variant="caption" color="text.secondary">
                  Tempo Médio
                </Typography>
                <Typography variant="subtitle2">
                  1.3 dias p/ venda
                </Typography>
              </Box>
            </Box>
          </Box>
          
          {/* Progress Stepper */}
          <Box sx={{ mb: 4 }}>
            <Stepper 
              alternativeLabel 
              activeStep={getActiveStep()}
              sx={{ 
                '& .MuiStepConnector-line': {
                  borderColor: 'rgba(255,255,255,0.08)'
                }
              }}
            >
              <Step>
                <StepLabel StepIconComponent={(props) => (
                  <StepBadge 
                    number={1} 
                    active={props.active} 
                    completed={props.completed} 
                  />
                )}>
                  <Typography variant="subtitle2" fontWeight={getActiveStep() === 0 ? 700 : 500}>
                    Verificar Débitos
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={(props) => (
                  <StepBadge 
                    number={2} 
                    active={props.active} 
                    completed={props.completed} 
                  />
                )}>
                  <Typography variant="subtitle2" fontWeight={getActiveStep() === 1 ? 700 : 500}>
                    Cadastrar Veículo
                  </Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel StepIconComponent={(props) => (
                  <StepBadge 
                    number={3} 
                    active={props.active} 
                    completed={props.completed} 
                  />
                )}>
                  <Typography variant="subtitle2" fontWeight={getActiveStep() === 2 ? 700 : 500}>
                    Criar Anúncio
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Box>

          {/* Main Content */}
          <Paper 
            elevation={24} 
            sx={{ 
              overflow: 'hidden',
              border: '1px solid rgba(255,255,255,0.05)',
              background: theme.palette.background.gradient
            }}
          >
            {/* Phase 1: Debt Check */}
            {phase === 'debitCheck' && (
              <Box sx={{ p: { xs: 2, md: 5 } }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 4,
                    pb: 3,
                    flexDirection: { xs: 'column', sm: 'row' },
                    textAlign: { xs: 'center', sm: 'left' },
                    borderBottom: '1px solid rgba(255,255,255,0.05)'
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: 'rgba(0, 208, 255, 0.15)', 
                      color: 'secondary.main',
                      width: 48,
                      height: 48,
                      mr: { xs: 0, sm: 2 },
                      mb: { xs: 2, sm: 0 }
                    }}
                  >
                    <SecurityIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 0.5 }}>
                      Verificação de Débitos
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Verifique pendências antes de anunciar e aumente suas chances de venda rápida
                    </Typography>
                  </Box>
                </Box>

                {message.text && (
                  <Alert 
                    severity={message.type} 
                    sx={{ 
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center',
                      '& .MuiAlert-icon': {
                        mr: 2,
                        alignItems: 'center',
                        fontSize: '2rem',
                        opacity: 0.9
                      }
                    }}
                    action={
                      message.type === 'success' && debitCheckCompleted ? (
                        <Button 
                          color="inherit" 
                          size="small" 
                          onClick={proceedToRegistration}
                          endIcon={<ArrowForwardIcon />}
                          sx={{ 
                            fontWeight: 600,
                            borderRadius: 2,
                            px: 2, 
                            background: 'rgba(255,255,255,0.1)',
                            '&:hover': { background: 'rgba(255,255,255,0.2)' }
                          }}
                        >
                          Continuar
                        </Button>
                      ) : null
                    }
                    icon={message.type === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ReportProblemIcon fontSize="inherit" />}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {message.text}
                    </Typography>
                  </Alert>
                )}

                <Grid container spacing={3}>
                  {/* Info Column */}
                  <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
                    <Box sx={{ mb: 4 }}>
                      <Typography 
                        variant="h6" 
                        color="text.primary" 
                        gutterBottom 
                        sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          mb: 3
                        }}
                      >
                        <GppGoodIcon 
                          sx={{ 
                            mr: 1, 
                            color: 'secondary.main', 
                            fontSize: '1.6rem' 
                          }} 
                        />
                        Por que verificar débitos?
                      </Typography>
                      
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <BenefitCard 
                            icon={<SpeedIcon fontSize="small" />}
                            title="Venda 2x mais rápido"
                            description="Veículos sem débitos vendem até 40% mais rápido que outros com pendências."
                            color="primary"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BenefitCard 
                            icon={<VerifiedIcon fontSize="small" />}
                            title="Ganhe confiança"
                            description="Compradores pagam até 12% a mais por veículos com documentação limpa."
                            color="secondary"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BenefitCard 
                            icon={<PaymentsIcon fontSize="small" />}
                            title="Evite surpresas"
                            description="Conheça o valor exato das pendências e planeje com antecedência."
                            color="success"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <BenefitCard 
                            icon={<LocalFireDepartmentIcon fontSize="small" />}
                            title="Destaque seu anúncio"
                            description="Veículos verificados recebem selo de qualidade e mais visibilidade."
                            color="warning"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    
                    {/* Social Proof */}
                    <Card
                      sx={{
                        p: 0.5,
                        background: 'linear-gradient(135deg, rgba(0, 208, 255, 0.2) 0%, rgba(16, 185, 129, 0.2) 100%)',
                        boxShadow: '0 10px 30px rgba(0, 208, 255, 0.15)',
                        display: { xs: 'none', sm: 'block' }
                      }}
                    >
                      <CardContent sx={{ 
                        background: alpha(theme.palette.background.paper, 0.9),
                        borderRadius: 2,
                        p: 3
                      }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormatQuoteIcon sx={{ color: 'secondary.main', mr: 1, transform: 'scaleX(-1)' }} />
                            <Typography variant="h6" color="secondary.main">
                              Vendem em 24h
                            </Typography>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <StarIcon sx={{ color: 'warning.main' }} />
                            <StarIcon sx={{ color: 'warning.main' }} />
                            <StarIcon sx={{ color: 'warning.main' }} />
                            <StarIcon sx={{ color: 'warning.main' }} />
                            <StarIcon sx={{ color: 'warning.main' }} />
                          </Box>
                        </Box>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2, fontStyle: 'italic' }}>
                          "Achei uma multa que eu nem sabia que existia! Regularizei tudo antes de anunciar e vendi meu carro em menos de 24 horas por um valor acima da tabela FIPE."
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar 
                              sx={{ 
                                width: 32, 
                                height: 32, 
                                bgcolor: 'secondary.dark',
                                fontSize: '0.875rem',
                                mr: 1
                              }}
                            >
                              RS
                            </Avatar>
                            <Typography variant="subtitle2" color="text.primary">
                              Roberto S. - São Paulo/SP
                            </Typography>
                          </Box>
                          <Chip 
                            label="Verificado" 
                            size="small" 
                            color="success"
                            icon={<CheckCircleOutlineIcon fontSize="small" />}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  
                  {/* Action Column */}
                  <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
                    <Card 
                      sx={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        border: '1px solid rgba(255, 107, 0, 0.3)',
                        boxShadow: '0 10px 30px rgba(255, 107, 0, 0.15)',
                        position: 'relative',
                        overflow: 'visible'
                      }}
                    >
                      {/* Hot Tag */}
                      <Box 
                        sx={{
                          position: 'absolute',
                          top: -12,
                          right: 24,
                          bgcolor: 'error.main',
                          color: '#fff',
                          py: 0.5,
                          px: 2,
                          borderRadius: '20px',
                          boxShadow: '0 4px 12px rgba(239, 68, 68, 0.3)',
                          display: 'flex',
                          alignItems: 'center',
                          zIndex: 1
                        }}
                      >
                        <WhatshotIcon fontSize="small" sx={{ mr: 0.5 }} />
                        <Typography variant="caption" fontWeight="bold">
                          GRATUITO HOJE
                        </Typography>
                      </Box>
                      
                      <CardContent sx={{ p: 3 }}>
                        <Typography 
                          variant="h6" 
                          color="primary.main" 
                          gutterBottom 
                          sx={{ 
                            mb: 3,
                            fontWeight: 700,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          <PriorityHighIcon sx={{ mr: 1 }} />
                          Verifique agora os débitos
                        </Typography>
                        
                        <TextField
                          fullWidth
                          label="Placa do Veículo"
                          name="plate"
                          placeholder="ABC1234 ou ABC-1234"
                          value={formData.plate}
                          onChange={handleChange}
                          sx={{ mb: 3 }}
                          autoFocus
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DirectionsCarIcon sx={{ color: 'primary.main' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        
                        <Box sx={{ mb: 3 }}>
                          <Box 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center', 
                              mb: 1.5,
                              background: 'rgba(255,255,255,0.03)',
                              borderRadius: 2,
                              p: 1.5,
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <CheckCircleOutlineIcon sx={{ color: 'success.main', mr: 1.5 }} />
                            <Typography variant="body2" fontWeight={500}>
                              100% seguro e gratuito
                            </Typography>
                          </Box>
                          
                          <Box 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              background: 'rgba(255,255,255,0.03)',
                              borderRadius: 2,
                              p: 1.5,
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <LockIcon sx={{ color: 'secondary.main', mr: 1.5 }} />
                            <Typography variant="body2" fontWeight={500}>
                              Dados protegidos por criptografia
                            </Typography>
                          </Box>
                        </Box>
                        
                        {/* FOMO elements */}
                        <Alert 
                          severity="warning" 
                          icon={<AccessTimeIcon fontSize="inherit" />}
                          sx={{ mb: 3 }}
                        >
                          <Typography variant="body2" fontWeight={500}>
                            Últimas 3 vagas grátis para hoje!
                          </Typography>
                        </Alert>
                        
                        <Typography 
                          variant="body2" 
                          color="text.secondary" 
                          sx={{ 
                            mb: 1,
                            display: 'flex',
                            alignItems: 'center' 
                          }}
                        >
                          <InfoIcon sx={{ fontSize: '1rem', mr: 1, color: 'info.main' }} />
                          A consulta verifica:
                        </Typography>
                        
                        <Box 
                          sx={{ 
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 1,
                            mb: 3
                          }}
                        >
                          <Chip 
                            label="Multas" 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            sx={{ fontWeight: 500 }}
                          />
                          <Chip 
                            label="IPVA" 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            sx={{ fontWeight: 500 }}
                          />
                          <Chip 
                            label="Licenciamento" 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            sx={{ fontWeight: 500 }}
                          />
                          <Chip 
                            label="DPVAT" 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            sx={{ fontWeight: 500 }}
                          />
                          <Chip 
                            label="Restrições" 
                            size="small" 
                            variant="outlined" 
                            color="primary"
                            sx={{ fontWeight: 500 }}
                          />
                        </Box>
                      </CardContent>
                      
                      <Box 
                        sx={{ 
                          p: 3, 
                          pt: 0,
                          borderTop: '1px solid rgba(255,255,255,0.05)',
                          background: 'rgba(0,0,0,0.2)'
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          size="large"
                          disabled={loading || !formData.plate}
                          onClick={redirectToDebitCheck}
                          startIcon={<SecurityIcon />}
                          sx={{ 
                            mb: 2,
                            py: 1.5,
                            fontSize: '1rem'
                          }}
                        >
                          {loading ? <CircularProgress size={24} color="inherit" /> : 'Verificar Débitos Grátis'}
                        </Button>
                        
                        <Button
                          variant="text"
                          color="primary"
                          onClick={proceedToRegistration}
                          disabled={!debitCheckCompleted}
                          sx={{ 
                            width: '100%',
                            justifyContent: 'center',
                            color: alpha(theme.palette.primary.main, 0.8)
                          }}
                        >
                          Pular esta etapa
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            )}

            {/* Phase 2: Vehicle Registration */}
            {phase === 'registration' && (
              <Box sx={{ p: { xs: 2, md: 5 } }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 4,
                    pb: 3,
                    flexDirection: { xs: 'column', sm: 'row' },
                    textAlign: { xs: 'center', sm: 'left' },
                    borderBottom: '1px solid rgba(255,255,255,0.05)'
                  }}
                >
                  <Avatar
                    sx={{
                      bgcolor: 'rgba(255, 107, 0, 0.15)', 
                      color: 'primary.main',
                      width: 48,
                      height: 48,
                      mr: { xs: 0, sm: 2 },
                      mb: { xs: 2, sm: 0 }
                    }}
                  >
                    <DirectionsCarIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 0.5 }}>
                      Cadastro do Veículo
                    </Typography>
                                          <Typography variant="body2" color="text.secondary">
                      Estamos quase lá! Adicione seu veículo para criar o anúncio
                    </Typography>
                  </Box>
                </Box>

                {message.text && (
                  <Alert 
                    severity={message.type} 
                    sx={{ 
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center',
                      '& .MuiAlert-icon': {
                        mr: 2,
                        alignItems: 'center',
                        fontSize: '2rem'
                      }
                    }}
                    icon={message.type === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ReportProblemIcon fontSize="inherit" />}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {message.text}
                    </Typography>
                  </Alert>
                )}

                {/* Stats Bar - FOMO trigger */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: 'rgba(255, 107, 0, 0.1)',
                    borderRadius: 2,
                    p: 2,
                    mb: 4,
                    border: '1px solid rgba(255, 107, 0, 0.2)',
                    flexWrap: 'wrap',
                    gap: { xs: 1, md: 0 }
                  }}
                >
                  <Typography 
                    variant="body2" 
                    color="primary.light" 
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      fontWeight: 600,
                      width: { xs: '100%', sm: 'auto' },
                      justifyContent: { xs: 'center', sm: 'flex-start' },
                      mb: { xs: 1, sm: 0 }
                    }}
                  >
                    <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                    Dados em tempo real
                  </Typography>
                  
                  <Chip 
                    label="15 anúncios criados nos últimos 30min" 
                    size="small"
                    sx={{ 
                      bgcolor: 'rgba(255, 107, 0, 0.15)',
                      color: 'primary.light',
                      border: '1px solid rgba(255, 107, 0, 0.3)',
                      fontWeight: 500
                    }}
                  />
                  
                  <Chip 
                    icon={<AccessTimeIcon fontSize="small" />}
                    label="1.2 dias para venda" 
                    size="small"
                    sx={{ 
                      bgcolor: 'rgba(16, 185, 129, 0.15)',
                      color: 'success.light',
                      border: '1px solid rgba(16, 185, 129, 0.3)',
                      fontWeight: 500
                    }}
                  />
                </Box>

                <Card sx={{ 
                  mb: 4, 
                  border: '1px solid rgba(0, 208, 255, 0.3)',
                  boxShadow: '0 10px 30px rgba(0, 208, 255, 0.1)'
                }}>
                  <CardContent sx={{ p: 3 }}>
                    <Typography 
                      variant="subtitle1" 
                      color="secondary.main" 
                      gutterBottom
                      sx={{ 
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2
                      }}
                    >
                      <InfoIcon sx={{ mr: 1, fontSize: '1.3rem' }} />
                      Informações do Proprietário e Veículo
                    </Typography>

                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="email"
                          type="email"
                          value={formData.email}
                          onChange={handleChange}
                          error={formData.email.length > 0 && !formData.email.includes('@')}
                          helperText={formData.email.length > 0 && !formData.email.includes('@') ? 'Digite um email válido' : ''}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlineIcon sx={{ color: 'secondary.main' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Placa do Veículo"
                          name="plate"
                          value={formData.plate}
                          onChange={handleChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DirectionsCarIcon sx={{ color: 'primary.main' }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Box
                    sx={{ 
                      px: 3, 
                      pb: 3, 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: 2
                    }}
                  >
                    <Typography 
                      variant="caption" 
                      color="error.light" 
                      sx={{ 
                        fontStyle: 'italic', 
                        display: 'flex', 
                        alignItems: 'center'
                      }}
                    >
                      <TimerIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                      Oferta exclusiva expira em breve
                    </Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      onClick={fetchVehicleData}
                      startIcon={<SearchIcon />}
                      sx={{ minWidth: '180px' }}
                    >
                      {loading ? <CircularProgress size={20} color="inherit" /> : 'Buscar Dados'}
                    </Button>
                  </Box>
                </Card>

                {vehicleData && (
                  <Card 
                    sx={{ 
                      border: '1px solid rgba(255, 107, 0, 0.3)',
                      background: 'linear-gradient(135deg, rgba(255, 107, 0, 0.1) 0%, rgba(255, 107, 0, 0.05) 100%)',
                      boxShadow: '0 10px 30px rgba(255, 107, 0, 0.15)'
                    }}
                  >
                    <CardContent sx={{ p: 3 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          flexWrap: 'wrap',
                          mb: 3
                        }}
                      >
                        <Box>
                          <Typography 
                            variant="h6" 
                            color="primary" 
                            sx={{ 
                              display: 'flex', 
                              alignItems: 'center',
                              mb: 1 
                            }}
                          >
                            <CheckCircleOutlineIcon sx={{ mr: 1, color: 'success.main' }} />
                            Veículo Encontrado
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Confirmamos os dados do seu veículo. Verifique se estão corretos.
                          </Typography>
                        </Box>
                        
                        <Chip 
                          label={`${vehicleData.fabricante} ${vehicleData.modelo}`}
                          color="primary"
                          sx={{ 
                            px: 2, 
                            height: 32,
                            fontWeight: 600,
                            fontSize: '0.9rem',
                            mt: { xs: 2, sm: 0 },
                            width: { xs: '100%', sm: 'auto' }
                          }}
                        />
                      </Box>
                      
                      <Grid container spacing={3} sx={{ mb: 4 }}>
                        <Grid item xs={6} sm={6} md={3}>
                          <Box 
                            sx={{ 
                              p: 2, 
                              borderRadius: 2, 
                              bgcolor: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Modelo
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {vehicleData.modelo}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <Box 
                            sx={{ 
                              p: 2, 
                              borderRadius: 2, 
                              bgcolor: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Fabricante
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {vehicleData.fabricante}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <Box 
                            sx={{ 
                              p: 2, 
                              borderRadius: 2, 
                              bgcolor: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Ano
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {vehicleData.ano_fabricacao} / {vehicleData.ano_modelo}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={3}>
                          <Box 
                            sx={{ 
                              p: 2, 
                              borderRadius: 2, 
                              bgcolor: 'rgba(0,0,0,0.2)',
                              border: '1px solid rgba(255,255,255,0.05)'
                            }}
                          >
                            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                              Placa
                            </Typography>
                            <Typography variant="body1" fontWeight={600} color="text.primary">
                              {maskPlate(vehicleData.placa)}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      
                      <Box 
                        sx={{ 
                          mb: 4, 
                          p: 3,
                          border: '1px dashed rgba(255,255,255,0.2)',
                          borderRadius: 2,
                          background: 'rgba(0,0,0,0.1)'
                        }}
                      >
                        <Typography 
                          variant="subtitle2" 
                          color="secondary.main"
                          sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2
                          }}
                        >
                          <PhotoCameraIcon sx={{ mr: 1 }} />
                          Adicionar Foto Principal (aumenta chances em 70%)
                        </Typography>
                        
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: { xs: 'stretch', sm: 'center' },
                            gap: 2
                          }}
                        >
                          <Button 
                            variant="outlined" 
                            component="label" 
                            color="secondary"
                            disabled={loading}
                            startIcon={<AddPhotoAlternateIcon />}
                            sx={{ flexGrow: { xs: 1, sm: 0 } }}
                          >
                            Selecionar Imagem
                            <input type="file" accept="image/*" hidden onChange={handleVehicleImageUpload} />
                          </Button>
                          
                          {uploadedVehicleImage ? (
                            <Chip 
                              icon={<CheckCircleOutlineIcon />}
                              label="Imagem selecionada" 
                              color="success" 
                              variant="filled"
                              sx={{ fontWeight: 500 }}
                            />
                          ) : (
                            <Typography variant="caption" color="error.light">
                              <PriorityHighIcon sx={{ fontSize: '0.8rem', mr: 0.5, verticalAlign: 'middle' }} />
                              Anúncios sem foto recebem 70% menos contatos
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </CardContent>
                    
                    <Box 
                      sx={{ 
                        p: 3, 
                        pt: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        gap: 2
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <LockIcon sx={{ color: 'secondary.main', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Seus dados estão seguros conosco
                        </Typography>
                      </Box>
                      
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleVehicleRegistration}
                        disabled={loading}
                        endIcon={<ArrowForwardIcon />}
                        sx={{ 
                          minWidth: { xs: '100%', sm: '220px' },
                          py: 1.5
                        }}
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Cadastrar e Continuar"
                        )}
                      </Button>
                    </Box>
                  </Card>
                )}
              </Box>
            )}

            {/* Phase 3: Ad Creation */}
            {phase === 'adCreation' && registeredVehicle && (
              <Box sx={{ p: { xs: 2, md: 5 } }}>
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 4,
                    pb: 3,
                    flexDirection: { xs: 'column', sm: 'row' },
                    textAlign: { xs: 'center', sm: 'left' },
                    borderBottom: '1px solid rgba(255,255,255,0.05)'
                  }}
                >
                  <Badge
                    badgeContent={
                      <WhatshotIcon 
                        sx={{ 
                          color: '#FFF', 
                          bgcolor: 'error.main',
                          borderRadius: '50%',
                          p: 0.3,
                          boxShadow: '0 0 0 2px #161B22'
                        }} 
                      />
                    }
                    overlap="circular"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Avatar
                      sx={{
                        bgcolor: 'rgba(16, 185, 129, 0.15)', 
                        color: 'success.main',
                        width: 48,
                        height: 48,
                        mr: { xs: 0, sm: 2 },
                        mb: { xs: 2, sm: 0 }
                      }}
                    >
                      <LocalOfferIcon />
                    </Avatar>
                  </Badge>
                  <Box>
                    <Typography variant="h5" color="text.primary" gutterBottom sx={{ mb: 0.5 }}>
                      Finalize Seu Anúncio
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Estamos na reta final! Adicione detalhes para atrair compradores
                    </Typography>
                  </Box>
                </Box>

                {message.text && (
                  <Alert 
                    severity={message.type} 
                    sx={{ 
                      mb: 4,
                      display: 'flex',
                      alignItems: 'center',
                      '& .MuiAlert-icon': {
                        mr: 2,
                        alignItems: 'center',
                        fontSize: '2rem'
                      }
                    }}
                    icon={message.type === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ReportProblemIcon fontSize="inherit" />}
                  >
                    <Typography variant="body1" fontWeight={500}>
                      {message.text}
                    </Typography>
                  </Alert>
                )}

                {/* Demand Indicator - FOMO element */}
                <Box
                  sx={{
                    position: 'relative',
                    mb: 4,
                    overflow: 'hidden',
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(255, 107, 0, 0.15)',
                      border: '1px solid rgba(255, 107, 0, 0.3)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      position: 'relative',
                    }}
                  >
                    <Typography variant="subtitle1" align="center" fontWeight={600} color="primary.light" gutterBottom>
                      <TrendingUpIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                      Alta Demanda para {registeredVehicle.fabricante} {registeredVehicle.modelo}
                    </Typography>
                    
                    <Box sx={{ width: '100%', mt: 1 }}>
                      <LinearProgress 
                        variant="determinate" 
                        value={85} 
                        color="primary"
                        sx={{ height: 6, borderRadius: 3, mb: 1 }}
                      />
                      
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="caption" color="text.secondary">
                          Procura: <span style={{ color: '#FF6B00', fontWeight: 'bold' }}>Alta</span>
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          <strong>85%</strong> de interesse acima da média
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Card 
                  sx={{ 
                    mb: 4,
                    border: '1px solid rgba(16, 185, 129, 0.3)',
                    background: 'rgba(16, 185, 129, 0.05)',
                    boxShadow: '0 10px 30px rgba(16, 185, 129, 0.1)'
                  }}
                >
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                      <BeenhereIcon sx={{ color: 'success.main', mr: 1 }} />
                      <Typography variant="h6" color="success.main">
                        Veículo Cadastrado com Sucesso
                      </Typography>
                    </Box>
                    
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box 
                          sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(0,0,0,0.2)',
                            border: '1px solid rgba(255,255,255,0.05)'
                          }}
                        >
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Modelo
                          </Typography>
                          <Typography variant="body1" fontWeight={600} color="text.primary">
                            {registeredVehicle.modelo}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box 
                          sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(0,0,0,0.2)',
                            border: '1px solid rgba(255,255,255,0.05)'
                          }}
                        >
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Fabricante
                          </Typography>
                          <Typography variant="body1" fontWeight={600} color="text.primary">
                            {registeredVehicle.fabricante}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box 
                          sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(0,0,0,0.2)',
                            border: '1px solid rgba(255,255,255,0.05)'
                          }}
                        >
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Ano
                          </Typography>
                          <Typography variant="body1" fontWeight={600} color="text.primary">
                            {registeredVehicle.ano_fabricacao} / {registeredVehicle.ano_modelo}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <Box 
                          sx={{ 
                            p: 2, 
                            borderRadius: 2, 
                            bgcolor: 'rgba(0,0,0,0.2)',
                            border: '1px solid rgba(255,255,255,0.05)'
                          }}
                        >
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Placa
                          </Typography>
                          <Typography variant="body1" fontWeight={600} color="text.primary">
                            {maskPlate(registeredVehicle.placa)}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>

                <Typography 
                  variant="h6" 
                  color="primary" 
                  sx={{ 
                    mb: 3,
                    display: 'flex',
                    alignItems: 'center' 
                  }}
                >
                  <AttachMoneyIcon sx={{ mr: 1 }} />
                  Informações do Anúncio
                </Typography>

                <Grid container spacing={3} sx={{ mb: 4 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Preço (R$)"
                      type="number"
                      value={listingPrice}
                      onChange={(e) => setListingPrice(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyIcon sx={{ color: 'success.main' }} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    
                    {/* Price suggestion - FOMO element */}
                    {listingPrice && (
                      <Alert 
                        severity="info" 
                        sx={{ mt: 1 }}
                        icon={<InfoIcon fontSize="inherit" />}
                      >
                        <Typography variant="caption" color="info.light">
                          Carros similares na sua região são vendidos por <strong>
                            {formatCurrency(parseFloat(listingPrice) * 1.12)}
                          </strong> em média
                        </Typography>
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Condição"
                      value={listingCondition}
                      onChange={(e) => setListingCondition(e.target.value)}
                      select
                      SelectProps={{
                        native: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SpeedIcon sx={{ color: 'info.main' }} />
                          </InputAdornment>
                        ),
                      }}
                    >
                      <option value="new">Novo</option>
                      <option value="used">Usado</option>
                      <option value="certified">Certificado</option>
                    </TextField>
                  </Grid>
                </Grid>

                <TextField
                  fullWidth
                  label="Descrição do Anúncio"
                  value={listingDescription}
                  onChange={(e) => setListingDescription(e.target.value)}
                  multiline
                  rows={4}
                  placeholder="Descreva seu veículo destacando diferenciais e estado de conservação..."
                  sx={{ mb: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ alignSelf: 'flex-start', mt: 1.5 }}>
                        <DescriptionIcon sx={{ color: 'primary.main' }} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Box 
                  sx={{ 
                    mb: 4, 
                    p: 3,
                    border: '1px dashed rgba(255,255,255,0.2)',
                    borderRadius: 2,
                    background: 'rgba(0,0,0,0.1)'
                  }}
                >
                  <Typography 
                    variant="subtitle2" 
                    color="secondary.main"
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      mb: 2
                    }}
                  >
                    <PhotoCameraIcon sx={{ mr: 1 }} />
                    Fotos do Veículo (Máximo 3)
                  </Typography>
                  
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems: { xs: 'stretch', sm: 'center' },
                      gap: 2
                    }}
                  >
                    <Button 
                      variant="outlined" 
                      component="label" 
                      color="secondary"
                      disabled={loading}
                      startIcon={<AddPhotoAlternateIcon />}
                      sx={{ flexGrow: { xs: 1, sm: 0 } }}
                    >
                      Selecionar Fotos
                      <input type="file" accept="image/*" hidden multiple onChange={handleListingImageUpload} />
                    </Button>
                    
                    {listingImages.length > 0 ? (
                      <Chip 
                        icon={<CheckCircleOutlineIcon />}
                        label={`${listingImages.length} foto${listingImages.length > 1 ? 's' : ''} selecionada${listingImages.length > 1 ? 's' : ''}`}
                        color="success" 
                        variant="filled"
                        sx={{ fontWeight: 500 }}
                      />
                    ) : (
                      <Typography variant="caption" color="error.light">
                        <PriorityHighIcon sx={{ fontSize: '0.8rem', mr: 0.5, verticalAlign: 'middle' }} />
                        Anúncios com 3 fotos vendem até 3x mais rápido!
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Instant Boost Offer - FOMO element */}
                <Card 
                  sx={{ 
                    mb: 4,
                    background: 'linear-gradient(135deg, rgba(255, 75, 0, 0.2) 0%, rgba(255, 125, 0, 0.15) 100%)',
                    border: '1px solid rgba(255, 107, 0, 0.3)',
                    boxShadow: '0 10px 30px rgba(255, 107, 0, 0.15)',
                    position: 'relative',
                    overflow: 'visible'
                  }}
                >
                  {/* Hot Badge */}
                  <Box 
                    sx={{
                      position: 'absolute',
                      top: -12,
                      right: 16,
                      bgcolor: 'error.main',
                      color: '#fff',
                      py: 0.5,
                      px: 2,
                      borderRadius: '20px',
                      boxShadow: '0 4px 12px rgba(239, 68, 68, 0.3)',
                      display: 'flex',
                      alignItems: 'center',
                      zIndex: 1
                    }}
                  >
                    <WhatshotIcon fontSize="small" sx={{ mr: 0.5 }} />
                    <Typography variant="caption" fontWeight="bold">
                      LIMITADO
                    </Typography>
                  </Box>
                  
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <LocalFireDepartmentIcon sx={{ color: 'primary.main', mr: 1, fontSize: '1.5rem' }} />
                      <Typography variant="h6" color="primary.main" fontWeight="bold">
                        Boost Instantâneo
                      </Typography>
                    </Box>
                    
                    <Typography variant="body2" color="text.primary" sx={{ mb: 2 }}>
                      Destaque seu anúncio e receba até 5x mais visualizações nos primeiros 3 dias.
                      <strong> Oferta grátis</strong> para novos anunciantes!
                    </Typography>
                    
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexWrap: 'wrap',
                        gap: 1,
                        mb: 2 
                      }}
                    >
                      <Chip 
                        icon={<StarIcon fontSize="small" />}
                        label="Prioridade nos resultados" 
                        color="primary" 
                        variant="outlined"
                        size="small"
                      />
                      <Chip 
                        icon={<TrendingUpIcon fontSize="small" />}
                        label="5x mais visualizações" 
                        color="primary" 
                        variant="outlined"
                        size="small"
                      />
                      <Chip 
                        icon={<CheckCircleOutlineIcon fontSize="small" />}
                        label="Selo de destaque" 
                        color="primary" 
                        variant="outlined"
                        size="small"
                      />
                    </Box>
                    
                    <Box sx={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ px: 4 }}
                        startIcon={<WhatshotIcon />}
                      >
                        Ativar Boost Grátis
                      </Button>
                    </Box>
                  </CardContent>
                </Card>

                <Card 
                  sx={{ 
                    mb: 4,
                    background: 'linear-gradient(135deg, rgba(59, 130, 246, 0.1) 0%, rgba(59, 130, 246, 0.05) 100%)',
                    border: '1px solid rgba(59, 130, 246, 0.3)',
                    boxShadow: '0 10px 30px rgba(59, 130, 246, 0.1)'
                  }}
                >
                  <CardContent sx={{ p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <InfoIcon sx={{ color: 'info.main', mr: 2 }} />
                      <Typography variant="body2" color="text.primary">
                        <Typography component="span" fontWeight={600} color="info.main">
                          Dica pro:
                        </Typography>{' '}
                        Descreva diferenciais como acessórios, manutenções recentes e histórico para 
                        aumentar suas chances de venda em até 60%.
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>

                {/* Activity Indicator - FOMO element */}
                <Alert 
                  severity="info"
                  icon={<VisibilityIcon />}
                  sx={{ 
                    mb: 4,
                    animation: 'pulse 2s infinite',
                    '@keyframes pulse': {
                      '0%': { opacity: 0.8 },
                      '50%': { opacity: 1 },
                      '100%': { opacity: 0.8 }
                    }
                  }}
                >
                  <Typography variant="body2">
                    <strong>{activeUsers} pessoas</strong> estão vendo anúncios similares agora.
                    Na média, veículos como o seu são vendidos em <strong>1.3 dias</strong>.
                  </Typography>
                </Alert>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleAdCreation}
                    disabled={loading || !listingPrice}
                    startIcon={<LocalOfferIcon />}
                    sx={{ 
                      minWidth: { xs: '100%', sm: '280px' },
                      py: 2,
                      fontSize: '1.1rem'
                    }}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Publicar Anúncio Agora'}
                  </Button>
                </Box>
              </Box>
            )}
          </Paper>
        </Container>
        
        {/* Fixed Action Button - Mobile only */}
        {isMobile && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
              zIndex: 1000,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setDrawerOpen(true)}
              sx={{
                width: 56,
                height: 56,
                borderRadius: '50%',
                boxShadow: '0 4px 14px rgba(0, 208, 255, 0.4)',
              }}
            >
              <SendIcon />
            </Button>
          </Box>
        )}
        
        {/* Quick Action Drawer */}
        <SwipeableDrawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
        >
          <DrawerContent />
        </SwipeableDrawer>
        
        {/* Hot Deal Banner */}
        {showHotDeal && (
          <HotDealBanner onClose={() => setShowHotDeal(false)} />
        )}
        
        {/* Live Activity Notification */}
        {showNotification && (
          <LiveActivityNotification 
            message={notificationMessage} 
            onClose={() => setShowNotification(false)} 
          />
        )}
        
        {/* Persistent FOMO Notification */}
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            mb: { xs: 8, sm: 2 },
            '& .MuiPaper-root': {
              borderRadius: 3,
              bgcolor: 'rgba(16, 185, 129, 0.95)',
              color: '#fff',
              border: '1px solid rgba(255, 255, 255, 0.2)',
            }
          }}
        >
          <Alert 
            severity="success"
            icon={<AccessTimeIcon />}
            sx={{ 
              width: '100%', 
              '& .MuiAlert-icon': {
                color: '#fff'
              }
            }}
          >
            <Typography variant="body2" fontWeight={500} color="#fff">
              {Math.floor(Math.random() * 5) + 3} pessoas anunciaram nos últimos 10 minutos
            </Typography>
          </Alert>
        </Snackbar>
        
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default VehicleRegistration;
