import React, { useState, useEffect } from 'react';
import {
  AppBar, Box, Button, Card, CardContent, CardMedia, Chip, Container,
  IconButton, Drawer, Divider, Grid, List, ListItem, ListItemText,
  Typography, useScrollTrigger, ThemeProvider, createTheme, useMediaQuery,
  CssBaseline, Paper, Avatar, CircularProgress, Snackbar, Alert, Rating,
  Tabs, Tab, Breadcrumbs, Tooltip, Zoom, Fab, Accordion, AccordionDetails,
  AccordionSummary, Badge, TextField, Collapse, Slide, Grow, LinearProgress,
  FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  Search as SearchIcon,
  DirectionsCar as CarIcon,
  LocalOffer as OfferIcon,
  ArrowBack as ArrowBackIcon,
  Bookmark as BookmarkIcon,
  BookmarkBorder as BookmarkBorderIcon,
  WhatsApp as WhatsAppIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  Person as PersonIcon,
  Star as StarIcon,
  Check as CheckIcon,
  LocalParking as DiscountIcon,
  Notifications as NotificationsIcon,
  ShoppingCart as ShoppingCartIcon,
  Add as AddIcon,
  Remove as RemoveIcon,
  Info as InfoIcon,
  Settings as SettingsIcon,
  Build as BuildIcon,
  Speed as SpeedIcon,
  LocalShipping as LocalShippingIcon,
  Payment as PaymentIcon,
  Shield as ShieldIcon,
  Description as DescriptionIcon,
  Assignment as AssignmentIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  ThumbUp as ThumbUpIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Home as HomeIcon,
  NavigateNext as NavigateNextIcon,
  AddShoppingCart as AddShoppingCartIcon,
  CreditCard as CreditCardIcon,
  ShoppingBasket as ShoppingBasketIcon,
  AttachMoney as AttachMoneyIcon,
  LocalAtm as LocalAtmIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Share as ShareIcon,
  Chat as ChatIcon,
  QrCode as QrCodeIcon,
  Receipt as ReceiptIcon,
  AccessTime as AccessTimeIcon,
  FlashOn as FlashOnIcon,
  Bolt as BoltIcon,
  LocalFireDepartment as FireIcon,
  PriorityHigh as PriorityHighIcon,
  Visibility as VisibilityIcon,
  GroupAdd as GroupAddIcon,
  Timer as TimerIcon,
  Warning as WarningIcon,
  SentimentVerySatisfied as HappyIcon,
  Autorenew as AutorenewIcon,
  LockClock as LockClockIcon,
  HelpOutline as HelpOutlineIcon
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CountUp from 'react-countup';
import Countdown from 'react-countdown';

// JSON DE DADOS EDITÁVEIS DO PRODUTO
// Este objeto contém todos os dados editáveis para personalização do produto e da UX
const productData = {
  // Informações básicas do produto
  id: "ols-5w30-1l",
  name: "Óleo Sintético Premium Torki 5W-30",
  sku: "OL-SINT-5W30-1L",
  brand: "Torki",
  category: "Óleos e Lubrificantes",
  subcategory: "Óleo para Motor",
  
  // Preços e descontos
  pricing: {
    regularPrice: 79.90,
    salePrice: 59.90, 
    discountPercentage: 25,
    installments: 12,
    pixDiscount: 5,
    showCompareAtPrice: true, // mostra o preço original riscado
  },
  
  // Estratégias FOMO (Fear Of Missing Out)
  fomo: {
    limitedTimeOffer: {
      enabled: true,
      expirationDate: "2025-04-05T23:59:59", // Data de expiração da oferta
      showCountdown: true,
    },
    stockStatus: {
      available: 58,
      showRemainingCount: true,
      lowStockThreshold: 20,
      showSoldToday: true,
      soldToday: 42,
    },
    recentActivity: {
      enabled: true,
      messages: [
        { name: "Carlos A.", location: "São Paulo", action: "comprou", time: "12 min atrás" },
        { name: "Renata L.", location: "Rio de Janeiro", action: "adicionou ao carrinho", time: "24 min atrás" },
        { name: "João M.", location: "Curitiba", action: "comprou", time: "36 min atrás" },
        { name: "Marcelo P.", location: "Brasília", action: "está vendo", time: "agora" }
      ],
      rotationIntervalMs: 5000 // intervalo para rotacionar as mensagens
    },
    activeVisitors: {
      enabled: true,
      count: 38,
      message: "pessoas estão vendo este produto agora"
    }
  },
  
  // Informações sobre o produto
  description: "Proteção máxima para motores modernos. Desenvolvido com a mais avançada tecnologia de lubrificantes para proporcionar limpeza superior, proteção contra desgaste e melhor desempenho do motor mesmo em condições extremas.",
  longDescription: "O Óleo Sintético Premium Torki 5W-30 é formulado com tecnologia sintética avançada que oferece proteção excepcional contra desgaste e depósitos de motor, mesmo nas condições mais severas. Sua formulação especial mantém o motor limpo por mais tempo, reduz o atrito entre as peças móveis e proporciona economia de combustível sem comprometer a performance.\n\nIndicado para veículos a gasolina, etanol, flex e GNV de última geração, especialmente aqueles que operam em condições de alta exigência como tráfego urbano intenso, longas distâncias em rodovias ou condições climáticas extremas.",
  
  // Características e diferenciais do produto
  rating: 4.8,
  reviewCount: 346,
  features: [
    "100% sintético de alta performance",
    "Aprovado pelas principais montadoras",
    "Maior intervalo entre trocas",
    "Proteção contra desgaste",
    "Economia de combustível"
  ],
  
  // Vantagens competitivas
  competitiveAdvantages: [
    {
      icon: "FlashOn",
      title: "33% mais proteção",
      description: "Comparado a óleos convencionais"
    },
    {
      icon: "Speed",
      title: "Resposta imediata",
      description: "Melhor aceleração e desempenho"
    },
    {
      icon: "Build",
      title: "10.000 km entre trocas",
      description: "Economia e praticidade"
    },
    {
      icon: "LocalAtm",
      title: "Até 12% de economia",
      description: "Redução no consumo de combustível"
    }
  ],
  
  // Urgência e Escassez (elementos de FOMO)
  urgencyElements: {
    limitedQuantity: true,
    flashSale: true,
    endingTimeframe: "48 horas",
    specialBatch: true,
    batchNumber: "2025-03",
    hotProduct: true
  },
  
  // Gatilhos de garantia e segurança
  trustElements: [
    {
      icon: "Shield",
      title: "Garantia Torki",
      description: "3 meses contra defeitos"
    },
    {
      icon: "Autorenew",
      title: "Devolução facilitada",
      description: "30 dias para testar"
    },
    {
      icon: "AssignmentTurnedIn",
      title: "Produto 100% original",
      description: "Com nota fiscal"
    },
    {
      icon: "LocalShipping",
      title: "Envio imediato",
      description: "Despachamos no mesmo dia"
    }
  ],
  
  // Depoimentos destacados (social proof)
  highlightedTestimonials: [
    {
      name: "Roberto C.",
      vehicle: "VW Golf TSI",
      stars: 5,
      comment: "Sensível melhora na performance e consumo. Motor mais silencioso!",
      image: "https://source.unsplash.com/random/50x50/?man,1"
    },
    {
      name: "Amanda S.",
      vehicle: "Honda Civic",
      stars: 5,
      comment: "Meu mecânico recomendou e notei diferença no funcionamento.",
      image: "https://source.unsplash.com/random/50x50/?woman,1"
    }
  ],
  
  // Kit de vendas (upselling)
  salesKit: {
    title: "Kit de troca completo",
    description: "Aproveite e leve o kit completo com desconto especial",
    saving: "22%",
    price: 129.90,
    regularPrice: 167.70,
    items: [
      {
        name: "Óleo Sintético 5W-30 Torki",
        quantity: 1,
        regularPrice: 79.90,
        included: true
      },
      {
        name: "Filtro de Óleo Original Torki",
        quantity: 1,
        regularPrice: 38.90,
        included: true
      },
      {
        name: "Filtro de Ar Torki",
        quantity: 1,
        regularPrice: 48.90,
        included: true
      }
    ]
  },
  
  // Especificações técnicas
  specifications: [
    { name: "Viscosidade", value: "5W-30" },
    { name: "Volume", value: "1 Litro" },
    { name: "Base", value: "Sintética" },
    { name: "API", value: "SN/SN PLUS" },
    { name: "ACEA", value: "A3/B4, A5/B5" },
    { name: "Aprovações", value: "VW 504.00/507.00, MB 229.51, BMW LL-04" },
    { name: "Aplicação", value: "Veículos a gasolina, etanol, flex e GNV" }
  ],
  
  // Compatibilidade veicular
  compatibleVehicles: [
    "Ford Focus 1.6 (2009-2019)",
    "Chevrolet Onix 1.0 Turbo (2019-2023)",
    "Volkswagen Golf 1.4 TSI (2013-2021)",
    "Toyota Corolla 2.0 (2017-2023)",
    "Honda Civic 1.5 Turbo (2016-2022)",
    "Hyundai HB20 1.0 Turbo (2019-2023)",
    "Ver lista completa (154 veículos)"
  ],
  
  // Perguntas frequentes
  faq: [
    {
      question: "Qual a diferença entre óleo sintético e mineral?",
      answer: "O óleo sintético é produzido em laboratório com moléculas uniformes, oferecendo melhor desempenho em temperaturas extremas, maior proteção contra desgaste e intervalos mais longos entre trocas. Já o óleo mineral é derivado diretamente do petróleo, com moléculas de tamanhos variados, sendo mais econômico, porém com menor desempenho e durabilidade."
    },
    {
      question: "Posso misturar este óleo com outras marcas ou viscosidades?",
      answer: "Embora não seja recomendado misturar óleos de diferentes viscosidades e especificações, este óleo sintético 5W-30 é compatível com outros óleos sintéticos de mesma viscosidade em caso de emergência. Para melhor desempenho e proteção do motor, recomendamos usar exclusivamente o Óleo Sintético Premium Torki 5W-30."
    },
    {
      question: "Qual o intervalo recomendado para troca deste óleo?",
      answer: "O intervalo recomendado para troca do Óleo Sintético Premium Torki 5W-30 é de até 10.000 km ou 12 meses, o que ocorrer primeiro. Para veículos que operam em condições severas (tráfego intenso, curtas distâncias, poeira excessiva), recomenda-se reduzir este intervalo para 7.500 km ou 6 meses."
    }
  ],
  
  // Produtos relacionados
  relatedProducts: [
    {
      id: 2,
      name: "Filtro de Óleo Original Torki",
      price: 38.90,
      discountPrice: 28.90,
      image: "https://source.unsplash.com/random/300x300/?oil,filter",
      badge: "Mais Vendido"
    },
    {
      id: 3,
      name: "Kit 5 Litros Óleo Sintético 5W-30",
      price: 359.90,
      discountPrice: 289.90,
      image: "https://source.unsplash.com/random/300x300/?oil,bottles",
      badge: "Oferta"
    },
    {
      id: 4,
      name: "Kit Troca de Óleo Completo",
      price: 189.90,
      discountPrice: 149.90,
      image: "https://source.unsplash.com/random/300x300/?car,maintenance",
      badge: "Kit Completo"
    }
  ],
  
  // Avaliações de clientes
  reviews: [
    {
      id: 1,
      user: "Roberto C.",
      rating: 5,
      date: "15/03/2025",
      title: "Desempenho excelente",
      comment: "Uso esse óleo há mais de 2 anos no meu Golf TSI e é sensível a melhora na performance e consumo. O motor funciona mais silencioso e com melhor resposta. Recomendo!",
      isVerified: true
    },
    {
      id: 2,
      user: "Amanda S.",
      rating: 5,
      date: "02/03/2025",
      title: "Ótimo custo-benefício",
      comment: "Excelente produto! Meu mecânico recomendou e notei diferença no funcionamento do motor. Menos ruído e melhor aceleração.",
      isVerified: true
    },
    {
      id: 3,
      user: "Carlos M.",
      rating: 4,
      date: "25/02/2025",
      title: "Bom produto, entrega rápida",
      comment: "Boa qualidade e preço justo. Entrega dentro do prazo e bem embalado. Tirei uma estrela só porque achei o selo de segurança um pouco difícil de remover.",
      isVerified: true
    }
  ],
  
  // Opções de pagamento
  paymentOptions: [
    "Cartão de crédito em até 12x sem juros",
    "Cartão de débito",
    "PIX com 5% de desconto adicional",
    "Boleto bancário"
  ],
  
  // Informações de envio
  shipping: {
    free: true,
    minValue: 150,
    estimatedDays: "2-3 dias úteis",
    expressOption: {
      available: true,
      price: 12.90,
      estimatedDays: "1 dia útil"
    },
    pickupInStore: true
  },
  
  // Garantia e política de devolução
  warranty: {
    period: "3 meses",
    conditions: "Contra defeitos de fabricação",
    extended: {
      available: true,
      price: 9.90,
      period: "12 meses"
    }
  },
  
  // Informações de mídia
  media: {
    images: [
      "https://source.unsplash.com/random/600x600/?oil,synthetic,1",
      "https://source.unsplash.com/random/600x600/?oil,synthetic,2",
      "https://source.unsplash.com/random/600x600/?oil,synthetic,3",
      "https://source.unsplash.com/random/600x600/?oil,synthetic,4"
    ],
    videos: [
      {
        thumbnail: "https://source.unsplash.com/random/300x200/?oil,tutorial",
        url: "https://www.youtube.com/embed/dQw4w9WgXcQ",
        title: "Como aplicar o óleo Torki corretamente"
      }
    ]
  },
  
  // Configurações de WhatsApp
  whatsapp: {
    number: "5511987654321",
    defaultMessage: "Olá! Estou interessado no Óleo Sintético Premium Torki 5W-30. Poderia me dar mais informações?",
    enableFloatingButton: true
  },
  
  // Configurações de SEO
  seo: {
    title: "Óleo Sintético Premium Torki 5W-30 | Desempenho Superior para seu Motor",
    description: "Proteção máxima para motores modernos. Desenvolvido com tecnologia avançada para proporcionar limpeza superior e proteção contra desgaste.",
    keywords: ["óleo sintético", "5W-30", "motor", "lubrificante premium", "Torki"]
  },
  
  // Informações de contato da loja
  storeInfo: {
    name: "Torki",
    address: "Av. das Nações Unidas, 14401 - Chácara Santo Antônio, São Paulo - SP, 04794-000",
    email: "contato@torki.com.br",
    phone: "(11) 3500-9000",
    socialMedia: {
      instagram: "https://instagram.com/torki",
      facebook: "https://facebook.com/torki",
      youtube: "https://youtube.com/torki",
      twitter: "https://twitter.com/torki"
    }
  }
};

// Tema da marca com visual agressivo e comercial
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FF3D00', // Laranja intenso
      light: '#FF7539',
      dark: '#DD2C00',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#071429', // Azul escuro
      light: '#132543',
      dark: '#050E1B',
      contrastText: '#FFF',
    },
    background: {
      default: '#071429',
      paper: '#0D1E38',
      card: '#132543',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#B0B7C3',
    },
    action: {
      hover: alpha('#FF3D00', 0.2),
      selected: alpha('#FF3D00', 0.3),
    },
    error: {
      main: '#FF3D00',
    },
    success: {
      main: '#00E676',
    },
    warning: {
      main: '#FFAB00',
    }
  },
  typography: {
    fontFamily: '"Montserrat", "Inter", "Roboto", sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 900,
    h1: {
      fontSize: '2.75rem',
      fontWeight: 900,
      lineHeight: 1.1,
      letterSpacing: '-0.02em',
      '@media (min-width:600px)': {
        fontSize: '4rem',
      },
    },
    h2: {
      fontSize: '2.25rem',
      fontWeight: 900,
      lineHeight: 1.1,
      letterSpacing: '-0.01em',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 800,
      lineHeight: 1.2,
      '@media (min-width:600px)': {
        fontSize: '2.25rem',
      },
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 800,
      lineHeight: 1.2,
      '@media (min-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: 1.3,
      '@media (min-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 700,
      lineHeight: 1.3,
      '@media (min-width:600px)': {
        fontSize: '1.25rem',
      },
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: 'smooth',
        },
        body: {
          overflowX: 'hidden',
        },
        '::selection': {
          backgroundColor: alpha('#FF3D00', 0.7),
          color: '#fff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '12px 24px',
          fontWeight: 700,
          transition: 'all 0.3s ease-in-out',
          textTransform: 'none',
        },
        containedPrimary: {
          boxShadow: '0 4px 18px 0 rgba(255, 61, 0, 0.4)',
          '&:hover': {
            boxShadow: '0 8px 25px rgba(255, 61, 0, 0.65)',
            transform: 'translateY(-3px)',
          },
        },
        outlinedPrimary: {
          borderWidth: 2,
          '&:hover': {
            borderWidth: 2,
            transform: 'translateY(-2px)',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          borderRadius: 16,
          background: alpha('#132543', 0.7),
          backdropFilter: 'blur(10px)',
          boxShadow: '0 10px 40px rgba(0, 0, 0, 0.25)',
          border: `1px solid ${alpha('#FFFFFF', 0.05)}`,
          transition: 'transform 0.4s ease, box-shadow 0.4s ease',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          fontWeight: 700,
          '&.MuiChip-filled': {
            background: alpha('#FF3D00', 0.95),
          },
        },
      },
    },
  },
});

// Componentes estilizados para a interface
const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  transition: 'all 0.4s ease-in-out',
  '&.scrolled': {
    background: alpha(theme.palette.background.default, 0.85),
    backdropFilter: 'blur(20px)',
    boxShadow: `0 8px 32px 0 ${alpha('#000', 0.2)}`,
  },
}));

const ProductImage = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 10px 40px rgba(0, 0, 0, 0.25)',
  position: 'relative',
  width: '100%',
  height: 'auto',
  aspectRatio: '1/1',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.5s ease',
  },
  '&:hover img': {
    transform: 'scale(1.05)',
  }
}));

const ProductThumb = styled(Box)(({ theme, selected }) => ({
  width: 80,
  height: 80,
  borderRadius: 8,
  overflow: 'hidden',
  cursor: 'pointer',
  border: selected ? `2px solid ${theme.palette.primary.main}` : `2px solid transparent`,
  transition: 'all 0.3s ease',
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
  },
}));

const QuantitySelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 150,
  height: 48,
  borderRadius: 8,
  border: `1px solid ${alpha('#fff', 0.15)}`,
  overflow: 'hidden',
}));

const QuantityButton = styled(IconButton)(({ theme }) => ({
  width: 40,
  height: 48,
  borderRadius: 0,
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
}));

const FeatureChip = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
  borderRadius: 8,
  padding: '8px 12px',
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  border: `1px solid ${alpha('#fff', 0.1)}`,
  marginBottom: 8,
  marginRight: 8,
  backdropFilter: 'blur(10px)',
}));

const SpecificationItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 0',
  borderBottom: `1px solid ${alpha('#fff', 0.1)}`,
  '&:last-child': {
    borderBottom: 0,
  },
}));

const BadgeHighlight = styled(Box)(({ theme, type = "default" }) => {
  const colors = {
    default: theme.palette.primary.main,
    hot: '#FF3D00',
    limited: '#FFAB00',
    bestseller: '#00E676',
    new: '#2979FF'
  };
  
  const color = colors[type] || colors.default;
  
  return {
    position: 'absolute',
    top: 16,
    left: 16,
    backgroundColor: color,
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: 8,
    padding: '8px 12px',
    zIndex: 2,
    boxShadow: `0 4px 12px ${alpha(color, 0.4)}`,
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    textTransform: 'uppercase',
    fontSize: '0.875rem',
    letterSpacing: '0.5px'
  };
});

const PulseBox = styled(Box)(({ theme, color = 'primary.main' }) => ({
  position: 'relative',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: 'inherit',
    background: theme.palette[color] ? theme.palette[color].main : color,
    animation: 'pulse 2s infinite',
    zIndex: -1,
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6,
      transform: 'scale(1)',
    },
    '70%': {
      opacity: 0,
      transform: 'scale(1.1)',
    },
    '100%': {
      opacity: 0,
      transform: 'scale(1.1)',
    },
  },
}));

const CountdownWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  justifyContent: 'center',
  marginTop: 16,
  marginBottom: 16,
}));

const CountdownItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: 'blur(10px)',
  borderRadius: 8,
  padding: '8px 12px',
  minWidth: 60,
}));

const BenefitCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 12,
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  padding: 24,
  borderRadius: 12,
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: `0 10px 25px ${alpha('#000', 0.25)}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.7),
  }
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '-50%',
    right: '-50%',
    bottom: '-50%',
    left: '-50%',
    background: `linear-gradient(to bottom, rgba(255,255,255,0) 0%, ${alpha('#fff', 0.2)} 50%, rgba(255,255,255,0) 100%)`,
    transform: 'rotateZ(60deg) translate(-5em, 7.5em)',
    animation: 'shine 3s infinite',
    animationDelay: '0.5s',
  },
  '@keyframes shine': {
    '0%': {
      transform: 'rotateZ(60deg) translate(-5em, 7.5em)',
    },
    '100%': {
      transform: 'rotateZ(60deg) translate(5em, -7.5em)',
    },
  },
}));

const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  display: 'inline-block',
}));

const FomoAlert = styled(Box)(({ theme, type = 'warning' }) => {
  const colors = {
    warning: theme.palette.warning.main,
    error: theme.palette.error.main,
    success: theme.palette.success.main,
    info: theme.palette.primary.main
  };
  
  return {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    backgroundColor: alpha(colors[type], 0.15),
    color: colors[type],
    borderRadius: 8,
    padding: '12px 16px',
    marginBottom: 16,
    border: `1px solid ${alpha(colors[type], 0.3)}`,
    fontWeight: 'medium',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 4,
      height: '100%',
      backgroundColor: colors[type]
    }
  };
});

const ActivityNotification = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 12,
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  borderRadius: 8,
  padding: '12px 16px',
  marginBottom: 12,
  border: `1px solid ${alpha('#fff', 0.1)}`,
  backdropFilter: 'blur(10px)',
  animation: 'slideInUp 0.5s ease'
}));

const SocialProofBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  borderRadius: 8,
  padding: '12px 16px',
  margin: '24px 0',
  gap: 16
}));

const StockProgressBar = styled(LinearProgress)(({ theme, value }) => {
  const getColor = () => {
    if (value < 30) return theme.palette.error.main;
    if (value < 70) return theme.palette.warning.main;
    return theme.palette.success.main;
  };

  return {
    height: 8,
    borderRadius: 4,
    backgroundColor: alpha(getColor(), 0.2),
    '& .MuiLinearProgress-bar': {
      backgroundColor: getColor(),
    }
  };
});

// Helpers
const formatCountdownTime = ({ days, hours, minutes, seconds }) => {
  return (
    <CountdownWrapper>
      <CountdownItem>
        <Typography variant="h6" fontWeight="bold">{days}</Typography>
        <Typography variant="caption">Dias</Typography>
      </CountdownItem>
      <CountdownItem>
        <Typography variant="h6" fontWeight="bold">{hours}</Typography>
        <Typography variant="caption">Horas</Typography>
      </CountdownItem>
      <CountdownItem>
        <Typography variant="h6" fontWeight="bold">{minutes}</Typography>
        <Typography variant="caption">Min</Typography>
      </CountdownItem>
      <CountdownItem>
        <Typography variant="h6" fontWeight="bold">{seconds}</Typography>
        <Typography variant="caption">Seg</Typography>
      </CountdownItem>
    </CountdownWrapper>
  );
};

const openWhatsApp = (message = '') => {
  const phone = productData.whatsapp.number;
  const encodedMessage = encodeURIComponent(message || productData.whatsapp.defaultMessage);
  window.open(`https://wa.me/${phone}?text=${encodedMessage}`, '_blank');
};

// Componente de Botão Flutuante de WhatsApp
const WhatsAppFab = () => {
  return (
    <Zoom in={productData.whatsapp.enableFloatingButton}>
      <Fab
        color="primary"
        aria-label="whatsapp"
        sx={{
          position: 'fixed',
          bottom: 30,
          right: 30,
          zIndex: 1000,
          bgcolor: '#25D366',
          '&:hover': {
            bgcolor: '#128C7E',
          },
          boxShadow: '0 4px 20px rgba(37, 211, 102, 0.5)',
        }}
        onClick={() => openWhatsApp()}
      >
        <WhatsAppIcon />
      </Fab>
    </Zoom>
  );
};

// Componente para TabPanel
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`product-tabpanel-${index}`}
      aria-labelledby={`product-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `product-tab-${index}`,
    'aria-controls': `product-tabpanel-${index}`,
  };
}

// Componente de Notificação de Atividade Recente
const RecentActivityAlert = ({ activities }) => {
  const [currentActivity, setCurrentActivity] = useState(0);
  
  useEffect(() => {
    if (!activities || activities.length === 0) return;
    
    const interval = setInterval(() => {
      setCurrentActivity((prev) => (prev + 1) % activities.length);
    }, productData.fomo.recentActivity.rotationIntervalMs);
    
    return () => clearInterval(interval);
  }, [activities]);
  
  if (!activities || activities.length === 0) return null;
  
  const activity = activities[currentActivity];
  
  return (
    <Grow in={true} key={currentActivity}>
      <ActivityNotification>
        <Avatar sx={{ width: 36, height: 36, bgcolor: 'primary.main' }}>
          <PersonIcon fontSize="small" />
        </Avatar>
        <Box>
          <Typography variant="body2">
            <Box component="span" fontWeight="bold">{activity.name}</Box> de {activity.location} {activity.action} este produto
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {activity.time}
          </Typography>
        </Box>
      </ActivityNotification>
    </Grow>
  );
};

// Página de detalhes do produto
const ProductDetail = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedImage, setSelectedImage] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [mobileOpen, setMobileOpen] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [kitSelected, setKitSelected] = useState(false);
  const [extendedWarranty, setExtendedWarranty] = useState(false);
  const [showSalesKit, setShowSalesKit] = useState(false);
  const [addingToCart, setAddingToCart] = useState(false);
  const [showStockWarning, setShowStockWarning] = useState(false);
  
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 50,
  });

  // Simular carregamento de dados do produto
  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      // Simulação de chamada de API
      setTimeout(() => {
        setProduct(productData);
        setLoading(false);
        
        // Exibir Sales Kit após 3 segundos
        setTimeout(() => {
          setShowSalesKit(true);
        }, 3000);
        
        // Exibir alerta de estoque baixo após 5 segundos
        setTimeout(() => {
          if (productData.fomo.stockStatus.available < productData.fomo.stockStatus.lowStockThreshold) {
            setShowStockWarning(true);
          }
        }, 5000);
      }, 1000);
    };

    fetchProduct();
  }, [productId]);

  // Funções de interação
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleQuantityChange = (newValue) => {
    if (newValue >= 1 && newValue <= (product?.fomo.stockStatus.available || 100)) {
      setQuantity(newValue);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleAddToCart = () => {
    setAddingToCart(true);
    
    // Simular processo de adição ao carrinho
    setTimeout(() => {
      setCartCount(cartCount + quantity);
      setAddingToCart(false);
      
      const kitItems = kitSelected ? product.salesKit.items.length : 0;
      const warrantyText = extendedWarranty ? " + garantia estendida" : "";
      
      setNotification({
        open: true,
        message: kitSelected 
          ? `Kit com ${kitItems} itens adicionado ao carrinho${warrantyText}`
          : `${quantity} ${quantity > 1 ? 'unidades adicionadas' : 'unidade adicionada'} ao carrinho${warrantyText}`,
        severity: 'success'
      });
    }, 800);
  };

  const handleBuyNow = () => {
    handleAddToCart();
    setTimeout(() => {
      navigate('/checkout');
    }, 800);
  };

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite);
    setNotification({
      open: true,
      message: isFavorite ? 'Produto removido dos favoritos' : 'Produto adicionado aos favoritos',
      severity: 'success'
    });
  };

  const handleShareProduct = () => {
    if (navigator.share) {
      navigator.share({
        title: product?.name,
        text: `Confira este produto na Torki: ${product?.name}`,
        url: window.location.href,
      })
      .catch(error => console.log('Erro ao compartilhar', error));
    } else {
      // Fallback para navegadores sem API Web Share
      setNotification({
        open: true,
        message: 'Link copiado para a área de transferência',
        severity: 'success'
      });
      navigator.clipboard.writeText(window.location.href);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleKitSelect = () => {
    setKitSelected(!kitSelected);
  };

  const handleExtendedWarranty = () => {
    setExtendedWarranty(!extendedWarranty);
  };

  // Se estiver carregando, mostrar indicador de progresso
  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <CircularProgress color="primary" size={60} />
          <Typography variant="h6">Carregando detalhes do produto...</Typography>
        </Box>
      </ThemeProvider>
    );
  }

  // Se o produto não foi encontrado
  if (!product) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <InfoIcon sx={{ fontSize: 60, color: 'error.main' }} />
          <Typography variant="h4">Produto não encontrado</Typography>
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate(-1)}
            sx={{ mt: 2 }}
          >
            Voltar à loja
          </Button>
        </Box>
      </ThemeProvider>
    );
  }

  // Calcular preço final com base nas seleções
  const calculateFinalPrice = () => {
    let basePrice = kitSelected ? product.salesKit.price : product.pricing.salePrice;
    
    if (extendedWarranty) {
      basePrice += product.warranty.extended.price;
    }
    
    return basePrice * quantity;
  };

  // Calcular economia total
  const calculateTotalSavings = () => {
    const regularPrice = kitSelected ? product.salesKit.regularPrice : product.pricing.regularPrice;
    const currentPrice = kitSelected ? product.salesKit.price : product.pricing.salePrice;
    
    return ((regularPrice - currentPrice) * quantity).toFixed(2);
  };

  // Calcular porcentagem restante de estoque
  const stockPercentage = Math.min(100, Math.max(0, (product.fomo.stockStatus.available / product.fomo.stockStatus.lowStockThreshold) * 100));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      {/* SEO Optimization */}
      <Helmet>
        <title>{product.seo.title}</title>
        <meta name="description" content={product.seo.description} />
        <meta name="keywords" content={product.seo.keywords.join(', ')} />
        <meta property="og:title" content={product.seo.title} />
        <meta property="og:description" content={product.seo.description} />
        <meta property="og:type" content="product" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={product.media.images[0]} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
        {/* Structured Data para Rich Snippets */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${product.name}",
              "image": "${product.media.images[0]}",
              "description": "${product.description}",
              "brand": {
                "@type": "Brand",
                "name": "${product.brand}"
              },
              "sku": "${product.sku}",
              "offers": {
                "@type": "Offer",
                "url": "${window.location.href}",
                "priceCurrency": "BRL",
                "price": "${product.pricing.salePrice}",
                "priceValidUntil": "${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0]}",
                "availability": "https://schema.org/InStock"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "${product.rating}",
                "reviewCount": "${product.reviewCount}"
              }
            }
          `}
        </script>
      </Helmet>

      {/* WhatsApp Button */}
      <WhatsAppFab />

      {/* AppBar/Header */}
      <StyledAppBar position="fixed" className={trigger ? 'scrolled' : ''}>
        <Container>
          <Box display="flex" alignItems="center" py={1.5}>
            <Box display="flex" alignItems="center" gap={1} sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
              <CarIcon color="primary" sx={{ fontSize: 32 }} />
              <Typography variant="h6" component="div" fontWeight="bold">
                TORKI
              </Typography>
            </Box>
            
            <Box sx={{ flexGrow: 1 }} />
            
            {/* Menu Desktop */}
            <Box sx={{ display: { xs: 'none', md: 'flex' } }} gap={3} alignItems="center">
              <Button color="inherit" href="/servicos">Serviços</Button>
              <Button color="inherit" href="/agendamento">Agendar</Button>
              <Button color="inherit" href="/blog">Blog</Button>
              <Button color="inherit" href="/contato">Contato</Button>
              <Button color="primary" href="/loja" variant="text" sx={{ fontWeight: 'bold' }}>Loja</Button>
              
              <Tooltip title="Favoritos">
                <IconButton color="inherit">
                  <FavoriteBorderIcon />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Carrinho de compras">
                <IconButton color="inherit" onClick={() => navigate('/carrinho')}>
                  <Badge badgeContent={cartCount} color="primary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Notificações">
                <IconButton color="primary">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Minha conta">
                <IconButton sx={{ p: 0.5 }}>
                  <Avatar sx={{ width: 32, height: 32, bgcolor: 'primary.main' }}>
                    <PersonIcon fontSize="small" />
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            
            {/* Menu Mobile Toggle */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', gap: 1 }}>
              <Tooltip title="Carrinho de compras">
                <IconButton color="inherit" onClick={() => navigate('/carrinho')}>
                  <Badge badgeContent={cartCount} color="primary">
                    <ShoppingCartIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              
              <IconButton
                color="inherit"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </StyledAppBar>

      {/* Mobile Drawer */}
      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { 
            width: '100%',
            background: theme.palette.background.default,
          },
        }}
      >
        <Box p={2} display="flex" justifyContent="flex-end">
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          <ListItem button onClick={() => { navigate('/'); handleDrawerToggle(); }}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/servicos'); handleDrawerToggle(); }}>
            <ListItemText primary="Serviços" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/agendamento'); handleDrawerToggle(); }}>
            <ListItemText primary="Agendar Serviço" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/blog'); handleDrawerToggle(); }}>
            <ListItemText primary="Blog" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/loja'); handleDrawerToggle(); }}>
            <ListItemText primary="Loja" primaryTypographyProps={{ color: 'primary', fontWeight: 'bold' }} />
          </ListItem>
          <ListItem button onClick={() => { navigate('/favoritos'); handleDrawerToggle(); }}>
            <ListItemText primary="Favoritos" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/carrinho'); handleDrawerToggle(); }}>
            <ListItemText primary="Carrinho" />
          </ListItem>
          <ListItem button onClick={() => { navigate('/minha-conta'); handleDrawerToggle(); }}>
            <ListItemText primary="Minha Conta" />
          </ListItem>
          <Divider sx={{ my: 2 }} />
          <ListItem>
            <Button 
              fullWidth 
              variant="contained" 
              color="primary"
              startIcon={<WhatsAppIcon />}
              onClick={() => openWhatsApp(`Olá! Estou interessado no produto ${product.name}. Poderia me dar mais informações?`)}
              size="large"
            >
              CONSULTAR DISPONIBILIDADE
            </Button>
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content */}
      <Box component="main" sx={{ pt: { xs: 8, sm: 10 } }}>
        <Container maxWidth="lg" sx={{ py: 4 }}>
          {/* Breadcrumbs */}
          <Breadcrumbs 
            separator={<NavigateNextIcon fontSize="small" />} 
            aria-label="breadcrumb"
            sx={{ mb: 4, color: 'text.secondary' }}
          >
            <Button
              color="inherit"
              startIcon={<HomeIcon fontSize="small" />}
              onClick={() => navigate('/')}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              Home
            </Button>
            <Button
              color="inherit"
              onClick={() => navigate('/loja')}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              Loja
            </Button>
            <Button
              color="inherit"
              onClick={() => navigate(`/loja/categoria/${product.category}`)}
              size="small"
              sx={{ textTransform: 'none' }}
            >
              {product.category}
            </Button>
            <Typography color="text.primary" sx={{ fontWeight: 'medium' }}>
              {product.name}
            </Typography>
          </Breadcrumbs>

          {/* Flash Sale Indicator (FOMO) */}
          {product.fomo.limitedTimeOffer.enabled && (
            <FomoAlert type="warning" sx={{ mb: 3 }}>
              <FlashOnIcon />
              <Box>
                <Typography variant="body2" fontWeight="bold">
                  OFERTA RELÂMPAGO! TERMINA EM:
                </Typography>
                <Countdown 
                  date={new Date(product.fomo.limitedTimeOffer.expirationDate)} 
                  renderer={formatCountdownTime}
                />
              </Box>
            </FomoAlert>
          )}

          {/* Product Main Content */}
          <Grid container spacing={4}>
            {/* Product Images */}
            <Grid item xs={12} md={6}>
              <Box sx={{ position: 'relative' }}>
                <ProductImage>
                  <img src={product.media.images[selectedImage]} alt={product.name} />
                  
                  {/* Discount Badge */}
                  {product.pricing.discountPercentage > 0 && (
                    <BadgeHighlight type="hot">
                      <DiscountIcon fontSize="small" />
                      {product.pricing.discountPercentage}% OFF
                    </BadgeHighlight>
                  )}
                  
                  {/* Hot Product Badge */}
                  {product.urgencyElements.hotProduct && (
                    <Box 
                      sx={{ 
                        position: 'absolute', 
                        top: 16, 
                        right: 16, 
                        bgcolor: alpha(theme.palette.error.main, 0.9),
                        color: 'white',
                        fontWeight: 'bold',
                        borderRadius: 2,
                        px: 2,
                        py: 1,
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}
                    >
                      <FireIcon fontSize="small" />
                      MAIS VENDIDO
                    </Box>
                  )}
                  
                  {/* Action Buttons */}
                  <Box 
                    sx={{ 
                      position: 'absolute', 
                      bottom: 16, 
                      right: 16, 
                      display: 'flex',
                      gap: 1,
                      zIndex: 1
                    }}
                  >
                    <IconButton 
                      sx={{ 
                        bgcolor: 'background.paper', 
                        '&:hover': { bgcolor: 'background.default' } 
                      }}
                      onClick={handleToggleFavorite}
                    >
                      {isFavorite ? <FavoriteIcon color="primary" /> : <FavoriteBorderIcon />}
                    </IconButton>
                    
                    <IconButton 
                      sx={{ 
                        bgcolor: 'background.paper', 
                        '&:hover': { bgcolor: 'background.default' } 
                      }}
                      onClick={handleShareProduct}
                    >
                      <ShareIcon />
                    </IconButton>
                  </Box>
                </ProductImage>
                
                {/* Active Viewers (FOMO) */}
                {product.fomo.activeVisitors.enabled && (
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      position: 'absolute',
                      left: 16,
                      bottom: 16,
                      bgcolor: alpha(theme.palette.background.paper, 0.8),
                      backdropFilter: 'blur(10px)',
                      borderRadius: 2,
                      px: 2,
                      py: 1,
                      zIndex: 1
                    }}
                  >
                    <VisibilityIcon fontSize="small" color="primary" />
                    <Typography variant="body2" fontWeight="medium">
                      <CountUp 
                        end={product.fomo.activeVisitors.count} 
                        duration={2.5} 
                      /> {product.fomo.activeVisitors.message}
                    </Typography>
                  </Box>
                )}
                
                {/* Thumbnails */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    mt: 2, 
                    justifyContent: 'center',
                    flexWrap: 'wrap'
                  }}
                >
                  {product.media.images.map((image, index) => (
                    <ProductThumb 
                      key={index} 
                      selected={selectedImage === index}
                      onClick={() => setSelectedImage(index)}
                    >
                      <img src={image} alt={`${product.name} - Imagem ${index + 1}`} />
                    </ProductThumb>
                  ))}
                </Box>

                {/* Recent Activity Alert (FOMO) */}
                {product.fomo.recentActivity.enabled && (
                  <Box sx={{ mt: 3 }}>
                    <RecentActivityAlert activities={product.fomo.recentActivity.messages} />
                  </Box>
                )}
              </Box>
            </Grid>
            
            {/* Product Details */}
            <Grid item xs={12} md={6}>
              <Box>
                {/* Product Title and Rating */}
                <Typography variant="h3" component="h1" fontWeight="bold" gutterBottom>
                  {product.name}
                </Typography>
                
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                  <Rating value={product.rating} precision={0.5} readOnly />
                  <Typography variant="body2" color="text.secondary">
                    ({product.rating}/5) - {product.reviewCount} avaliações
                  </Typography>
                </Box>
                
                {/* SKU and Brand */}
                <Box display="flex" gap={4} mb={3}>
                  <Typography variant="body2" color="text.secondary">
                    <Box component="span" fontWeight="medium">SKU:</Box> {product.sku}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Box component="span" fontWeight="medium">Vendido por:</Box> {product.brand}
                  </Typography>
                </Box>
                
                {/* Stock Status & Sales Volume (FOMO) */}
                <Box mb={3}>
                  {product.fomo.stockStatus.showRemainingCount && (
                    <Box>
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography variant="body2" color={product.fomo.stockStatus.available < product.fomo.stockStatus.lowStockThreshold ? "error.main" : "text.primary"} fontWeight="medium">
                          {product.fomo.stockStatus.available < product.fomo.stockStatus.lowStockThreshold ? (
                            <Box component="span" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                              <WarningIcon fontSize="small" />
                              ESTOQUE LIMITADO: Apenas {product.fomo.stockStatus.available} unidades disponíveis!
                            </Box>
                          ) : (
                            `Disponibilidade: ${product.fomo.stockStatus.available} em estoque`
                          )}
                        </Typography>
                        <Typography variant="body2" fontWeight="medium" color="primary.main">
                          {product.fomo.stockStatus.available}/<Box component="span" color="text.secondary">{product.fomo.stockStatus.lowStockThreshold * 5}</Box>
                        </Typography>
                      </Box>
                      <StockProgressBar 
                        variant="determinate" 
                        value={stockPercentage} 
                        sx={{ mb: 1 }}
                      />
                    </Box>
                  )}
                  
                  {product.fomo.stockStatus.showSoldToday && (
                    <Box mt={1} display="flex" alignItems="center" gap={1}>
                      <Typography variant="body2" color="text.secondary" fontWeight="medium">
                        <Box component="span" color="success.main" fontWeight="bold">
                          <CountUp end={product.fomo.stockStatus.soldToday} duration={2} />+
                        </Box> pessoas compraram este produto hoje
                      </Typography>
                    </Box>
                  )}
                </Box>
                
                {/* Flash Sale Alert (FOMO) */}
                {showStockWarning && (
                  <Collapse in={showStockWarning}>
                    <FomoAlert type="error">
                      <WarningIcon />
                      <Typography variant="body2">
                        <Box component="span" fontWeight="bold">ATENÇÃO:</Box> Estoque chegando ao fim! Este produto costuma esgotar rápido.
                      </Typography>
                    </FomoAlert>
                  </Collapse>
                )}
                
                {/* Product Description */}
                <Typography variant="body1" paragraph>
                  {product.description}
                </Typography>
                
                {/* Special Batch (FOMO) */}
                {product.urgencyElements.specialBatch && (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      gap: 1,
                      bgcolor: alpha(theme.palette.warning.main, 0.15),
                      border: `1px dashed ${theme.palette.warning.main}`,
                      p: 1.5,
                      borderRadius: 2,
                      mb: 3
                    }}
                  >
                    <InfoIcon fontSize="small" color="warning" />
                    <Typography variant="body2" fontWeight="medium">
                      Lote especial {product.urgencyElements.batchNumber} - Produção limitada
                    </Typography>
                  </Box>
                )}
                
                {/* Key Features */}
                <Box mb={3}>
                  {product.features.map((feature, index) => (
                    <FeatureChip key={index}>
                      <CheckIcon color="primary" fontSize="small" />
                      <Typography variant="body2" fontWeight="medium">
                        {feature}
                      </Typography>
                    </FeatureChip>
                  ))}
                </Box>
                
                {/* Social Proof Bar */}
                <SocialProofBar>
                  {product.highlightedTestimonials.map((testimonial, index) => (
                    <Box key={index} display="flex" alignItems="center" gap={1} flexBasis="48%">
                      <Avatar src={testimonial.image} alt={testimonial.name} sx={{ width: 36, height: 36 }} />
                      <Box>
                        <Rating value={testimonial.stars} size="small" readOnly />
                        <Typography variant="caption" display="block">
                          "{testimonial.comment.substring(0, 30)}..."
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </SocialProofBar>
                
                {/* Price */}
                <Box mb={4}>
                  <Box display="flex" alignItems="baseline" gap={1} mb={1}>
                    {product.pricing.showCompareAtPrice && (
                      <Typography variant="h6" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                        R$ {product.pricing.regularPrice.toFixed(2)}
                      </Typography>
                    )}
                    <Typography variant="h3" color="primary.main" fontWeight="bold">
                      R$ {product.pricing.salePrice.toFixed(2)}
                    </Typography>
                    
                    {product.pricing.discountPercentage > 0 && (
                      <Chip 
                        label={`Economize ${product.pricing.discountPercentage}%`} 
                        color="primary" 
                        size="small"
                      />
                    )}
                  </Box>
                  
                  <Typography variant="body2" color="success.main" fontWeight="medium">
                    Em até {product.pricing.installments}x de R$ {(product.pricing.salePrice / product.pricing.installments).toFixed(2)} sem juros
                  </Typography>
                  <Typography variant="body2" color="primary.main" fontWeight="medium">
                    À vista R$ {(product.pricing.salePrice * (1 - product.pricing.pixDiscount / 100)).toFixed(2)} no PIX ({product.pricing.pixDiscount}% de desconto)
                  </Typography>
                </Box>
                
                {/* Sales Kit (upselling) */}
                <Collapse in={showSalesKit} timeout={800}>
                  <Paper 
                    elevation={0}
                    sx={{ 
                      p: 2, 
                      mb: 3, 
                      borderRadius: 2,
                      bgcolor: alpha(theme.palette.primary.main, 0.15),
                      border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                      <Box>
                        <Typography variant="h6" fontWeight="bold">
                          {product.salesKit.title}
                        </Typography>
                        <Typography variant="body2">
                          {product.salesKit.description}
                        </Typography>
                      </Box>
                      <Chip 
                        label={`Economize ${product.salesKit.saving}`} 
                        color="primary" 
                        size="small"
                      />
                    </Box>
                    
                    <Box 
                      sx={{ 
                        mb: 2,
                        pl: 2,
                        borderLeft: `2px dashed ${alpha(theme.palette.primary.main, 0.3)}`
                      }}
                    >
                      {product.salesKit.items.map((item, index) => (
                        <Box 
                          key={index} 
                          display="flex" 
                          justifyContent="space-between" 
                          alignItems="center"
                          mb={1}
                        >
                          <Box display="flex" alignItems="center" gap={1}>
                            <CheckIcon fontSize="small" color="primary" />
                            <Typography variant="body2">
                              {item.quantity}x {item.name}
                            </Typography>
                          </Box>
                          <Typography variant="body2" color="text.secondary">
                            R$ {item.regularPrice.toFixed(2)}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Typography variant="body2" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                          De R$ {product.salesKit.regularPrice.toFixed(2)}
                        </Typography>
                        <Typography variant="h6" color="primary.main" fontWeight="bold">
                          Por R$ {product.salesKit.price.toFixed(2)}
                        </Typography>
                      </Box>
                      <FormGroup>
                        <FormControlLabel 
                          control={
                            <Checkbox 
                              checked={kitSelected} 
                              onChange={handleKitSelect}
                              icon={<AddIcon />}
                              checkedIcon={<CheckIcon />}
                            />
                          } 
                          label="Adicionar Kit"
                        />
                      </FormGroup>
                    </Box>
                  </Paper>
                </Collapse>
                
                {/* Extended Warranty */}
                {product.warranty.extended.available && (
                  <Paper
                    elevation={0}
                    sx={{ 
                      p: 2, 
                      mb: 3, 
                      borderRadius: 2,
                      bgcolor: alpha(theme.palette.success.main, 0.1),
                      border: `1px solid ${alpha(theme.palette.success.main, 0.2)}`,
                    }}
                  >
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                      <Box display="flex" alignItems="center" gap={1}>
                        <ShieldIcon color="success" />
                        <Box>
                          <Typography variant="body1" fontWeight="medium">
                            Garantia estendida
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {product.warranty.extended.period} de proteção completa
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2" fontWeight="medium">
                          + R$ {product.warranty.extended.price.toFixed(2)}
                        </Typography>
                        <Checkbox 
                          checked={extendedWarranty}
                          onChange={handleExtendedWarranty}
                        />
                      </Box>
                    </Box>
                  </Paper>
                )}
                
                {/* Quantity Selector and Total */}
                <Grid container spacing={2} sx={{ mb: 3 }}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" fontWeight="medium" mb={1}>
                      Quantidade:
                    </Typography>
                    <QuantitySelector>
                      <QuantityButton 
                        onClick={() => handleQuantityChange(quantity - 1)}
                        disabled={quantity <= 1}
                      >
                        <RemoveIcon />
                      </QuantityButton>
                      <Typography 
                        variant="body1" 
                        textAlign="center" 
                        sx={{ flexGrow: 1, fontWeight: 'bold' }}
                      >
                        {quantity}
                      </Typography>
                      <QuantityButton 
                        onClick={() => handleQuantityChange(quantity + 1)}
                        disabled={quantity >= product.fomo.stockStatus.available}
                      >
                        <AddIcon />
                      </QuantityButton>
                    </QuantitySelector>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body2" fontWeight="medium" mb={1}>
                      Total:
                    </Typography>
                    <Box display="flex" alignItems="baseline" gap={1}>
                      {kitSelected && (
                        <Typography variant="h6" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                          R$ {(product.salesKit.regularPrice * quantity).toFixed(2)}
                        </Typography>
                      )}
                      <Typography variant="h5" fontWeight="bold" color="primary.main">
                        R$ {calculateFinalPrice().toFixed(2)}
                      </Typography>
                      {(kitSelected || product.pricing.showCompareAtPrice) && (
                        <Typography variant="body2" color="success.main">
                          (Economia de R$ {calculateTotalSavings()})
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                
                {/* Trust Points */}
                <Box 
                  display="flex" 
                  gap={2} 
                  flexWrap="wrap" 
                  mb={3}
                >
                  {product.trustElements.map((element, index) => (
                    <Box 
                      key={index}
                      sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        bgcolor: alpha(theme.palette.background.paper, 0.4),
                        borderRadius: 2,
                        p: 1.5,
                        flex: '1 1 45%',
                        minWidth: { xs: '100%', sm: '45%' }
                      }}
                    >
                      {element.icon === 'Shield' && <ShieldIcon color="primary" />}
                      {element.icon === 'Autorenew' && <AutorenewIcon color="primary" />}
                      {element.icon === 'AssignmentTurnedIn' && <AssignmentTurnedInIcon color="primary" />}
                      {element.icon === 'LocalShipping' && <LocalShippingIcon color="primary" />}
                      <Box>
                        <Typography variant="body2" fontWeight="medium">
                          {element.title}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {element.description}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
                
                {/* Action Buttons */}
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <PulseBox>
                      <AnimatedButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                        onClick={handleBuyNow}
                        startIcon={<FlashOnIcon />}
                        disabled={product.fomo.stockStatus.available <= 0 || addingToCart}
                        sx={{ py: 2 }}
                      >
                        {addingToCart ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          'COMPRAR AGORA'
                        )}
                      </AnimatedButton>
                    </PulseBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      color="primary"
                      fullWidth
                      size="large"
                      onClick={handleAddToCart}
                      startIcon={<AddShoppingCartIcon />}
                      disabled={product.fomo.stockStatus.available <= 0 || addingToCart}
                    >
                      ADICIONAR AO CARRINHO
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      fullWidth
                      startIcon={<FavoriteBorderIcon />}
                      onClick={handleToggleFavorite}
                    >
                      ADICIONAR AOS FAVORITOS
                    </Button>
                  </Grid>
                </Grid>
                
                {/* WhatsApp Button */}
                <Button
                  variant="contained"
                  color="inherit"
                  fullWidth
                  startIcon={<WhatsAppIcon />}
                  onClick={() => openWhatsApp(`Olá! Estou interessado no produto ${product.name} (SKU: ${product.sku}). Poderia me dar mais informações?`)}
                  sx={{ 
                    bgcolor: '#25D366', 
                    color: 'white',
                    '&:hover': { bgcolor: '#128C7E' },
                    mb: 3
                  }}
                >
                  CONSULTAR DISPONIBILIDADE VIA WHATSAPP
                </Button>
                
                {/* Competitive Advantages */}
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h5" gutterBottom>
                    Por que escolher o <GradientText variant="h5" component="span">Óleo Sintético Torki</GradientText>?
                  </Typography>
                  <Grid container spacing={2} mt={1}>
                    {product.competitiveAdvantages.map((advantage, index) => (
                      <Grid item xs={6} md={3} key={index}>
                        <BenefitCard>
                          {advantage.icon === 'FlashOn' && <FlashOnIcon fontSize="large" color="primary" />}
                          {advantage.icon === 'Speed' && <SpeedIcon fontSize="large" color="primary" />}
                          {advantage.icon === 'Build' && <BuildIcon fontSize="large" color="primary" />}
                          {advantage.icon === 'LocalAtm' && <LocalAtmIcon fontSize="large" color="primary" />}
                          <Typography variant="body1" fontWeight="bold" align="center">
                            {advantage.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" align="center">
                            {advantage.description}
                          </Typography>
                        </BenefitCard>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Product Tabs */}
          <Box sx={{ mt: 8 }}>
            <Paper 
              elevation={0} 
              sx={{ 
                borderRadius: 3, 
                overflow: 'hidden',
                bgcolor: alpha(theme.palette.background.paper, 0.6),
                backdropFilter: 'blur(10px)',
              }}
            >
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  '& .MuiTabs-indicator': {
                    height: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  },
                  bgcolor: alpha(theme.palette.background.default, 0.4),
                }}
              >
                <Tab label="Descrição Detalhada" icon={<DescriptionIcon />} iconPosition="start" {...a11yProps(0)} />
                <Tab label="Especificações" icon={<AssignmentIcon />} iconPosition="start" {...a11yProps(1)} />
                <Tab label="Compatibilidade" icon={<CheckIcon />} iconPosition="start" {...a11yProps(2)} />
                <Tab label="Avaliações" icon={<StarIcon />} iconPosition="start" {...a11yProps(3)} />
                <Tab label="Perguntas Frequentes" icon={<InfoIcon />} iconPosition="start" {...a11yProps(4)} />
              </Tabs>
              
              <TabPanel value={tabValue} index={0}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  Sobre o {product.name}
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                  {product.longDescription}
                </Typography>
                
                {/* Video (if available) */}
                {product.media.videos && product.media.videos.length > 0 && (
                  <Box mt={4}>
                    <Typography variant="h6" gutterBottom>
                      Veja como aplicar o produto:
                    </Typography>
                    <Box 
                      sx={{ 
                        position: 'relative',
                        paddingBottom: '56.25%', /* 16:9 aspect ratio */
                        height: 0,
                        overflow: 'hidden',
                        borderRadius: 2,
                      }}
                    >
                      <iframe
                        src={product.media.videos[0].url}
                        title={product.media.videos[0].title}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          border: 0,
                        }}
                        allowFullScreen
                      />
                    </Box>
                  </Box>
                )}
              </TabPanel>
              
              <TabPanel value={tabValue} index={1}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  Especificações Técnicas
                </Typography>
                <Box>
                  {product.specifications.map((spec, index) => (
                    <SpecificationItem key={index}>
                      <Typography variant="body1" fontWeight="medium">
                        {spec.name}
                      </Typography>
                      <Typography variant="body1">
                        {spec.value}
                      </Typography>
                    </SpecificationItem>
                  ))}
                </Box>
              </TabPanel>
              
              <TabPanel value={tabValue} index={2}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  Veículos Compatíveis
                </Typography>
                <Box 
                  sx={{ 
                    display: 'grid', 
                    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' },
                    gap: 2,
                  }}
                >
                  {product.compatibleVehicles.map((vehicle, index) => (
                    <Box 
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 1.5,
                        bgcolor: alpha(theme.palette.background.paper, 0.4),
                        borderRadius: 2,
                      }}
                    >
                      <CheckIcon fontSize="small" color="primary" />
                      <Typography variant="body2">
                        {vehicle}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </TabPanel>
              
              <TabPanel value={tabValue} index={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                  <Typography variant="h5" fontWeight="bold">
                    Avaliações dos Clientes
                  </Typography>
                  <Button 
                    variant="outlined" 
                    color="primary"
                    startIcon={<ThumbUpIcon />}
                  >
                    Avaliar este produto
                  </Button>
                </Box>
                
                <Box 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: 4,
                    mb: 4
                  }}
                >
                  <Box 
                    sx={{ 
                      width: { xs: '100%', md: '25%' },
                      bgcolor: alpha(theme.palette.background.paper, 0.4),
                      borderRadius: 2,
                      p: 3,
                      textAlign: 'center'
                    }}
                  >
                    <Typography variant="h2" color="primary" fontWeight="bold" gutterBottom>
                      {product.rating}
                    </Typography>
                    <Rating value={product.rating} precision={0.5} readOnly size="large" sx={{ mb: 1 }} />
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Baseado em {product.reviewCount} avaliações
                    </Typography>
                    
                    <Divider sx={{ my: 2 }} />
                    
                    <Box>
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Typography variant="body2">5 estrelas</Typography>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 8, borderRadius: 4, overflow: 'hidden' }}>
                          <Box sx={{ width: '75%', height: '100%', bgcolor: 'primary.main' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">75%</Typography>
                      </Box>
                      
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Typography variant="body2">4 estrelas</Typography>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 8, borderRadius: 4, overflow: 'hidden' }}>
                          <Box sx={{ width: '20%', height: '100%', bgcolor: 'primary.main' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">20%</Typography>
                      </Box>
                      
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Typography variant="body2">3 estrelas</Typography>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 8, borderRadius: 4, overflow: 'hidden' }}>
                          <Box sx={{ width: '5%', height: '100%', bgcolor: 'primary.main' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">5%</Typography>
                      </Box>
                      
                      <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Typography variant="body2">2 estrelas</Typography>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 8, borderRadius: 4, overflow: 'hidden' }}>
                          <Box sx={{ width: '0%', height: '100%', bgcolor: 'primary.main' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">0%</Typography>
                      </Box>
                      
                      <Box display="flex" alignItems="center" gap={1}>
                        <Typography variant="body2">1 estrela</Typography>
                        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', height: 8, borderRadius: 4, overflow: 'hidden' }}>
                          <Box sx={{ width: '0%', height: '100%', bgcolor: 'primary.main' }} />
                        </Box>
                        <Typography variant="body2" color="text.secondary">0%</Typography>
                      </Box>
                    </Box>
                  </Box>
                  
                  <Box sx={{ flexGrow: 1 }}>
                    {product.reviews.map((review, index) => (
                      <Paper 
                        key={index}
                        elevation={0}
                        sx={{ 
                          p: 3, 
                          mb: 2, 
                          bgcolor: alpha(theme.palette.background.paper, 0.4),
                          borderRadius: 2
                        }}
                      >
                        <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
                          <Box display="flex" alignItems="center" gap={1}>
                            <Avatar sx={{ bgcolor: 'primary.main', width: 40, height: 40 }}>
                              {review.user.charAt(0)}
                            </Avatar>
                            <Box>
                              <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="body1" fontWeight="medium">
                                  {review.user}
                                </Typography>
                                {review.isVerified && (
                                  <Chip 
                                    icon={<AssignmentTurnedInIcon fontSize="small" />} 
                                    label="Compra verificada" 
                                    size="small"
                                    sx={{ 
                                      bgcolor: alpha(theme.palette.success.main, 0.1), 
                                      color: 'success.main',
                                      fontWeight: 'medium',
                                      height: 24
                                    }} 
                                  />
                                )}
                              </Box>
                              <Typography variant="caption" color="text.secondary">
                                {review.date}
                              </Typography>
                            </Box>
                          </Box>
                          <Rating value={review.rating} readOnly size="small" />
                        </Box>
                        
                        <Typography variant="body1" fontWeight="medium" gutterBottom>
                          {review.title}
                        </Typography>
                        <Typography variant="body2">
                          {review.comment}
                        </Typography>
                      </Paper>
                    ))}
                    
                    <Button 
                      variant="outlined" 
                      color="primary"
                      fullWidth
                      sx={{ mt: 2 }}
                    >
                      Ver todas as {product.reviewCount} avaliações
                    </Button>
                  </Box>
                </Box>
              </TabPanel>
              
              <TabPanel value={tabValue} index={4}>
                <Typography variant="h5" gutterBottom fontWeight="bold">
                  Perguntas Frequentes
                </Typography>
                
                <Box>
                  {product.faq.map((item, index) => (
                    <Accordion 
                      key={index}
                      disableGutters
                      elevation={0}
                      sx={{ 
                        bgcolor: 'transparent',
                        '&:before': { display: 'none' },
                        mb: 2
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<KeyboardArrowDownIcon />}
                        sx={{ 
                          bgcolor: alpha(theme.palette.background.paper, 0.4),
                          borderRadius: 2,
                          '&.Mui-expanded': {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0
                          }
                        }}
                      >
                        <Typography variant="body1" fontWeight="medium">
                          {item.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ 
                          bgcolor: alpha(theme.palette.background.paper, 0.2),
                          borderBottomLeftRadius: 2,
                          borderBottomRightRadius: 2,
                          p: 3
                        }}
                      >
                        <Typography variant="body2">
                          {item.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                  
                  {/* Ask a Question Button */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<ChatIcon />}
                      onClick={() => openWhatsApp(`Olá! Tenho uma dúvida sobre o produto ${product.name} (SKU: ${product.sku}).`)}
                      size="large"
                    >
                      Fazer uma pergunta sobre este produto
                    </Button>
                  </Box>
                </Box>
              </TabPanel>
            </Paper>
          </Box>

          {/* Payment Options */}
          <Box sx={{ mt: 8 }}>
            <Typography variant="h4" gutterBottom>
              Formas de <GradientText variant="h4" component="span">Pagamento</GradientText>
            </Typography>
            
            <Paper
              elevation={0} 
              sx={{ 
                p: 3,
                borderRadius: 3, 
                bgcolor: alpha(theme.palette.background.paper, 0.6),
                backdropFilter: 'blur(10px)',
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Box 
                    display="flex" 
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}>
                        <CreditCardIcon color="primary" />
                      </Avatar>
                      <Box>
                        <Typography variant="body1" fontWeight="medium">
                          Cartão de Crédito
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Em até 12x sem juros
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}>
                        <PaymentIcon color="primary" />
                      </Avatar>
                      <Box>
                        <Typography variant="body1" fontWeight="medium">
                          Cartão de Débito
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Aprovação imediata
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                
                <Grid item xs={12} md={6}>
                  <Box 
                    display="flex" 
                    flexDirection="column"
                    gap={2}
                  >
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}>
                        <QrCodeIcon color="primary" />
                      </Avatar>
                      <Box>
                        <Typography variant="body1" fontWeight="medium">
                          PIX
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          5% de desconto adicional
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Box display="flex" alignItems="center" gap={2}>
                      <Avatar sx={{ bgcolor: alpha(theme.palette.primary.main, 0.1) }}>
                        <ReceiptIcon color="primary" />
                      </Avatar>
                      <Box>
                        <Typography variant="body1" fontWeight="medium">
                          Boleto Bancário
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Aprovação em até 2 dias úteis
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>

          {/* Related Products */}
          <Box sx={{ mt: 8 }}>
            <Typography variant="h4" gutterBottom>
              Produtos <GradientText variant="h4" component="span">Relacionados</GradientText>
            </Typography>
            
            <Grid container spacing={3}>
              {product.relatedProducts.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <Card 
                    sx={{ 
                      height: '100%',
                      cursor: 'pointer',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '0 16px 40px rgba(0, 0, 0, 0.3)',
                      },
                      position: 'relative'
                    }}
                    onClick={() => navigate(`/loja/produto/${item.id}`)}
                  >
                    {item.badge && (
                      <Chip
                        label={item.badge}
                        color="primary"
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: 10,
                          left: 10,
                          zIndex: 2,
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                    <CardMedia
                      component="img"
                      height="200"
                      image={item.image}
                      alt={item.name}
                    />
                    <CardContent>
                      <Typography variant="h6" component="h3" gutterBottom sx={{ 
                        height: '2.8rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        fontWeight: 'bold'
                      }}>
                        {item.name}
                      </Typography>
                      
                      <Box display="flex" alignItems="baseline" gap={1} mt={2}>
                        {item.discountPrice < item.price && (
                          <Typography variant="caption" color="text.secondary" sx={{ textDecoration: 'line-through' }}>
                            R$ {item.price.toFixed(2)}
                          </Typography>
                        )}
                        <Typography variant="h6" color="primary.main" fontWeight="bold">
                          R$ {item.discountPrice.toFixed(2)}
                        </Typography>
                      </Box>
                    </CardContent>
                    
                    <Box p={2} pt={0} display="flex" gap={1}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ flexGrow: 1 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setCartCount(cartCount + 1);
                          setNotification({
                            open: true,
                            message: `${item.name} adicionado ao carrinho`,
                            severity: 'success'
                          });
                        }}
                      >
                        <AddShoppingCartIcon />
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ flexGrow: 3 }}
                      >
                        Ver detalhes
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* WhatsApp CTA */}
          <Box 
            sx={{ 
              mt: 8,
              p: 3, 
              borderRadius: 3, 
              bgcolor: alpha('#25D366', 0.1),
              border: '1px dashed #25D366',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar sx={{ bgcolor: '#25D366', width: 56, height: 56 }}>
                <WhatsAppIcon sx={{ color: 'white', fontSize: 32 }} />
              </Avatar>
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  Precisa de ajuda para escolher a peça certa?
                </Typography>
                <Typography variant="body2">
                  Nossos especialistas estão disponíveis para tirar todas as suas dúvidas!
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              size="large"
              startIcon={<WhatsAppIcon />}
              sx={{ 
                bgcolor: '#25D366', 
                '&:hover': { bgcolor: '#1DA951' },
                px: 4
              }}
              onClick={() => openWhatsApp(`Olá! Estou com dúvidas sobre o produto ${product.name} (SKU: ${product.sku}) e gostaria de falar com um especialista.`)}
            >
              Falar com especialista
            </Button>
          </Box>
          
          {/* Last Chance FOMO */}
          <Box 
            sx={{ 
              mt: 4,
              p: 3, 
              borderRadius: 3, 
              background: `linear-gradient(90deg, ${alpha(theme.palette.error.main, 0.7)} 0%, ${alpha(theme.palette.error.dark, 0.7)} 100%)`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 2,
              position: 'relative',
              overflow: 'hidden'
            }}
          >
            <Box 
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'url(https://source.unsplash.com/random/1200x300/?abstract,fire) center/cover',
                opacity: 0.1,
                zIndex: 0
              }}
            />
            <Box display="flex" alignItems="center" gap={2} sx={{ zIndex: 1 }}>
              <LockClockIcon sx={{ fontSize: 48, color: 'white' }} />
              <Box>
                <Typography variant="h5" fontWeight="bold" color="white">
                  ÚLTIMA CHANCE!
                </Typography>
                <Typography variant="body1" color="white">
                  Esta promoção termina em breve, não perca!
                </Typography>
              </Box>
            </Box>
            <PulseBox color="white">
              <AnimatedButton
                variant="contained"
                size="large"
                color="inherit"
                sx={{ 
                  bgcolor: 'white', 
                  color: 'error.main',
                  fontWeight: 'bold',
                  px: 4,
                  zIndex: 1
                }}
                onClick={handleBuyNow}
              >
                GARANTIR OFERTA
              </AnimatedButton>
            </PulseBox>
          </Box>
        </Container>

        {/* Footer */}
        <Box component="footer" bgcolor={theme.palette.background.default} py={6} mt={8}>
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                  <CarIcon color="primary" />
                  <Typography variant="h6" fontWeight="bold">
                    TORKI
                  </Typography>
                </Box>
                <Typography color="text.secondary" paragraph>
                  Soluções completas de manutenção automotiva com tecnologia de ponta, especialistas certificados e transparência total.
                </Typography>
                <Box display="flex" gap={1} mt={2}>
                  <IconButton size="small" color="primary">
                    <InstagramIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" color="primary">
                    <FacebookIcon fontSize="small" />
                  </IconButton>
                  <IconButton size="small" color="primary">
                    <YouTubeIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
              
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Loja
                </Typography>
                <List dense disablePadding>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Todos os Produtos</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Ofertas</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Categorias</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Minha Conta</Button>
                  </ListItem>
                </List>
              </Grid>
              
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Serviços
                </Typography>
                <List dense disablePadding>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Revisão Completa</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Troca de Óleo</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Suspensão</Button>
                  </ListItem>
                  <ListItem disablePadding>
                    <Button color="inherit" sx={{ px: 0, justifyContent: 'flex-start' }}>Diagnóstico</Button>
                  </ListItem>
                </List>
              </Grid>
              
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Contato
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  Av. das Nações Unidas, 14401 - Chácara Santo Antônio<br />
                  São Paulo - SP, 04794-000
                </Typography>
                <Typography variant="body2" color="text.secondary" paragraph>
                  contato@torki.com.br<br />
                  (11) 3500-9000
                </Typography>
                <AnimatedButton 
                  variant="contained" 
                  color="primary" 
                  startIcon={<WhatsAppIcon />}
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={() => window.open(`https://wa.me/${product.whatsapp.number}`, '_blank')}
                >
                  AGENDAR PELO WHATSAPP
                </AnimatedButton>
              </Grid>
            </Grid>
            
            <Divider sx={{ my: 4 }} />
            
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2
              }}
            >
              <Typography color="text.secondary" variant="body2">
                © {new Date().getFullYear()} Torki. Todos os direitos reservados.
              </Typography>
              
              <Box display="flex" gap={2}>
                <Button color="inherit" size="small">
                  Privacidade
                </Button>
                <Button color="inherit" size="small">
                  Cookies
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar> 
    </ThemeProvider>
  );
};

export default ProductDetail;